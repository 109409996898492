import { addQuery } from "service/helperFunctions";
import { customerApi } from "../service/apiVariables";

import { userLocationType } from "service/actionType";

// create customer
export const createCustomer =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...customerApi.createCustomer, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
// get single customer details
export const getCustomerById =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...customerApi.getCustomerById, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
