import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NormalButton } from 'component/common'
import { handleAddToCart } from 'action/cartAct'

const AddToButton = ({ id, ChefDetail, handleAddToCart }) => {
  const [isAddingCart, setIsaddingCart] = useState(false)
  const handleAddToCartButton = async () => {
    let body = { userId: ChefDetail._id, foodId: id, quantity: 1 }
    debugger
    setIsaddingCart(true)

    await handleAddToCart(body)
      .then((data) => {
        if (!!data) {
          console.log(data, 'dffg')
        }
        setIsaddingCart(false)
      })
      .catch((e) => setIsaddingCart(false))
  }
  return (
    <NormalButton
      primary
      label="Add"
      className="fw-700 fs-16 add-to-cart"
      disabled={isAddingCart}
      loader={isAddingCart}
      onClick={() => {
        handleAddToCartButton()
      }}
    />
  )
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleAddToCart,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(AddToButton)
