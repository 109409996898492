export const validationRules = () => {
  return {
    emailId: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      email: true,
    },
    name: {
      presence: {
        allowEmpty: false,
        message: "^Name is required",
      },
      format: {
        pattern: /^[a-zA-z ]*$/,
        flags: "i",
        message: "must contain only alphabets and spaces",
      },
    },
    message: {
      presence: {
        allowEmpty: false,
        message: "^Message is required",
      },
      length: {
        minimum: 3,
        message: "^Message should contains atleast 3 characters",
        maximum: 300,
        message: "^Message should contains maximum 300 characters",
      },
    },
    mobileNumber: {
      presence: {
        allowEmpty: false,
        message: "^Mobile number is required",
      },
      format: {
        pattern: /^[0-9]*$/,
        flags: "i",
        message: "^Please enter valid mobile number",
      },
      length: {
        minimum: 10,
        maximum: 10,
        message: "^Please enter valid mobile number",
      },
    },
  };
};
