import React, { useState } from 'react'
import { forgotPassword } from 'action/AuthAct'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { history } from 'service/helpers'
import validate from 'service/validation'
import ErrorComponent from 'component/common/ErrorComponent'
import { validationRules } from './validate'
import { NormalButton } from 'component/common/NormalButton'
import { useLocation } from 'react-router-dom'
import OrSeperator from 'component/common/OrSeperator'

const ResetpasswordClass = (props) => {
  const [passwordDetails, setPasswordDetails] = useState({
    email: '',
  })

  const [isMailSent, setIsMailSent] = useState(false)

  const [isButtonClicked, setisButtonClicked] = useState(false)
  const [error, setErrors] = useState({})

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  //handle Change
  const handleChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error }
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined)
    setPasswordDetails((prevState) => ({ ...prevState, [name]: value }))
    setErrors({ ...tempErrors })
  }

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules())
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList })
    }
    return !fieldInvalidList
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.key === "enter") {
      handleSubmit(e);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    let body = {
      email: passwordDetails.email,
      userTypeRole: query.get('userTypeRole'),
    }
    if (!validateFields(body)) return
    setisButtonClicked(true)
    props
      .forgotPasswordApi(body)
      .then(() => {
        setisButtonClicked(false)
        setIsMailSent(true)
      })
      .catch(() => {
        setisButtonClicked(false)
      })
  }

  return (
    <div className="row">
      {!isMailSent ? (
        <React.Fragment>
          <div className="col-md-6 col-12 mx-auto">
            <h3 className="fw-700 fs-24 mb-3 pb-1 line-height-28 text-black-18 text-md-start text-center">
              Password Assistance
            </h3>

            <div className="mt-3 pt-1">
              <label>Enter email ID</label>
              <div className="input-group">
                <input
                  onKeyPress={handleKeypress}
                  className="form-control"
                  name="email"
                  placeholder="Enter email..."
                  value={passwordDetails.email}
                  onChange={handleChange}
                />
              </div>
              {error.email && <ErrorComponent message={error.email[0]} />}
            </div>
            <div className="w-100 mt-4 pt-1">
              <NormalButton
                primary
                label="Continue"
                className="w-100 fw-700"
                onClick={handleSubmit}
                disabled={isButtonClicked}
              />
            </div>
          </div>
          <div className="col-md-10 col-12 text-center my-md-4 my-3 mx-auto">
            <OrSeperator title="Already have an account" />
          </div>
          <div className="col-md-6 col-12 mx-auto">
            <NormalButton
              viewButton
              label="Login"
              className="w-100 fw-700"
              onClick={() =>
                history.push(
                  `/auth/login?userTypeRole=${query.get('userTypeRole')}`,
                )
              }
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="col-md-6 col-12 mx-auto">
            <h3 className="fw-700 mb-3 pb-1 fs-24 line-height-28 text-black-18 text-md-start text-center">
              Password Assistance
            </h3>
            <p className="text-black-25 fs-16 line-height-24">
              We’ve sent password reset link to change password to{' '}
              <b className="text-primary-color">{passwordDetails.email}</b>
            </p>
            <div className="w-100 mt-4 pt-1">
              <NormalButton
                primary
                label="Home"
                className="w-100 fw-700"
                onClick={() => history.push('/')}
              />
            </div>
          </div>
          <div className="col-md-10 col-12 text-center my-md-4 my-3 mx-auto">
            <OrSeperator title="Already have an account" />
          </div>
          <div className="col-md-6 col-12 mx-auto">
            <NormalButton
              viewButton
              label="login"
              className="w-100 fw-700"
              onClick={() =>
                history.push(
                  `/auth/login?userTypeRole=${query.get('userTypeRole')}`,
                )
              }
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ forgotPasswordApi: forgotPassword }, dispatch)
}

export const ResetpasswordComp = connect(
  null,
  mapDispatchToProps,
)(ResetpasswordClass)
