import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { history } from 'service/helpers'

// Component import
import ChefProfileComponent from 'component/ChefProfile/index.js'

export class ChefProfile extends Component {
  componentDidMount = () => {
    let authToken = localStorage.getItem('authToken')
    if (!authToken) {
      localStorage.clear()
      history.push('/')
    }
  }
  render() {
    return <ChefProfileComponent />
  }
}
export const ChefProfileC = connect(null, null)(ChefProfile)
