import React, { Component } from "react";

import { TermsAndConditionComp } from "component/TermsAndCondition";
export class TermsAndConditionClass extends Component {
  componentDidMount() {
    let element = document.getElementById("terms-and-conditions");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <div id="terms-and-conditions">
        <TermsAndConditionComp />
      </div>
    );
  }
}
export const TermsAndCondition = TermsAndConditionClass;
