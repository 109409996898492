import React, { useState, useRef, useCallback, useEffect } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import closeBtn from "assets/images/cloceBtn.png";
import { NormalButton } from "component/common";
import { Toast } from "service/toast";

export const ImageCropComponent = ({
  showImageCrop,
  setShowImageCrop,
  handleFileUpload,
  setCroppingImage,
  croppingImage,
}) => {
  const [upImg, setUpImg] = useState();
  const [rawFile, setRawFile] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [crop, setCrop] = useState({
    unit: "px",
    width: 120,
    height: 120,
    aspect: 4 / 4,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const reference = useRef();

  const onSelectFile = (e) => {
    if (e) {
      setRawFile(e);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!!croppingImage) {
      onSelectFile(croppingImage);
    }
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    // getImage()
    // getCroppedImg(imgRef.current, crop, 'test')
  }, [completedCrop, croppingImage]);

  const getCroppedImg = (canvas, pixelCrop, fileName) => {
    if (!pixelCrop || !canvas) {
      return;
    }
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, "image/png");
    });
  };

  const getImage = async () => {
    const val = await getCroppedImg(
      previewCanvasRef.current,
      crop,
      rawFile.name
    );
    console.log(val);
    handleFileUpload(val);
    setShowImageCrop(false);
    // setRawFile(null)
    // imgRef.current = null
    setUpImg(null);
    // generateDownload(previewCanvasRef.current, completedCrop)
  };
  const handleReset = () => {
    setRawFile(null);
    imgRef.current = null;
    setUpImg(null);
    setCroppingImage(null);
    setShowImageCrop(false);
  };

  return (
    <Modal
      size="lg"
      show={showImageCrop}
      onHide={() => handleReset()}
      backdrop={false}
      keyboard={false}
      className="deactivate-modal image-cropping-model"
      centered
    >
      <Modal.Header className="bg-white border-0">
        <Modal.Title className="text-primary-color">Image Editor</Modal.Title>
        <span class="cursor-pointer" onClick={() => handleReset()}>
          <img src={closeBtn} alt="icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex cropping-images">
          <div className="img-crop-box me-md-3">
            <ReactCrop
              maxWidth={170}
              maxHeight={170}
              locked={false}
              disabled={false}
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              onImageError={() => {
                Toast({
                  type: "error",
                  message: "Image Not support for cropping",
                  time: 2000,
                });
                handleReset();
              }}
            />
          </div>
          <div className="ms-md-3 d-flex flex-column align-items-center">
            <div className="preview-img-crop mb-4">
              {completedCrop ? (
                <canvas
                  ref={previewCanvasRef}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: '100%',
                    height: '100%',
                    // width: Math.round(completedCrop?.width ?? 0),
                    // height: Math.round(completedCrop?.height ?? 0),
                  }}
                />
              ) : (
                <div className="d-flex align-items-center justify-content-center bg-grey-d8 h-100">
                  <p className="mb-0 text-black-10">Preview</p>
                </div>
              )}
            </div>
            <div>
              {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}
              <div className="upload_btn">
                <input
                  ref={reference}
                  onChange={(e) => {
                    e.target.files[0] && onSelectFile(e.target.files[0]);
                    setCroppingImage(null);
                  }}
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                // multiple={false}
                />
                {/* <Button
              onClick={() => reference.current.click()}
              variant="outline-dark"
            >
              Upload Image
            </Button> */}
                <NormalButton
                  label="Choose image"
                  className="w-100 fw-700 outline-gray-btn"
                  onClick={() => reference.current.click()}
                />
                <p className="fs-12 alert-for-upload-profile mb-0 mt-2">
                  <sup className="text-danger fs-14">*</sup>
                  Please upload image file that is 5MB or less
                </p>
              </div>
            </div>
          </div>

          {/* <button
                            type="button"
                            disabled={!completedCrop?.width || !completedCrop?.height}
                            onClick={() =>
                                generateDownload(previewCanvasRef.current, completedCrop)
                            }
                        >
                            Download cropped image
                        </button> */}
        </div>
        <Modal.Footer>
          <div className="float-right">
            <NormalButton
              disabled={!completedCrop}
              primary
              label="Ok"
              className="w-100 fw-700 outline-gray-btn text-uppercase"
              onClick={() => {
                // let test = getCroppedImg(imgRef.current, crop, 'test')
                getImage();
                // console.log(getImage())
              }}
            />
          </div>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

function generateDownload(canvas, crop, fileName) {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = "cropPreview.png";
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    "image/png",
    1
  );
}
