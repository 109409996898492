import React from 'react';
import "./style.scss";

export const NormalCustomCheckbox = ({
    className = "custom-checkbox-edited",
    label = "",
    value = "",
    name = "",
    onChange,
    checked,
    disable = false,
}) => {

    return (
        <label className={`${className} ${disable === true ? "disable" : ''}`} >
            <input
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                disabled={disable}
                onChange={({ target: { name, checked: Checked } }) => {
                    onChange && onChange({ target: { name, value: Checked } })
                }}
            />
            <span className="checkbox-tick border-radius-circle">
                <i className="icon-tick-transparent border-radius-circle"></i>
            </span>
            {label ? <span className="label-txt fs-16 pl-2 ml-1">{label}</span> : ""}
        </label>
    )

}

