import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { List } from "../common/List";
import { Cards } from "../common/Cards";
import { Slick } from "../common/Slick";
import Slider from "react-slick";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import landingBg from "assets/images/landingbgImage.png";
import landingBg1 from "assets/images/landingbgImage1.png";
import landingBg2 from "assets/images/landingbgImage2.png";
import landingBg3 from "assets/images/landingbgImage3.png";
import mobileLandingBg from "assets/images/mobileLandingBg.jpg";
import mobileLandingBg1 from "assets/images/mobileLandingBg1.jpg";
import mobileLandingBg2 from "assets/images/mobileLandingBg2.jpg";
import mobileLandingBg3 from "assets/images/mobileLandingBg3.jpg";
import { userLocationType } from "service/actionType";
import { history } from "service/helpers";
import { Toast } from "service/toast";
import { logout } from "service/utilities";

const bannerData = [
  {
    title: "The Chef Lane",
    description: "Home-cooked food for every mood",
    bgImage: landingBg,
    mobileBg: mobileLandingBg,
  },
  {
    title: "The Chef Lane",
    description: "From their homes to yours",
    bgImage: landingBg1,
    mobileBg: mobileLandingBg1,
  },
  {
    title: "The Chef Lane",
    description: "Healthy, hygienic & homely",
    bgImage: landingBg2,
    mobileBg: mobileLandingBg2,
  },
  {
    title: "The Chef Lane",
    description: "A cornucopia of cuisine in your city",
    bgImage: landingBg3,
    mobileBg: mobileLandingBg3,
  },
];

const LandingClass = (props) => {
  const [address, setAddress] = useState("");
  const [timeoutForMap, setTimeoutForMap] = useState(false);
  // const [location, setLocation] = useState({})

  // set lat long value
  const handleLatLng = (data) => {
    let newData = [];
    console.log(data, "data");
    Object.keys(data).forEach((item) => newData.push(data[item]));
    // setFileds({ ...formFields, latLong: newData })
  };

  const handleFind = (e) => {
    let location = e;
    if (Object.keys(location).length) {
      props.updatePageCount(1);
      history.push(
        `/index/userDetail?area=${location.area}&city=${location.city}&state=${location.state}&country=${location.region}`
      );
    } else if (address !== "") {
      Toast({
        type: "error",
        message: "Please select from drop-down",
        time: 2000,
      });
    }
  };
  const handleFindError = () => {
    if (address !== "")
      Toast({
        type: "error",
        message: "Please select from drop-down",
        time: 2000,
      });
  };

  const params = {
    slidesPerView: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
  };
  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    if (!!userDetails && userDetails?.userTypeRole === "chef") {
      logout();
    }
    const timer = setTimeout(() => {
      setTimeoutForMap(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="Landing w-100">
      <Slider {...params} className="Landing-slider">
        {bannerData.map((list, index) => (
          <div key={index}>
            <div
              className="chef chef-mobile"
              style={{ backgroundImage: `url(${list.mobileBg})` }}
            >
              <div className="container h-100">
                <div className="row h-100">
                  <div className="my-auto">
                    <div className="fw-700 text-white text-center">
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => {
                              // setLocation(e)
                              handleFind(e);
                            }}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="chef chef-web"
              style={{ backgroundImage: `url(${list.bgImage})` }}
            >
              <div className="container h-100">
                <div className="row h-100">
                  <div className="my-auto">
                    <div className="fw-700 text-white text-center">
                      <p className="fs-41 mb-2 pb-1 line-height-48">
                        {list.title}
                      </p>
                      <p className="fs-18 fw-400 mb-2 pb-1 line-height-20">
                        {list.description}
                      </p>
                      <div className="maxWidth-500 mx-auto">
                        {timeoutForMap && (
                          <LocationSearchInput
                            isMap={"landing"}
                            address={address}
                            handleAddress={(e) => {
                              // setLocation(e)
                              handleFind(e);
                            }}
                            placeholder=""
                            handleSelect={(address) => setAddress(address)}
                            handleLatLng={handleLatLng}
                            handleFind={handleFind}
                            handleFindError={handleFindError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <Cards />
      <List />
      {/* <Slick /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updatePageCount: () =>
        dispatch({
          type: userLocationType.currentPageChef,
          payload: 1,
        }),
    },
    dispatch
  );
};

let component = LandingClass;

export const LandingComp = connect(null, mapDispatchToProps)(component);
