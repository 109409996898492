import { addQuery } from "service/helperFunctions";
import { chefdetailsApi } from "../service/apiVariables";

import { userLocationType } from "service/actionType";
//get Chef Details By Id Api
export const getMenuDetails =
  (body, query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, chefdetailsApi.getMenuApi);
      api({ ...chefdetailsApi.getMenuApi, body })
        .then((data) => {
          let page =
            data && data.data && data.data.pageMeta
              ? data.data.pageMeta.page
              : 1;
          dispatch({
            type: userLocationType.currentPageMenu,
            payload: page,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//get Chef Details By Id Api
export const chefCompleted =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.chefCompleted, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const getMenuWithChef =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.getMenuWithChef, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

// create dish
export const createDish =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.createDish, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
// Update dish
export const upDateDish =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.updateDish, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const menuAvailability =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.menuAvailability, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const deleteDish =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.deleteDish, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
//create Chef Details By Id Api
export const chefUpdate =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.chefUpdate, body })
        .then((response) => {
          if (
            response.message ==
            "An account with this number already exists. You can complete your registration with the same number."
          ) {
            Toast({ type: "success", message: response.message, time: 3000 });
          }
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//Quite Chef Details By Id Api
export const chefQuite =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.chefUpdate, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

export const chefEditUpdate =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.chefEditUpdate, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const deactivateChef =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.deactivateChef, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const deleteChef =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.deleteChef, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const getChefWithBrand =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.getByBrandName, body })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

// get single chef
export const getSingle =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.getSingle, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
// get single chef pdf
export const getChefPdf =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.getPdf, id })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
//get Chef Details  Api
export const getChefDetails =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    console.log(query);
    return new Promise((resolve, reject) => {
      addQuery(query, chefdetailsApi.getChefDetailsApi);
      api({ ...chefdetailsApi.getChefDetailsApi })
        .then((data) => {
          let page =
            data && data.data && data.data.pageMeta
              ? data.data.pageMeta.page
              : 1;
          dispatch({
            type: userLocationType.currentPageChef,
            payload: page,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
// export const getChefDetails =
//   (query) =>
//   (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//       // let { getNotificationList } = authApi;
//       // getNotificationList.chefId = id;
//       addQuery(query, chefdetailsApi.getChefDetailsApi);
//       api({ ...chefdetailsApi.getChefDetailsApi })
//         .then((data) => {
//           // let payload = data.data;
//           // dispatch({
//           //   type: notificationType.notificationListData,
//           //   payload,
//           // });
//           resolve(data);
//         })
//         .catch(({ message }) => {
//           reject(Toast({ type: "error", message }));
//         });
//     });
//   };

// export const getChefDetails =
//   (query) =>
//   (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//       api({ ...chefdetailsApi.getChefDetailsApi })
//         .then((response) => {
//           resolve(response);
//         })
//         .catch(({ message }) => {
//           reject(Toast({ type: "error", message }));
//         });
//     });
//   };
//get Chef Details By Id Api
export const getCusineList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.getCusineList })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
//get Chef Details By Id Api
export const foodlabelList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.foodlabelList })
        .then((response) => {
          resolve(response);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

// contactUs Api
export const contactUs =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...chefdetailsApi.contactUsApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

// Filter Api
// export const filterCuisine =
//   (body, query) =>
//   (dispatch, getState, { api, Toast }) => {
//     return new Promise((resolve, reject) => {
//       addQuery(query, ...chefdetailsApi.filterCuisineApi, body);
//       api({ ...chefdetailsApi.filterCuisineApi })
//         // .then(({ chefDetails }) => {
//         //   resolve(chefDetails);
//         // })
//         .then(({ data }) => {
//           let page =
//             data && data.chefDetails && data.pageMeta ? data.pageMeta.page : 1;
//           dispatch({
//             type: userLocationType.currentPageMenu,
//             payload: page,
//           });
//           resolve(data);
//         })
//         .catch(({ message }) => {
//           reject(Toast({ type: "error", message }));
//         });
//     });
//   };

export const filterCuisine =
  (body, query) =>
  (dispatch, getState, { api, Toast }) => {
    console.log(query);
    debugger;
    return new Promise((resolve, reject) => {
      addQuery(query, chefdetailsApi.filterCuisineApi);
      api({ ...chefdetailsApi.filterCuisineApi, body })
        .then(({ data }) => {
          let page =
            data && data.chefDetails && data.pageMeta ? data.pageMeta.page : 1;
          dispatch({
            type: userLocationType.currentPageChef,
            payload: page,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
