import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton } from "component/common";
import { history } from "service/helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefUpdate, chefQuite } from "action/ChefDetailsAct";
import closeBtn from "assets/images/cloceBtn.png";
import logo from "assets/images/alertLogo.png";
import correct from "../../../../../assets/images/correct.png";
import wrong from "../../../../../assets/images/wrong.png";

function UnderTrial({ formData, chefQuite }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(true);
  const [isQuit, setIsQuit] = useState(false);

  let isExpired = false;
  let isblocked = false;
  return (
    <div>
      <Modal
        show={isAlert}
        //   onHide={() => setIsAlert(true)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal trial-model"
        centered
      >
        <Modal.Header className="bg-white border-0">
          <Modal.Title></Modal.Title>
          {/* {
            !isExpired ?
              <span class="cursor-pointer" onClick={() => setIsAlert(false)}>
                <img src={closeBtn} alt="icon" />
              </span>
              : ''
          } */}
        </Modal.Header>
        <Modal.Body>
          <div className="alert-deActive mt-3 pb-4">
            <p className="mb-2 fs-22 text-black">
              Welcome to The Chef Lane! Let us help you navigate this journey.
              Check your inbox for tips to create your perfect profile.
            </p>
          </div>
          <Modal.Footer>
            <>
              <div className="d-flex pb-3">
                <NormalButton
                  primary
                  label="Create Profile"
                  className="px-5 fw-700"
                  disabled={isLoading}
                  onClick={() => {
                    history.replace(`/index/profile`);
                  }}
                />
              </div>
            </>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefUpdate,
      chefQuite,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(UnderTrial);

const PlanTable = () => {
  return (
    <div id="Package">
      <div className="container Table">
        <div className="row d-flex justify-content-center">
          <div className="fs-36 fw-700 text-primary-color d-flex justify-content-center pb-5 pt-1">
            <img src={logo} alt="logo" />
          </div>
          <div class="col-lg-10 col-12">
            <div class="card">
              <div class="card-body">
                <table class="table table-bordered ">
                  <thead>
                    <tr className="heading">
                      <th scope="col" className="">
                        <p className="mb-0 fs-18 fw-700 text-black-25 text-left">
                          Packages
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto mb-0 fs-18 fw-700 text-black-25">
                          Listing
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto mb-0 fs-18 fw-700 text-black-25">
                          ListingPLUS
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="w-40 pt-3">
                      <th className="fs-18 fw-700 text-black-25 text-left">
                        Listing
                      </th>

                      <td className="text-center">
                        <img
                          alt="correct"
                          className="text-center icon"
                          src={correct}
                        />
                      </td>
                      <td className="text-center">
                        <img
                          alt="correct"
                          className="text-center icon"
                          src={correct}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-left"
                        scope="row"
                      >
                        Ordering &amp; Delivery
                      </th>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                      </td>
                      <td className="text-center">
                        <img alt="correct " className="icon" src={correct} />
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-left"
                        scope="row"
                      >
                        Unlimted updates to Menu
                      </th>
                      <td className="text-center">
                        <img alt="correct" className="icon" src={correct} />
                      </td>
                      <td className="text-center">
                        <img alt="correct" className="icon" src={correct} />
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-left"
                        scope="row"
                      >
                        Monthy Pricing
                      </th>
                      <td className="text-center fs-18 fw-400 text-black-25">
                        {" "}
                        INR 500
                      </td>
                      <td className="text-center fs-18 fw-400 text-black-25">
                        INR 1000
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-left"
                        scope="row"
                      >
                        Yearly Pricing
                      </th>
                      <td className="text-center fs-18 fw-400 text-black-25 ">
                        INR 5000
                      </td>
                      <td className="text-center fs-18 fw-400 text-black-25">
                        INR 10000
                      </td>
                    </tr>
                    <tr>
                      <th
                        className="fs-18 fw-700 text-black-25 text-left"
                        scope="row "
                      >
                        Transaction Fees
                      </th>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                        <div className="mt-4 d-flex justify-content-center">
                          <NormalButton
                            primary
                            label="Select"
                            className="px-5  fw-700"
                            onClick={() => {}}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                        <div className="mt-4 d-flex justify-content-center">
                          <NormalButton
                            primary
                            label="Select"
                            className="px-5 fw-700"
                            onClick={() => {}}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-end">
              <a
                href="/index/t&c"
                className="fs-16 line-height-20 fw-400 text-black-25 pt-3 cursor-pointer hover-black-25"
                target="_blank"
              >
                Terms &amp; Conditions apply
              </a>
            </div>
            {/* <div className="text-center pt-5">
              <p className="fs-24 fw-700 text-black-25 line-height-28 ">
                Sign Up and avail the 3 Months Free Trial with The Chef Lane{" "}
              </p>
              <NormalButton
                onClick={() => history.push(`/index/profile`)}
                primary
                label="Get started "
                className="my-4 col-md-2  mx-auto fw-700  fs-16 line-height-18 cursor-pointer"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
