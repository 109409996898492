import React, { useState } from "react";
import { NormalButton, NormalInput } from "component/common";
import { contactUs } from "./../../action/ChefDetailsAct";
import { validationRules } from "./validate";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import "./style.scss";
import { history } from "service/helpers";
import email from "../../assets/images/email.png";
import address from "../../assets/svg/address.svg";
import ReCAPTCHA from "react-google-recaptcha";


export const ContactUsClass = ({ contactUsApiCall }) => {
  function onChangeCaptcha(value) {
    if (value === null) {
      setreCaptchaFailed(true);
    } else {
      setreCaptchaFailed(false);
    }
  }
  const [reCaptchaFailed, setreCaptchaFailed] = useState(true);
  const [contactUsDetails, setContactUsDetails] = useState({
    name: "",
    emailId: "",
    message: "",
    mobileNumber: "",
    mobileCountryCode: "+91",
  });
  const [error, setErrors] = useState({});
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  //handle Input Change
  const handleInputChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setContactUsDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      ...contactUsDetails,
      userId: localStorage.getItem("userId"),
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    contactUsApiCall(body)
      .then(() => {
        setisButtonClicked(false);
        handleClear();
      })
      .catch(() => {
        setisButtonClicked(false);
      });
  };

  //handle Clear
  const handleClear = () => {
    setContactUsDetails({
      name: "",
      emailId: "",
      message: "",
      mobileNumber: "",
      mobileCountryCode: "+91",
    });
  };

  return (
    <div className="contactus_bacground_image">
      <div className="container">
        <div className="row my-5">
          <div className="col-md-10 col-12 mx-auto">
            <h3 className="text-primary-color fw-700 fs-30 line-height-36 mb-md-5 text-center">
              Contact Us
            </h3>
            <div className="row bg-white border-line">
              <div className="col-md-6 col-12 mx-auto py-3 px-md-5 px-3">
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-md-5 mb-3">
                    <div className="me-5">
                      <img src={email} alt="email" />
                    </div>
                    <div className="d-flex flex-wrap">
                      <p className="fs-18 fw-700 text-black mb-0  text-nowrap  d-flex w-100 mb-2">
                        Email us{" "}
                      </p>
                      <p className="text-underline mt-0 fw-500 fs-16 cursor-pointer mb-0">
                        <a
                          href="mailto:support@thecheflane.com"
                          className="text-gold letter-spacing"
                        >
                          support@thecheflane.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="me-4 pt-2 pe-2 ">
                      <img src={address} className="pe-1" alt="email" />
                    </div>
                    <div className="d-flex flex-wrap">
                      <p className="fs-18 fw-700 text-black mb-0  text-nowrap  d-flex w-100 mb-2">
                        Write to us{" "}
                      </p>
                      <p className="text-grey-4f fs-14 mb-0">
                        <span className="d-flex mb-0 fs-16 text-grey-5f ">
                          Chef Lane Online Services Private Limited{" "}
                        </span>
                        <span className="mb-0 fs-16  text-grey-5f ">
                          SP7A Guindy Industrial Estate
                          <br />
                          Guindy
                          <br />
                          Chennai - 600032
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 mx-auto py-3 px-md-5 px-3 pb-4 contact-form">
                <NormalInput
                  label="Name"
                  placeholder="Enter name"
                  value={contactUsDetails.name}
                  onChange={handleInputChange}
                  name="name"
                />
                {error.name && <ErrorComponent message={error.name[0]} />}
                <NormalInput
                  label="Email ID"
                  placeholder="Enter email"
                  value={contactUsDetails.emailId}
                  onChange={handleInputChange}
                  name="emailId"
                />
                {error.emailId && <ErrorComponent message={error.emailId[0]} />}
                <div className="mt-2 pt-2">
                  <label>Mobile Number</label>
                  <div className="input-group position-relative phone-number-input">
                    <input
                      type="number"
                      className="form-control bg-white fs-16"
                      name="mobileNumber"
                      placeholder="Enter number"
                      value={contactUsDetails.mobileNumber}
                      onChange={handleInputChange}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />

                    <div className="country-code-dropdown">
                      <CountryCodeDropDown
                        onClick={(e) =>
                          handleInputChange({
                            target: {
                              name: "mobileCountryCode",
                              value: e,
                            },
                          })
                        }
                        value={contactUsDetails.mobileCountryCode}
                      />
                    </div>
                  </div>
                  {error.mobileNumber && (
                    <ErrorComponent message={error.mobileNumber[0]} />
                  )}
                </div>
                <div className="mt-2 pt-2">
                  <label>Message</label>
                  <textarea
                    className="mt-1 d-flex flex-column w-100 form-control"
                    name="message"
                    placeholder="Enter message..."
                    value={contactUsDetails.message}
                    rows="4"
                    onChange={handleInputChange}
                  />
                  {error.message && (
                    <ErrorComponent message={error.message[0]} />
                  )}
                </div>

                <div className="mt-3 pt-1 d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey="6Lf7Po8gAAAAAG7XwUxeNjPaBX0wNlZQ_ume6px_"
                    onChange={onChangeCaptcha}
                  />
                </div>

                <div className="mt-md-4 mt-4 d-flex justify-content-center">
                  <NormalButton
                    label="Clear"
                    viewButton
                    className="me-4 fw-700"
                    disabled={isButtonClicked}
                    onClick={() => handleClear()}
                  />
                  <NormalButton
                    label="Send"
                    //disabled={isButtonClicked}
                    disabled={isButtonClicked || reCaptchaFailed}
                    className="fw-700"
                    primary
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
            {/* <h3 className="text-primary-color fw-700 fs-30 mt-4 line-height-36 mb-md-3 text-center">
              Contact Details
            </h3>
            <div className="row mt-4 bg-white pb-4">
              <div className="col-md-7 col-12 mx-auto py-3">
                <div className="mt-4">
                  <div className=" d-flex ">
                    <p className="mb-0 fs-16 fw-700 text-grey-4f text-nowrap me-5">
                      Email us{" "}
                    </p>
                    <p className="text-underline mt-0 fw-500 fs-14 cursor-pointer mb-0">
                      <a
                        href="mailto:support@thecheflane.com"
                        className="text-gold "
                      >
                        support@thecheflane.com
                      </a>
                    </p>
                  </div>
                  <div className="mt-2">
                    <div className=" d-flex  ">
                      <p className="mb-0 fs-16 fw-700 text-grey-4f me-4 pe-1">
                        Write to us{" "}
                      </p>

                      <p className="text-grey-4f fs-14 mb-0">
                        <span className="d-flex mb-0 fs-14 fw-500 text-grey-4f ">
                          Chef Lane Online Services Private Limited{" "}
                        </span>
                        <span className="mb-0 fs-14 fw-500 text-grey-4f ">
                          SP7A Guindy Industrial Estate
                          <br />
                          Guindy
                          <br />
                          Chennai - 600032
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      contactUsApiCall: contactUs,
    },
    dispatch
  );
};

let component = ContactUsClass;
export const ContactUsComp = connect(null, mapDispatchToProps)(component);
