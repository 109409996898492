import React, { useState, useEffect, useRef } from "react";

import { CommonPageLoader, NormalButton, NormalDate } from "component/common";
import BasicDetail from "./BasicDetail/BasicDetail";
import MenuDetails from "./Menu";
import ChefOrder from "./ChefOrder";
import RateReview from "./RateReview";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SideBar from "./sideBar";
import UnderTrial from "./userTrial";
import { getSingle, chefEditUpdate } from "action/ChefDetailsAct";
import SimpleReactValidator from "simple-react-validator";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { fileUpload } from "action/FileUpload";
import logo from "assets/images/alertLogo.png";
import "./BasicDetail/styles.scss";
import { Toast } from "service/toast";
import { logout } from "service/utilities";

function ActiveProfileComponent({ getSingle, fileUpload, chefEditUpdate }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLicense, setIsLicense] = useState(false);
  const [formData, setFormData] = useState();
  const [active, setActive] = useState("basicDetail");
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [toastView, setToastView] = useState(false);
  const referenceFile = useRef();

  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  let isTrial = false;
  useEffect(() => {
    if (active === "basicDetail") fetchChefDetails();
  }, [active]);
  const fetchChefDetails = async () => {
    let id = localStorage.getItem("userId");
    !id && logout();
    setIsLoading(true);
    id &&
      (await getSingle(id)
        .then((response) => {
          let { checkLicenseExpired, data } = response;
          if (!!data && !data.deactivateId) {
            let { LicenseDetails } = data;
            //   let newForm = Object.assign(formData, data)
            if (data.status === "PENDING") {
              if (!toastView) {
                setToastView(true);
                Toast({
                  type: "info",
                  message: "Your Profile is waiting for Approval.",
                  time: 2000,
                });
              }
            }
            if (
              data.status === "SUSPENDED" ||
              checkLicenseExpired ||
              (data.status === "PENDING" &&
                LicenseDetails.adminApproved === "REJECTED")
            ) {
              data.LicenseDetails = {
                licenseNumber: "",
                licenseExpiryDate: null,
                licenseFileUrl: "",
                adminApproved: false,
                isEdit: false,
              };
              setIsSubmitted(true);
              setFormData(data);
              setIsLicense(false);
            } else {
              data.isEdit = false;
              setFormData(data);
            }
          } else {
            logout();
          }
          setIsLoading(false);
        })
        .catch((e) => setIsLoading(false)));
  };
  const handleFileUpload = async (event, name) => {
    var data = new FormData();
    let file = {};
    file = event.target.files[0];
    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      Toast({
        type: "error",
        message: "Please upload document file that is 5MB or less",
        time: 2000,
      });
    } else {
      setIsDocumentuploading(true);

      await fileUpload(data)
        .then((data) => {
          let { LicenseDetails } = { ...formData };
          LicenseDetails.licenseFileUrl = data?.data?.url;
          setFormData((prevState) => ({
            ...prevState,
            LicenseDetails,
          }));

          setIsDocumentuploading(false);
        })
        .catch(() => {
          setIsDocumentuploading(false);
        });
    }
  };
  const submitBasicDetails = async () => {
    let flag = validatorLicence.current.allValid();
    if (flag) {
      setIsFormLoading(true);
      formData["masterChefId"] = localStorage.getItem("userId");
      formData.LicenseDetails.isEdit = true;
      formData.LicenseDetails.adminApproved = null;
      formData.isEdit = true;
      delete formData["_id"];
      delete formData["__v"];
      console.log(formData, "basicDetails");
      await chefEditUpdate(formData)
        .then(() => {
          setIsFormLoading(false);
          setIsSubmitted(true);
          fetchChefDetails();
        })
        .catch((e) => setIsFormLoading(false));
    } else {
      validatorLicence.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };
  return (
    <section>
      {isTrial && <UnderTrial />}
      <div className="container-fluid container-lg py-4 minimum-height">
        {!isLoading ? (
          <div className="row">
            {isLicense ? (
              <Modal
                show={isLicense}
                backdrop={true}
                keyboard={false}
                className="deactivate-modal license-model"
                centered
              >
                <Modal.Header className=" border-0 bg-white p-0">
                  <Modal.Title> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {isFormLoading ? (
                    <CommonPageLoader />
                  ) : (
                    <>
                      {isSubmitted && formData.status === "PENDING" ? (
                        <p className="my-5 fs-20 text-black">
                          Your License is Under Review !
                        </p>
                      ) : (
                        <>
                          <div
                            id="custom-profile"
                            className="bg-white text-start pb-1"
                          >
                            <div className="text-center ">
                              <img src={logo} alt="logo" />
                            </div>
                            <p className="my-5 fs-20 text-black text-center">
                              There is a due for your certification <br />
                              please upload to take orders.
                            </p>

                            <div className="row">
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter License Number"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      let {
                                        target: { value },
                                      } = e;
                                      let { LicenseDetails } = { ...formData };
                                      LicenseDetails.licenseNumber = value;

                                      setFormData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                    value={
                                      formData.LicenseDetails.licenseNumber
                                    }
                                  />
                                  {validatorLicence.current.message(
                                    "License Number",
                                    formData.LicenseDetails.licenseNumber,
                                    "required"
                                  )}
                                </Form.Group>
                              </div>
                              <div className="col-sm-6 ">
                                <Form.Group>
                                  <Form.Label>License Expiry Date</Form.Label>
                                  <NormalDate
                                    placeholder="Enter Date"
                                    name="licenseExpiryDate"
                                    value={
                                      formData.LicenseDetails
                                        .licenseExpiryDate &&
                                      new Date(
                                        formData.LicenseDetails.licenseExpiryDate
                                      )
                                    }
                                    isIcon={false}
                                    minDate={new Date(moment().add(10, "days"))}
                                    className="form-control mini-date"
                                    showYearDropdown={true}
                                    onChange={(e) => {
                                      // let date=moment(e.target.value).format("DD/MM/YYYY")
                                      let { LicenseDetails } = { ...formData };
                                      LicenseDetails.licenseExpiryDate =
                                        e.target.value;

                                      setFormData((prevState) => ({
                                        ...prevState,
                                        LicenseDetails,
                                      }));
                                    }}
                                  />

                                  {validatorLicence.current.message(
                                    "License Expiry",
                                    formData.LicenseDetails.licenseExpiryDate,
                                    "required"
                                  )}
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="custom-file-upload">
                                  <Form.Group className="mb-0">
                                    <Form.Label>License File</Form.Label>
                                    <div className="position-relative">
                                      <Form.Control
                                        disabled={isDocumentuploading}
                                        type="file"
                                        ref={referenceFile}
                                        placeholder="Enter..."
                                        accept="application/pdf,image/*"
                                        className="custom-edited-upload"
                                        onChange={(e) => {
                                          e.target.files[0] &&
                                            handleFileUpload(e, "licenseFile");
                                        }}
                                      />
                                      <p className="fileNAme">
                                        {isDocumentuploading ? (
                                          "loading..."
                                        ) : (
                                          <>
                                            {formData.LicenseDetails
                                              .licenseFileUrl
                                              ? formData.LicenseDetails.licenseFileUrl
                                                  .split("/")
                                                  .pop()
                                                  .split("--")
                                                  .pop()
                                              : ""}
                                          </>
                                        )}
                                      </p>
                                      <NormalButton
                                        label="Upload"
                                        className="fw-700 fs-14 file-btn"
                                        disabled={isDocumentuploading}
                                        onClick={() =>
                                          referenceFile.current.click()
                                        }
                                      />
                                      <p className="fs-12 alert-for-upload mb-0">
                                        <sup className="text-danger fs-14">
                                          *
                                        </sup>
                                        Please upload file that is 5MB or less
                                      </p>
                                      {validatorLicence.current.message(
                                        "License File",
                                        formData.LicenseDetails.licenseFileUrl,
                                        "required"
                                      )}
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Modal.Footer>
                            <div className="d-flex pb-3">
                              {/* <NormalButton
                        primary
                        label="Pay Now"
                        className="px-5 w-100 fw-700"
                        disabled={isLoading}
                        onClick={() => {}}
                      />

                      <span className="m-4" /> */}
                              {/* {!isSubmitted && formData.status === 'PENDING' && ( */}
                              <NormalButton
                                disabled={isFormLoading}
                                primary
                                label="Update"
                                className="px-5 "
                                onClick={() => submitBasicDetails()}
                              />
                              {/* )} */}
                            </div>
                          </Modal.Footer>
                        </>
                      )}
                    </>
                  )}
                </Modal.Body>
              </Modal>
            ) : (
              <>
                <div className="col-md-3">
                  <SideBar
                    active={active}
                    setActive={setActive}
                    data={formData}
                  />
                </div>
                <div className="col-md-9 ">
                  {active === "basicDetail" || active === "address" ? (
                    <BasicDetail
                      active={active}
                      dataFromParent={formData}
                      fetchChefDetailsParent={fetchChefDetails}
                    />
                  ) : active === "menu" ? (
                    <MenuDetails dataFromParent={formData} />
                  ) : active === "chefOrder" ? (
                    <>
                      <ChefOrder />
                    </>
                  ) : (
                    <>
                      <RateReview />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        ) : (
          <CommonPageLoader />
        )}
      </div>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSingle, fileUpload, chefEditUpdate },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(ActiveProfileComponent);
