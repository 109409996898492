import { authApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";
import { notificationType } from "service/actionType";
// login
export const login =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.loginApi, body })
        .then((response) => {
          let { msg, message, data, token, checkLicenseExpired } = response;
          localStorage.setItem("authToken", token);
          localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          localStorage.setItem("userId", data._id);
          if (checkLicenseExpired) {
            Toast({
              type: "error",
              message: "License has been Expired.",
              time: 3000,
            });
          } else {
            Toast({ type: "success", message: msg || message, time: 3000 });
          }
          resolve(response);
        })
        .catch(({ msg, message }) => {
          reject(Toast({ type: "error", message: msg || message, time: 2000 }));
        });
    });
  };
export const userSwitch =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.userSwitch, body })
        .then(({ msg, message, data, token }) => {
          // localStorage.setItem("authToken", token);
          if (data !== null) {
            localStorage.setItem("userDetailsWeb", JSON.stringify(data));
            localStorage.setItem("userId", data._id);
            Toast({ type: "success", message: msg || message, time: 3000 });
          }
          resolve(data);
        })
        .catch(({ msg, message }) => {
          reject(Toast({ type: "error", message: msg || message, time: 2000 }));
        });
    });
  };
export const signUp =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.signUpApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 3000 });
          resolve(data);
        })
        .catch((data) => {
          reject(Toast({ type: "error", message: data.message, time: 2000 }));
        });
    });
  };
export const signUpChef =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.signUpChefApi, body })
        .then((data) => {
          Toast({ type: "success", message: data.message, time: 3000 });
          resolve(data);
        })
        .catch(({ errorMessage, message }) => {
          console.log("test");
          reject(Toast({ type: "error", message: message, time: 2000 }));
        });
    });
  };
export const resendOtpMobile =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resendOtpMobile, body })
        .then((data) => {
          Toast({ type: "success", message: data.message, time: 3000 });
          resolve(data);
        })
        .catch(({ errorMessage }) => {
          reject(Toast({ type: "error", message: errorMessage, time: 2000 }));
        });
    });
  };
export const resendOtpEmail =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resendOtpEmail, body })
        .then((data) => {
          Toast({ type: "success", message: data.message, time: 3000 });
          resolve(data);
        })
        .catch(({ errorMessage }) => {
          reject(Toast({ type: "error", message: errorMessage, time: 2000 }));
        });
    });
  };

export const verifyUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.verifyUserApi, body })
        .then(({ token, data, message }) => {
          token && localStorage.setItem("authToken", token);
          data && localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          message && Toast({ type: "success", message, time: 3000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

export const forgotPassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.forgotPasswordApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
export const resetPassword =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.resetPasswordApi, body })
        .then(({ message, data }) => {
          Toast({ type: "success", message, time: 3000 });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

export const chefChangePasswordPostLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.chefChangePasswordPostLogin, body })
        .then(({ message }) => {
          Toast({ type: "success", message, time: 3000 });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//user Change Password Post Login
export const userChangePasswordPostLogin =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.userChangePasswordPostLogin, body })
        .then(({ message }) => {
          Toast({ type: "success", message, time: 3000 });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//get Notification Count
export const getNotificationCount =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { getNotificationCount } = authApi;
      getNotificationCount.chefId = id;
      api({ ...authApi.getNotificationCount })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//get Notification List
export const getNotificationList =
  (id, query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { getNotificationList } = authApi;
      getNotificationList.chefId = id;
      addQuery(query, getNotificationList);
      api({ ...authApi.getNotificationList })
        .then((data) => {
          let payload = data.data;
          dispatch({
            type: notificationType.notificationListData,
            payload,
          });
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };

//update Notification List
export const updateNotificationList =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...authApi.updateNotificationList, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message, time: 2000 }));
        });
    });
  };
