import data from "mockData/listchefData";
import "./style.scss";
import React from "react";

export const ListChef = ({ title = "Why try The Chef Lane?" }) => {
  return (
    <div className="bg-gold-ff why-chef-lane">
      <div className="container">
        <div className="row py-md-5 py-4">
          <div className="col-lg-7 col-12 mx-auto">
            <h2 className="mb-md-5 mb-4">{title}</h2>
            {data.map((list, index) => (
              <div className="d-flex align-items-center my-4" key={index}>
                <div className="flex-shrink-0">
                  <img src={list.codesandbox} alt="codesandbox" />
                </div>
                <p className="flex-grow-1 ms-3 ps-md-1 mt-2 mt-md-0">
                  {list.pointone}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
