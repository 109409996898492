import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './style.scss'
import { history } from 'service/helpers'
import { Navbar } from 'component/common/Navbar'
import smiley from '../../assets/gif/smiley.gif'
import { NormalButton } from 'component/common'
const OrderplacedClass = () => {
  return (
    <div className="orderplaced">
      <div className="d-flex w-md-100 h-100 ">
        <div className="col-lg-12 col-12 mx-auto px-3 login-area">
          <div className=" mx-auto col-lg-7 col-12 h-100 ">
            <div className="d-flex align-items-center flex-column w-100">
              <Navbar />

              {/* <div className="mt-5">
                {" "}
                <img src={smiley} alt="loading..." />
              </div> */}
              <p className="fs-42 fw-700 line-height-48 text-black-33 mt-4 mb-2">
                Order placed successfully!{' '}
              </p>
              <p className="col-6 fs-16 fw-400 line-height-18 text-center text-grey-4f">
                Your Order has been placed successfully with Chef lane Please
                find the below order number XXXXXX and it is estimated to be
                delivered in 20mins
              </p>
              <div className="col-2 py-5 mb-5">
                <NormalButton
                  primary
                  label="Track Order"
                  className="w-100 fw-700  fs-16 line-height-18"
                  onClick={() => history.push('/index/trackorder')}
                />
                <NormalButton
                  optionButton
                  label="Login"
                  className="w-100 fw-700 mt-2"
                  // onClick={handleSubmit}
                  onClick={() => history.push('/auth/login')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // loginApiCall: login,
    },
    dispatch,
  )
}

let component = OrderplacedClass

export const OrderplacedComp = connect(null, mapDispatchToProps)(component)
