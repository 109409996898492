import React, { Component } from "react";
import Select from "react-select";
import triangle_arrow from "assets/images/triangle_arrow.png";

import "./select.scss";
export class NormalSelect extends Component {
  //change select
  handleChange = (newValue) => {
    let { isMulti } = this.props;
    if (!!isMulti) {
      let body = {
        target: {
          name: this.props.name,
          value: [],
        },
      };
      if (!!newValue && newValue.length) {
        newValue.forEach((array) => {
          let obj = {
            value: array.value,
            label: array.label,
          };
          body.target.value.push(obj);
        });
      }
      this.props.onChange(body);
    } else {
      let body = {
        target: {
          name: this.props.name,
          value: newValue ? newValue.value : "",
          label: newValue ? newValue.label : "",
        },
      };

      this.props.onChange(body);
    }
  };

  render() {
    let {
      className = "select-form-control w-100 fw-400 ",
      options = [],
      value = "",
      name = "",
      placeholder = "",
      disabled = false,
      triangle_arrow = false,
      label = "",
      isMulti = false,
      isClearable = false,
      isSearchable = true,
      isBoxShadow = false,
      isTableIcon = false,
    } = this.props;

    // const DropdownIndicator = (props) => {
    //   return (
    //     components.DropdownIndicator && (
    //       <components.DropdownIndicator {...props}>
    //         {isTableIcon ?
    //           <img src={downIcon} alt="chevron-down" />
    //           // ) : (
    //           // <i className={`icon-arrow-down fs-24`}></i>
    //         }
    //       </components.DropdownIndicator>
    //     )
    //   );
    // };
    const customStyles = {
      placeholder: (base) => ({
        ...base,
        // fontSize: "1em",
        color: "#cacccf",
        fontWeight: 400,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      // dropdownIndicator: (base) => ({
      //   ...base,
      //   padding: 0,
      //   height: "24px",
      //   width: "24px",
      //   color: "black",
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      // }),
      singleValue: (base) => ({
        ...base,
        color: "#2D3748",
      }),
      control: (base) => ({
        ...base,
        borderRadius: "4px",
        border: "none",
        boxShadow: `${isBoxShadow ? "0px 0px 4px rgba(0, 0, 0, 0.25)" : "0 0"
          } !important`,
        // height: "45px",
        outline: "0 ",
        background: "white",
        minHeight:"36px"
      }),
    };
    return (
      <>
        <div className={`${triangle_arrow ? "select-section" : "Select"}`}>
          {label !== "" ? (
            <div>
              <label className="font-weight-normal mb-1">{label}</label>
            </div>
          ) : null}
          {isMulti ? (
            <Select
              className={className}
              classNamePrefix="Select"
              isDisabled={disabled}
              isClearable={isClearable}
              isSearchable={isSearchable}
              name={name}
              options={options}
              onChange={this.handleChange}
              isMulti={true}
              placeholder={placeholder}
              styles={customStyles}
              value={value}
            // components={{ DropdownIndicator }}
            />
          ) : (
            <Select
            maxMenuHeight={150}
              className={className}
              classNamePrefix="Select"
              isDisabled={disabled}
              isClearable={isClearable}
              isSearchable={isSearchable}
              name={name}
              options={options}
              onChange={this.handleChange}
              isMulti={isMulti}
              placeholder={placeholder}
              styles={customStyles}
              value={
                !!options && options.length > 0
                  ? options.find((data) => data.value === value)
                    ? options.find((data) => data.value === value)
                    : null
                  : null
              }
            // components={{ DropdownIndicator }}
            />
          )}
        </div>
      </>
    );
  }
}
