import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cheflist from 'assets/images/cheflist.png'
import vegIcon from 'assets/images/veg.png'
import { NormalButton } from "component/common/NormalButton";
import { NormalSelect } from "component/common/NormalSelect";
import OrderAvailable from "./OrderAvailable";
import OrderAdvanced from "./OrderAdvanced";
import OrderServedAvailable from"./OrderServedAvailable";
function ChefOrder() {
  const [vegcategory, setvegcategory] = React.useState(false)
  const [selectfilter, updateName] = useState("");
  const FilterList = [
    {
      label: "All Orders",
      value: "all",
    },
    {
      label: "Accept",
      value: "Accept",
    },
    {
      label: "Decline",
      value: "Decline",
    },
    {
      label: "Served",
      value: "Served",
    },
    {
      label: "Accepted",
      value: "Accepted",
    },
    {
      label: "Ready",
      value: "Ready",
    }
]
 //set filter list
 const setFilterList = ({ target: { value } }) => {
  updateName(value);
};
  return (
    <>
     <div className="chef_detail_inner_container">
          <div>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="header">Active orders</div>
                <div className="col-3 select-filter">
                    <NormalSelect
                      placeholder="Filter"
                      name="selectfilter"
                      options={FilterList}
                      value={selectfilter}
                      onChange={setFilterList}
                      className="form-control rounded-0"
                    />
                </div>
              </div>
              <hr></hr>
          </div>
          <div id="order-custom" className="">
              <p className="fs-18 fw-700 text-gold mb-0"> Order ID: 12345</p>
               <div className="availble-advance-order">
                    <div className="row mt-2">
                                <div className="bitesheading col-10 line-height-36 fs-18 fw-700 text-primary-color mb-2 ">
                                  Available Now
                                </div>
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3 ">
                                  <OrderAvailable/>
                              </div>
                    </div>
                    <div className="row">
                                <div className="bitesheading col-10 line-height-36 fs-18 fw-700 text-primary-color mb-2">
                                  Advanced order
                                </div>
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-3 bg-grey-f0 p-3 ">
                                  <OrderAdvanced/>
                              </div>
                    </div>
              </div>
          </div>
    </div>
    <div className="chef_detail_inner_container mt-5">
          <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="header">Order History</div>
                
              </div>
              <hr></hr>
          </div>
          <div id="order-custom" className="">
              <p className="fs-18 fw-700 text-gold mb-0"> Order ID: 12345</p>
               <div className="availble-advance-order">
                    <div className="row mt-2">
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3 ">
                                  <OrderServedAvailable/>
                              </div>
                    </div>
              </div>
          </div>
    </div>
    </>
  )
}

// export default ChefOrder;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(ChefOrder)
