import React from 'react'
import './style.scss'
import placeOrder from 'assets/images/placeOrder.png'
import dish from 'assets/images/DishImage.png'
import chef_icon from 'assets/images/chef_Image.png'
import choosePackage from 'assets/images/choosePackage.png'
import customerConnection from 'assets/images/customerConnection.png'

export const CardsChef = () => {
  return (
    <div className="how-it-works">
      <div className="container">
        <div className="row py-md-5 py-4 d-flex justify-content-center">
          <div className="col-12 mx-auto">
            <h1 className="d-flex justify-content-center pb-2">
              Why sell on The Chef Lane?
            </h1>
            <div className="d-flex justify-content-center whycheffirstline pb-4 ">
              We work with a single mission - to enhance the lives, and
              businesses, of home chefs.
            </div>
          </div>
          <div className="col-lg-11 col-12 mx-auto">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img alt="chef" className="mx-auto h-100" src={chef_icon} />
                  <p className="pt-4 fs-18 mb-2">
                    {' '}
                    <b> Be Your Own Boss </b>
                  </p>
                  <p className=" px-md-3 mx-auto mb-md-3 mb-4">
                    Work when you want to, how you want to, and from any
                    location.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img
                    alt="chef"
                    className="mx-auto h-100"
                    src={choosePackage}
                  />
                  <p className="pt-4 fs-18 mb-2">
                    <b> Choose Your Price </b>
                  </p>
                  <p className="px-md-3 mx-auto mb-md-3 mb-4">
                    Value your Time and Effort.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="d-flex flex-column align-items-center px-md-2">
                  <img
                    alt="chef"
                    className="mx-auto h-100"
                    src={customerConnection}
                  />
                  <p className="pt-4 fs-18 mb-2">
                    <b> Connect With Your Customer</b>
                  </p>
                  <p className="px-md-3 mx-auto mb-md-3 mb-4">
                    Use your profile to build your presence, with verified
                    customers. Your personal data is secure.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pb-2 readytoy pt-3 ">
            Ready to be your own boss and cook whenever you want?
          </div>
        </div>
      </div>
    </div>
  )
}
