import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { AboutUsComp } from "component/aboutUs";
export class AboutUsClass extends Component {
  componentDidMount() {
    let element = document.getElementById("AboutUsClass");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.thecheflane.com/index/aboutUs"
          />
          <title>
            About Us | Our Eureka Moment | Homemade food delivery Service | The
            chef Lane
          </title>
          <meta
            name="title"
            content="About Us | Our Eureka Moment | Homemade food delivery Service | The chef Lane"
          />
          <meta
            name="description"
            content="We believe every person has a hidden chef inside them and The chef lane is the platform to discover the best home chefs at your city. Try the chef lane to taste the authentic food made by our renowned home chefs."
          />
          <meta
            name="keywords"
            content="homemade cake, homemade chocolate, homemade pizza, home made chocolates, home made cake, homemade food, homemade cakes near me, homemade snacks, homemade chocolate cake, home made food, homemade birthday cake, homemade pickles, homemade sweets, home cooked, home chef, chef cooked, homebakers near me, good baker, cooked dinner, cooked dishes, home cooked food delivered, home made food online, cooked food list, home cooked meals, home made food near me, home cooked meals delivered, authentic food, homebakers, home bread bakery, hand crafted chocolate, handcrafted cake, handmade cake, handmade homemade chocolate, handmade biscuits, handmade cookies"
          />
        </Helmet>
        <div id="AboutUsClass">
          <AboutUsComp />
        </div>
      </>
    );
  }
}
export const AboutUs = connect(null, null)(AboutUsClass);
