const slickData = [
    {
        key: 1,
        text: "The Chef Lane is the best for any chef.Lorem ipsum dummy text lorem ipsum dummy text Lorem ipsum dummy text lorem ipsum dummy text",
        heading: "Chef Larry Alexander ",
        ellipse: require("../assets/images/ellipse.png"),

    },
    {
        key: 2,
        text: "The Chef Lane is the best for any chef.Lorem ipsum dummy text lorem ipsum dummy text Lorem ipsum dummy text lorem ipsum dummy text",
        heading: "Chef Larry Alexander ",
        ellipse: require("../assets/images/ellipse.png"),
    },
    {
        key: 3,
        text: "The Chef Lane is the best for any chef.Lorem ipsum dummy text lorem ipsum dummy text Lorem ipsum dummy text lorem ipsum dummy text",
        heading: "Chef Larry Alexander ",
        ellipse: require("../assets/images/ellipse.png"),
    },
    {
        key: 4,
        text: "The Chef Lane is the best for any chef.Lorem ipsum dummy text lorem ipsum dummy text Lorem ipsum dummy text lorem ipsum dummy text",
        heading: "Chef Larry Alexander ",
        ellipse: require("../assets/images/ellipse.png"),
    },




];
export default slickData;
