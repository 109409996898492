import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { CustomeraccountComp } from "component/auth/login/Customeraccount";
export class CustomeraccountClass extends Component {
  render() {
    return < CustomeraccountComp />;
  }
}
export const Customeraccount = connect(null, null)(CustomeraccountClass);
