import React, { useState, useEffect } from 'react'
import './style.scss'
import { NormalButton } from 'component/common/NormalButton'
import { history } from 'service/helpers'
const OrderCreationClass = (props) => {
  return (
    <div className="orderOrderCreation_container">
      <div className="container">
        <div className="text-black-0 fw-700  order-container-heading line-height-28 d-flex  justify-content-center ">
          You order has been created, your order ID is
          <span className="text-gold "> &nbsp; 234567</span>
        </div>
        <div className="order-container  ">
          <div className="text-primary-color fw-700 fs-18 line-height-20  ">
            Order summary{' '}
          </div>
          <div>
            <div className="text-black-0 fw-700 fs-18 mb-2 mt-4 line-height-20">
              Available Now
            </div>
            <div className="d-flex justify-content-between">
              <div>1 x Chicken Salad</div>
              <div>₹230.00</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>1 x Cream of Tartar</div>
              <div>₹200.00</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Delivery charges</div>
              <div>₹50.00</div>
            </div>
            <div className="d-flex justify-content-between pt-2">
              <div className="fw-350 fs-16 line-height-18 ">
                <b>Sub Total</b>{' '}
              </div>
              <div className="fw-350 fs-16 line-height-18 ">
                <b>₹480.00</b>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="text-black-00 fw-700 fs-18 line-height-20 mb-2">
              Advance order
            </div>
            <div className="d-flex justify-content-between">
              <div>1 x Shallots </div>
              <div>₹500.00</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Delivery charges </div>
              <div>₹50.00</div>
            </div>
            <div className="d-flex justify-content-between pt-2">
              <div className="fw-350 fs-16 line-height-18 ">
                <b>Sub Total</b>{' '}
              </div>
              <div className="fw-350 fs-16 line-height-18 ">
                <b>₹480.00</b>
              </div>
            </div>
          </div>
          <hr />

          <div className="d-flex justify-content-between ">
            <div>Tax (estimated for india )</div>
            <div>₹50.00</div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="text-primary-color fw-700 fs-18">Grand Total</div>
            <div className="text-primary-color">
              <b>₹1092.00</b>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-center mt-5 ">
            <NormalButton
              label="Proceed to pay "
              className="bg-primary-color text-white-FF create-order-btn"
              onClick={() => history.push('/index/orderplaced')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrderCreationComp = OrderCreationClass
