import React, { useState, useEffect } from "react";
import { NormalButton } from "component/common/NormalButton";
import { history } from "service/helpers";
import correct from "../../../assets/images/correct.png";
import wrong from "../../../assets/images/wrong.png";
import "./style.scss";

export const Table = ({ country }) => {
  // const [service, setService] = useState('');
  // useEffect(() => {
  //   localStorage.setItem("service", service);
  // }, [service])
  const handleSelectPlan = (service) => {
    localStorage.setItem("service", service);
    //console.log(selectService, "selectService");
    history.push(`/auth/signup`);
  };

  return (
    <div className="bg-yellow-ff">
      <div className="container pb-5 Table">
        <div className="row d-flex justify-content-center">
          <h1 className=" fw-700 text-primary-color text-center Compare_different_Packages pb-4 pt-5">
            Compare different Packages
          </h1>
          <div class="col-lg-10 col-12">
            <div class="card border-0 ">
              <div class="card-body">
                <table class="table table-bordered ">
                  <thead>
                    <tr className="heading">
                      <th scope="col">
                        <p className="mb-2 fs-20 fw-700 text-black-25">
                          Packages
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto my-2 fs-20 fw-700 text-black-25">
                          Listing
                        </p>
                      </th>
                      <th className="text-center" scope="col">
                        <p className="col-md-6 col-12 mx-auto my-2 fs-20 fw-700 text-black-25">
                          ListingPLUS
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="w-40">
                      <th className="fs-18 py-4 fw-700 text-black-25">
                        Discovery
                      </th>

                      <td className="text-center py-4">
                        <img
                          alt="correct"
                          className="text-center icon"
                          src={correct}
                        />
                      </td>
                      <td className="text-center py-4">
                        <img
                          alt="correct"
                          className="text-center icon"
                          src={correct}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th className="fs-18 fw-700 text-black-25" scope="row">
                        Ordering &amp; Delivery
                      </th>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                      </td>
                      <td className="text-center">
                        <img alt="correct " className="icon" src={correct} />
                      </td>
                    </tr>
                    <tr>
                      <th className="fs-18 fw-700 text-black-25" scope="row">
                        Unlimited updates to Menu
                      </th>
                      <td className="text-center">
                        <img alt="correct" className="icon" src={correct} />
                      </td>
                      <td className="text-center">
                        <img alt="correct" className="icon" src={correct} />
                      </td>
                    </tr>
                    <tr>
                      <th className="fs-18 fw-700 text-black-25" scope="row">
                        Monthy Pricing
                      </th>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        {" "}
                        {country === "IN" ? " Free" : " Free"}
                      </td>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        Coming soon
                      </td>
                    </tr>
                    <tr>
                      <th className="fs-18 fw-700 text-black-25" scope="row">
                        Yearly Pricing
                      </th>
                      <td className="text-center fs-16 fw-400 text-black-25 ">
                        {country === "IN" ? " Free" : " Free"}
                      </td>
                      <td className="text-center fs-16 fw-400 text-black-25">
                        Coming soon
                      </td>
                    </tr>
                    <tr>
                      <th className="fs-18 fw-700 text-black-25" scope="row ">
                        Transaction Fees
                      </th>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                        <div className="mt-4 d-flex justify-content-center">
                          <NormalButton
                            primary
                            // label={
                            //   service === "LISTING" ? "Selected" : "Select"
                            // }
                            label="Select"
                            className="px-5  fw-700 width-140"
                            onClick={() => {
                              handleSelectPlan("LISTING");
                            }}
                          />
                        </div>
                      </td>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                        <div className="mt-4 pt-2 d-flex justify-content-center">
                          {/* <NormalButton
                            disabled={true}
                            primary
                            // label={
                            //   service === "LISTINGPLUS" ? "Selected" : "Select"
                            // }
                            label="Coming soon"
                            className="px-5 fw-700 width-140"
                            onClick={() => {
                              handleSelectPlan("LISTINGPLUS");
                            }}
                          /> */}
                          Coming soon
                        </div>
                      </td>
                      {/* <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                      </td>
                      <td className="text-center">
                        <img alt="wrong" className="icon" src={wrong} />
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-end">
              <a
                href="/index/t&c"
                className="fs-16 line-height-20 fw-400 text-black-25 pt-3 cursor-pointer hover-black-25"
                target="_blank"
              >
                Terms &amp; Conditions apply
              </a>
            </div>
            <div className="text-center pt-0">
              {/* <p className="fs-24 fw-700 text-black-25 line-height-32 ">
                Sign up and avail 3 months free with The Chef Lane{" "}
              </p> */}
              {/* <NormalButton
                onClick={() => history.push(`/auth/signup`)}
                primary
                label="Get started "
                className="my-4 col-md-2  mx-auto fw-700 getstarted  line-height-18 cursor-pointer"
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
