//validation Rules
export const validationRules = () => {
  return {
    email: {
      presence: {
        allowEmpty: false,
        message: '^Email is required',
      },
      format: {
        pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
        flags: 'i',
        message: '^Email id is incorrect',
      },
    },
  }
}
