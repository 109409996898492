import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ContactUsComp } from "component/ContactUs";
export class ContactUsClass extends Component {
  componentDidMount() {
    let element = document.getElementById("contact-us");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.thecheflane.com/index/contactus"
          />
          <title>
            Contact Us | Fill up the details to serve better | The Chef Lane
          </title>
          <meta
            property="og:title"
            content="Contact Us | Fill up the details to serve better | The Chef Lane"
          />
          <meta
            name="description"
            content="The Chef Lane: Do you have any query? Feel free to contact us by filling the details and one from our team will contact you shortly. We are just a click away from you."
          />
          <meta
            name="keywords"
            content="homemade cake, homemade chocolate, homemade pizza, home made chocolates, home made cake, homemade food, homemade cakes near me, homemade snacks, homemade chocolate cake, home made food, homemade birthday cake, homemade pickles, homemade sweets, home cooked, home chef, chef cooked, homebakers near me, good baker, cooked dinner, cooked dishes, home cooked food delivered, home made food online, cooked food list, home cooked meals, home made food near me, home cooked meals delivered, authentic food, homebakers, home bread bakery, hand crafted chocolate, handcrafted cake, handmade cake, handmade homemade chocolate, handmade biscuits, handmade cookies"
          />
        </Helmet>
        <div id="contact-us">
          <ContactUsComp />
        </div>
      </>
    );
  }
}
export const ContactUs = connect(null, null)(ContactUsClass);
