import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { NormalButton } from "component/common/NormalButton";
import  UnderReviewTrial  from "./ReviewTrial/index";
import orderplace from "assets/images/orderplace.png";
import { AddReview } from 'action/OrderReviewAct'
const ReviewOrderRate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTrial, setisTrial] = useState(false);
  
  const handleSubmit = (e) => {
    setisTrial(true)
  }
  return (
                <>
                  <div className="">
      <div className="main_container container-track pt-5 mb-4">
        <div class="container padding-bottom-3x mb-1">
          <div class="card mb-3">
            <div class="card-body">
              <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <div class="step completed">
                  <div class="step-icon-wrap">
                    <div class="step-icon">
                      {" "}
                      
                    </div>
                  </div>
                  <h4 class="step-title fs-16 text-black fw-700 ">
                    Order placed
                  </h4>
                </div>
                <div class="step completed">
                  <div class="step-icon-wrap">
                    <div class="step-icon"></div>
                  </div>
                  <h4 class="step-title fs-16 text-black fw-700">
                    Preparing order
                  </h4>
                </div>
                <div class="step completed">
                  <div class="step-icon-wrap">
                    <div class="step-icon"></div>
                  </div>
                  <h4 class="step-title fs-16 text-black fw-700">
                    {" "}
                    Order packed
                  </h4>
                </div>
                <div class="step">
                  <div class="step-icon-wrap">
                    <div class="step-icon"></div>
                  </div>
                  <h4 class="step-title fs-16 text-black fw-700">
                    Out for delivery
                  </h4>
                </div>
                <div class="step order-rating">
                  <div class="step-icon-wrap">
                    <div class="step-icon-image">
                      <img
                        src={orderplace}
                        className="w-100"
                        alt=""
                      />
                    </div>
                  </div>
                  <h4 class="step-title fs-16 text-black mt-5 pt-4 fw-700">
                    Order Delivered
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div class="track-button d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
            <NormalButton
              label="Rate & Review Order"
              className="order-review text-white line-height-18 fs-16 mx-3 fw-400 p-0"
              onClick = {handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
    {
                isTrial &&
                <UnderReviewTrial />
 }
            </>
  );
};


const mapDispatchToProps = (dispatch) => {
  
  return bindActionCreators(
    {
      AddReview
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(ReviewOrderRate)