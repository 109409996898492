import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import fssai from "assets/svg/fssai.svg";
import Instagram from "assets/images/Instagram.png";
import facebook from "assets/images/facebook.png";
import twitter from "assets/images/twitter.png";
import chefimage from "assets/images/chefimage.png";
import ratingicon from "assets/images/ratingicon.png";
import shareicon from "assets/images/share.png";
import map from "assets/images/map1.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { history } from "service/helpers";
import "./style.scss";
import ChefPdf from "component/common/ChefPdf";
import shareYellow from "assets/images/shareYellow.png";
import * as L from "leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import { NormalButton, Marker } from "component/common";
import copyIcon from "assets/images/copyIcon.png";
import { FRONT_END_PORT } from "utils/constants";
import { getSingle } from "action/ChefDetailsAct";
import GoogleMapReact from "google-map-react";
import markerIcon from "assets/svg/marker.svg";

import { useReactToPrint } from "react-to-print";
import { convertToSlug } from "service/helperFunctions";
import { getChefPdf } from "action/ChefDetailsAct";
import "./style.scss";

const AboutChef = ({ list, getSingle, getChefPdf }) => {
  console.log(list, "list");
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showShareResponsive, setshowShareResponsive] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const token = localStorage.getItem("authToken");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isCopy, setIsCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const [isDownload, setIsDownload] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const wrapperRef = useRef(null);
  const wrapperRefResponsive = useRef(null);
  const handleLearnmore = () => {
    setLearnMore(!learnMore);
  };
  const provider = new OpenStreetMapProvider();
  useEffect(() => {
    // var theMarker = {}
    // let { AddressDetails } = list
    // console.log(AddressDetails)
    // var latlng = AddressDetails.location
    //   ? L.latLng(
    //       Number(AddressDetails.location.lat),
    //       Number(AddressDetails.location.long),
    //     )
    //   : L.latLng(13.0827, 80.2707)
    // // var latlng = L.latLng(13.0827, 80.2707);
    // var map = L.map('map').setView([latlng.lat, latlng.lng], 14)
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution:
    //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    // }).addTo(map)
    // var marker = L.marker(latlng, {
    //   draggable: false,
    //   autoPan: true,
    //   // icon: myIcon
    // }).addTo(map)
    // const searchControl = new GeoSearchControl({
    //   style: 'button',
    //   notFoundMessage: 'Sorry, that address could not be found.',
    //   searchLabel: 'Enter address',
    //   autoClose: true,
    //   provider: new OpenStreetMapProvider(),
    //   showMarker: true,
    //   marker: marker,
    // })
    // map.on('geosearch/showlocation', function (result) {
    //   console.log(result)
    //   let { location } = result
    //   let lat = location.y
    //   let lng = location.x
    //   // console.log(bounds)
    //   // let marker = bounds[0];
    //   if (marker) {
    //     // map.removeControl(marker);
    //   }
    //   // if (theMarker !== undefined) {
    //   //   map.removeLayer(theMarker);
    //   //   // map.removeLayer(marker);
    //   // }
    //   //  theMarker = L.marker([lat,lng]).addTo(map);
    //   marker.setLatLng([lat, lng])
    //   //  if(!!location)
    //   //     L.marker(location.x, location.y).addTo(map)
    // })
    // map.on('click', function (e) {
    //   let lat = e.latlng.lat
    //   let lng = e.latlng.lng
    //   console.log('You clicked the map at LAT: ' + lat + ' and LONG: ' + lng)
    //   //Clear existing marker,
    //   // if (theMarker !== undefined) {
    //   //   map.removeLayer(theMarker);
    //   //   // map.removeLayer(marker);
    //   // };
    //   //Add a marker to show where you clicked.
    //   // theMarker = L.marker([lat, lng]).addTo(map);
    //   marker.setLatLng([lat, lng])
    // })
    // // const myIcon = L.icon({
    // //   iconUrl: markerIcon,
    // // });
    // map.addControl(searchControl)
    // // searchControl.on('results', function (data) {
    // //   results.clearLayers();
    // //   console.log(data)
    // //   for (var i = data.results.length - 1; i >= 0; i--) {
    // //     results.addLayer(L.marker(data.results[i].latlng));
    // //   }
    // // });
    // const form = document.querySelector('form')
    // const input = form.querySelector('input[type="text"]')
    // form.addEventListener('submit', async (event) => {
    //   event.preventDefault()
    //   console.log(input.value)
    //   // const results = await provider.search({ query: input.value });
    //   provider.search({ query: '...' }).then(function (result) {
    //     // do something with result;
    //     console.log(result) // » [{}, {}, {}, ...]
    //   })
    //   // console.log(results); // » [{}, {}, {}, ...]
    // })
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("click", handleClickOutsideReponsive, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("click", handleClickOutsideReponsive, false);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
    }
  };
  const handleClickOutsideReponsive = (event) => {
    if (
      wrapperRefResponsive.current &&
      !wrapperRefResponsive.current.contains(event.target)
    ) {
      setshowShareResponsive(false);
    }
  };
  // const fetchChefDetails = async () => {
  //   let id = localStorage.getItem("userId");
  //   setIsLoading(true);
  //   await getSingle(id).then(({ data }) => {

  //     if (!!data) {
  //       console.log(data,"dffg")
  //       // let newForm = Object.assign(formData, data);
  //       // setChefDetail(data);
  //       // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
  //     }
  //     setIsLoading(false);
  //   })
  //     .catch((e) => setIsLoading(false));
  // }
  const handleCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };
  let { preference = [] } = list;
  let imagePreference = preference.includes("Non-Vegetarian")
    ? nonvegIcon
    : preference.includes("Vegetarian")
    ? vegIcon
    : "";
  let {
    AddressDetails: { location },
    facebook,
    instagram,
  } = list;
  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,

      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      // minZoom: 11,
      // maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      clickableIcons: false,
      zoomControl: true,
      scrollwheel: false,
      draggable: false,
    };
  };
  const handleDownloadPdf = async (id) => {
    setIsDownload(true);
    await getChefPdf(id)
      .then((data) => {
        if (data.link) {
          var file_path = data.link;
          var a = document.createElement("A");
          a.href = file_path;
          a.target = "_blank";
          a.download = file_path.substr(file_path);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsDownload(false);
      })
      .catch(() => {
        setIsDownload(false);
      });
  };
  return (
    <div className="aboutChefDeatil custom-box-shadow p-3">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <div className=" d-flex chef-account-profile mobile-profie-chef">
            <div className=" d-flex align-items-center">
              <div className="chefprofileimage">
                <img src={list.chefImage} alt="" srcset="" />
              </div>
              <div className="sharecontainer_res mt-2 ms-2">
                {/* {!!token && ( */}
                <div className=" d-flex text-center mb-2">
                  <div ref={wrapperRefResponsive} className="position-relative">
                    <span>
                      <NormalButton
                        label="Share"
                        className="w-100 fw-500 outline-gray-btn"
                        onClick={() => {
                          setshowShareResponsive(!showShareResponsive);
                        }}
                        prefix={shareYellow}
                      />
                    </span>
                    {showShareResponsive && (
                      <div className="share-position">
                        {/* {!!token && ( */}
                        <NormalButton
                          disabled={isDownload}
                          label="as .pdf"
                          className="w-100 fw-500 outline-gray-btn"
                          onClick={() => {
                            // handlePrint()
                            handleDownloadPdf(list._id);
                            // history.push(`/index/chefpdf/${list._id}/user`)
                          }}
                          // prefix={shareYellow}
                        />
                        {/* )} */}
                        <div className="">
                          <NormalButton
                            label="Copy link"
                            className="w-100 fw-500 outline-gray-btn"
                            // onClick={() => setIsCopy(!isCopy)}
                            onClick={() => {
                              handleCopyText(
                                `${FRONT_END_PORT}${convertToSlug(
                                  list.businessNameSlug
                                )}`
                              );
                              setshowShareResponsive(false);
                            }}
                            // prefix={shareYellow}
                          />
                          {isCopy && (
                            <div className="position-copy">
                              <p className="mb-0 text-gold">
                                doodlebluelive.com:2013/
                                {list.businessName}
                              </p>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  handleCopyText(
                                    `${FRONT_END_PORT}${convertToSlug(
                                      list.businessNameSlug
                                    )}`
                                  );
                                }}
                              >
                                <img src={copyIcon} alt="icon" />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* )} */}
                {!!token && list.countryCode === "+91" ? (
                  <div className="d-flex align-items-center justify-content-center flex-wrap ">
                    <img src={fssai} alt="fssai" className="" />
                    <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center ">
                      {" "}
                      Lic No: {list.LicenseDetails.licenseNumber}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {list.LicenseDetails.adminApproved === "adminApproved" ||
                list.countryCode === "+91" ? (
                  ""
                ) : (
                  <>
                    {" "}
                    {!!token && list?.LicenseDetails?.licenseNumber ? (
                      <>
                        <span className="d-flex align-items-center w-100 fw-600 pt-2 text-black fs-10 justify-content-center">
                          Registered with Food Safety Authority
                        </span>
                        <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                          {" "}
                          Lic No: {list.LicenseDetails.licenseNumber}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="container position-relative">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="name_image d-flex mb-2 ">
                    <span className="chefname fs-18 line-height-20 me-2 sm-line-height-20 fw-700 text-black-25 text-nowrap">
                      {`${list.businessName} `}
                    </span>
                    <div className="chef_viewcontainer d-flex justify-content-between ms-3">
                      {/* {list.countryCode === "+91" && (
                        <span className="d-flex align-items-center ">
                          <span className="text-primary-color fs-16 line-height-18 fw-700 text-nowrap">
                            <a
                              href="https://foscos.fssai.gov.in"
                              className="text-primary-color"
                              target="_blank"
                            >
                              FSSAI Certified
                            </a>
                          </span>
                        </span>
                      )} */}
                      {/* <span className="d-flex align-items-center">
                <img src={ratingicon} alt="" srcset="" />
              </span>
                  <span className="d-flex align-items-center">
                    <span className="text-primary-color fs-16 line-height-18 fw-700">
                      4.8
                    </span>
                  </span> */}
                    </div>
                  </div>
                  {/* </div> */}
                  <p className="address mt-1 line-height-16 fw-600 fs-16 text-secondary-color  mb-3 d-block">
                    {/* {list.cuisine.join(', ')} */}
                    {list.cuisine.join(", ").length < 60 ? (
                      list.cuisine.join(", ")
                    ) : (
                      <React.Fragment>
                        {list.cuisine.join(", ").substring(0, 59)}{" "}
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.cuisine.join(", ")}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className="text-yellow-C1 cursor-pointer fs-14 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="sharecontainer">
                  {/* {!!token && ( */}
                  <div className=" d-flex text-center justify-content-center mb-2">
                    <div ref={wrapperRef} className="position-relative">
                      <span>
                        <NormalButton
                          label="Share"
                          className="w-100 fw-500 outline-gray-btn"
                          onClick={() => {
                            setShowShare(!showShare);
                            setIsCopy(false);
                          }}
                          prefix={shareYellow}
                        />
                      </span>
                      {showShare && (
                        <div className="share-position">
                          {/* {!!token && ( */}
                          <NormalButton
                            disabled={isDownload}
                            label="as .pdf"
                            className="w-100 fw-500 outline-gray-btn"
                            onClick={() => {
                              // handlePrint()
                              handleDownloadPdf(list._id);
                              // history.push/index/chefpdf/${list._id}/user`)
                            }}
                            // prefix={shareYellow}
                          />
                          {/* )} */}
                          <div className="">
                            <NormalButton
                              label="Copy link"
                              className="w-100 fw-500 outline-gray-btn"
                              // onClick={() => setIsCopy(!isCopy)}
                              onClick={() => {
                                handleCopyText(
                                  `${FRONT_END_PORT}${convertToSlug(
                                    list.businessNameSlug
                                  )}`
                                );
                                setShowShare(false);
                              }}
                              // prefix={shareYellow}
                            />
                            {isCopy && (
                              <div className="position-copy">
                                <p className="mb-0 text-gold">
                                  doodlebluelive.com:2013/
                                  {list.businessName}
                                </p>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    handleCopyText(
                                      `${FRONT_END_PORT}${convertToSlug(
                                        list.businessNameSlug
                                      )}`
                                    );
                                  }}
                                >
                                  <img src={copyIcon} alt="icon" />
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                  {!!token && list.countryCode === "+91" ? (
                    <div className="d-flex align-items-center flex-wrap justify-content-center">
                      <img src={fssai} alt="fssai" className="" />
                      <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                        {" "}
                        Lic No: {list.LicenseDetails.licenseNumber}
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {list.LicenseDetails.adminApproved === "adminApproved" ||
                  list.countryCode === "+91" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      {!!token && list?.LicenseDetails?.licenseNumber ? (
                        <>
                          <span className="d-flex align-items-center w-100 fw-600 pt-2 text-black fs-10 justify-content-center">
                            Registered with Food Safety Authority
                          </span>
                          <span className="d-flex align-items-center w-100 text-black fs-10 justify-content-center">
                            {" "}
                            Lic No: {list.LicenseDetails.licenseNumber}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
              <p className="about about-min-height fs-14 text-grey-7c fw-400 line-height-18 mb-0">
                {list.AboutDetails.About.length < 130 ? (
                  list.AboutDetails.About
                ) : learnMore ? (
                  <React.Fragment>
                    {list.AboutDetails.About}{" "}
                    <span
                      className="text-yellow-C1 cursor-pointer"
                      onClick={handleLearnmore}
                    >
                      ...Less
                    </span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {list.AboutDetails.About.substring(0, 129)}{" "}
                    <span
                      className="text-yellow-C1 cursor-pointer"
                      onClick={handleLearnmore}
                    >
                      Read more...
                    </span>
                  </React.Fragment>
                )}
              </p>
              <div className="content-login-update">
                {!!token ? (
                  <>
                    <div className="d-block content-user-login">
                      <div>
                        <p className="fs-14 text-primary-color fw-700 line-height-16 mb-2 mt-2">
                          <a
                            className="phone text-primary-color"
                            href={`tel:${list.mobileNumber}`}
                          >
                            {list.countryCode}
                            {list.mobileNumber}
                          </a>
                        </p>
                        <p className=" fs-14 text-grey-7c line-height-16 mb-2">
                          <a
                            className=" email text-grey-7c text-underline"
                            href={`mailto:${list.email}`}
                          >
                            {list.email}
                          </a>
                        </p>
                        <div className="socialmedia">
                          {!!token && instagram.instagramLink && (
                            <span className="mr-2">
                              <a
                                href={
                                  instagram.instagramLink.includes(
                                    "http" || "https"
                                  )
                                    ? instagram.instagramLink
                                    : "https://" + instagram.instagramLink
                                }
                                target="_blank"
                              >
                                <img src={Instagram} alt="" srcset="" />
                              </a>
                            </span>
                          )}

                          {!!token && facebook.facebookLink && (
                            <span className="mr-2">
                              <a
                                href={
                                  facebook.facebookLink.includes(
                                    "http" || "https"
                                  )
                                    ? facebook.facebookLink
                                    : "https://" + facebook.facebookLink
                                }
                                target="_blank"
                              >
                                <img src={facebook} alt="" srcset="" />
                              </a>
                            </span>
                          )}
                        </div>
                        <div className="fs-14 fw-400 text-grey-7c addressrespon line-height-16 text-start mt-1">
                          {list.AddressDetails.address1 !== "" &&
                            list.AddressDetails.address1 + ","}{" "}
                          {list.AddressDetails.address2 !== "" &&
                            list.AddressDetails.address2 + ","}{" "}
                          {/* <br /> */}
                          {list.AddressDetails &&
                            list.AddressDetails.city}, {/* <br /> */}
                          {list.AddressDetails &&
                            list.AddressDetails.state},{" "}
                          {list.AddressDetails && list.AddressDetails.pincode}{" "}
                          {/* {list.AddressDetails && list.AddressDetails.country} */}
                        </div>
                        {!list?.availabilityStatus ? (
                          <div className="text-red-D7 fs-18 fw-700 mt-3 text-center">
                            The Chef is currently unavailable
                          </div>
                        ) : (
                          ""
                        )}
                        {/* list?.islicensedExpried && list?.islicensedExpried ? (
                        <div className="text-red-D7 fs-18 fw-700 mt-3 text-center">
                          This Chef’s License has been expired.
                        </div>
                        ) */}
                      </div>
                      {/* <div className="d-flex align-items-start mt-2">
                        <div className="fs-14 fw-400 text-grey-7c addressrespon line-height-16 text-end">
                          {list.AddressDetails.address1 !== '' &&
                            list.AddressDetails.address1 + ','}{' '}
                          {list.AddressDetails.address2 !== '' &&
                            list.AddressDetails.address2 + ','}{' '}
                         
                          {list.AddressDetails &&
                            list.AddressDetails.city},{' '}
                          {list.AddressDetails && list.AddressDetails.pincode},{' '}
                         
                          {list.AddressDetails &&
                            list.AddressDetails.state},{' '}
                          {list.AddressDetails && list.AddressDetails.country}
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : (
                  <div className="d-flex mt-5">
                    <p className="fw-700 fs-16 text-grey-b3 mt-5 mb-0 toView-position">
                      Please login to view chef details
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-4 text-right m-auto">
          <div className="">
            {!!token && (
              <div id="map-outline-chef">
                <div
                  id="map"
                  className=""
                  // onClick={() => {
                  //   window.open(
                  //     `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`,
                  //   )
                  // }}
                >
                  <GoogleMapReact
                    center={
                      location
                        ? {
                            lat: Number(location.lat),
                            lng: Number(location.long),
                          }
                        : {
                            lat: 13.0827,
                            lng: 80.2707,
                          }
                    }
                    defaultZoom={18}
                    onClick={(e) => {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                      );
                    }}
                    options={getMapOptions}
                  >
                    {location && (
                      <Marker
                        lat={location.lat}
                        lng={location.long}
                        name="My Marker"
                        color=""
                        isInfo={false}
                        infoWindowOpen={false}
                        infoWindowContent={
                          <div className="info-outline-small d-flex justify-content-center">
                            <div className="">
                              <span className="text-red fs-12">
                                You are here!
                              </span>
                            </div>
                            <span className="rectang"></span>
                          </div>
                        }
                        children={
                          <img
                            src={markerIcon}
                            onClick={() => {
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                              );
                              // setInfoWindowOpen(!infoWindowOpen)
                            }}
                            alt="icon"
                          />
                        }
                      />
                    )}
                  </GoogleMapReact>
                </div>
              </div>
            )}
          </div>
          <div
            className="text-grey-7c fs-14 line-height-16 fw-325 mt-2"
            style={{ display: "none" }}
          >
            {" "}
            {/* 2118 Thornridge Cir. Syracuse, Connecticut 35624 */}
          </div>
          <div className="test-pdf">
            <div style={{ display: "none" }}>
              {/* <ChefPdf ref={componentRef} /> */}
              {list._id && (
                <ComponentToPrint ref={componentRef} chefId={list._id} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default MenuDetails;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSingle,
      getChefPdf,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(AboutChef);
class ComponentToPrint extends React.Component {
  render() {
    return <>{/* <ChefPdf chefId={this.props.chefId} /> */}</>;
  }
}
