//validation Rules
export const validationRules = () => {
  let otpValidation = {
    format: {
      pattern: /^[0-9]*$/,
      flags: 'i',
      message: '^OTP is incorrect',
    },
  }
  return {
    emailotp: {
      presence: {
        allowEmpty: false,
        message: '^Email OTP is required',
      },
      ...otpValidation,
    },
    mobileotp: {
      presence: {
        allowEmpty: false,
        message: '^Mobile OTP is required',
      },
      ...otpValidation,
    },
  }
}
