import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { NormalButton,NormalInput } from "component/common";
import{ TableWrapper }from "component/common/TableWrapper";
import staricon from "assets/images/Staricon.png"
function RateReview() {
  const [vegcategory, setvegcategory] = useState(false)
  const [pageMeta,setPageMeta] = useState({page:1,totalPages:2})
  const headerDetails = [
    {
      label: "S.No",
    },
    {
      label: "Name",
    },
    {
      label: "Review",
    },
    {
      label: "Rating",
    },
    {
      label: "Status",
    }
  ];
  const tableDataDetails = [
    {
      title: 'Eduardo',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'Colleen',
      description: 'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'Kristin',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'Kristin',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'reg',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'Arlene',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
    {
      title: 'Esther',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna....',
    },
    {
      title: 'Max',
      description:
        'Lorem ipsum dolor ed amet magna aliqua adipiscing magna...',
    },
  ];
  return (
    <>
     <div className="rate_detail_inner_container">
          <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="header">Ratings & Reviews</div>
                
              </div>
              <hr></hr>
              <TableWrapper headerDetails={headerDetails} pageMeta={pageMeta}>
                  {tableDataDetails.map((list, index) => {
                      return (
                           <tr className="my-2">
                                    <td class="py-5 ps-4">{ index+1}</td>
                                    <td  class="py-5">
                                      <div className="d-flex fs-14 align-items-center  text-secondary-color  h-100">
                                        <p className="mb-0">
                                          {list.title}
                                        </p>
                                      </div>
                                    </td>
                                    <td  class="py-5">
                                      <div className="d-flex fs-14 align-items-center text-secondary-color  h-100">
                                          {list.description}
                                      </div>
                                    </td>
                                    <td  class="py-5">
                                      <div className="d-flex fs-14 align-items-center text-gold h-100">
                                         <img src={staricon}className="me-2"/> 3.9
                                      </div>
                                    </td>
                                    <td  class="py-5">
                                      <div className="d-flex align-items-center  h-100">
                                          <NormalButton
                                            label="Publish"
                                            className="clear text-white line-height-18 fs-16 me-3 bg-primary-color fw-400 py-2 px-3"
                                          />
                                          <NormalButton
                                            label="Unpublished"
                                            className="clear text-black-25 unpublish line-height-18 bg-white fs-16  fw-400 py-2 px-3"
                                          />
                                      </div>
                                    </td>
                              </tr>
                          ) 
                       })}
              </TableWrapper>
          </div>
    </div>
    
    </>
  )
}

// export default ChefOrder;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(RateReview)
