import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { ChefDetailComp } from "component/ChefDatail/ChefDetail";
export class ChaifDetailClass extends Component {
  render() {
    return <ChefDetailComp />;
  }
}
export const ChefDetail = connect(null, null)(ChaifDetailClass);
