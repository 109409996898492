import React, { useState, useEffect } from 'react'
import './style.scss'
import { NormalButton } from 'component/common/NormalButton'
import MenuCard from './MenuCard'
import { history } from 'service/helpers'
import { getAllCartById } from 'action/cartAct'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
const CartComp = ({ getAllCartById }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setDate] = useState(true)

  useEffect(() => {
    fetchCartDetails()
  }, [])
  const fetchCartDetails = async () => {
    let userDetails = JSON.parse(localStorage.getItem('userDetailsWeb'))

    setIsLoading(true)
    await getAllCartById(userDetails?._id)
      .then(({ data }) => {
        if (!!data) {
          console.log(data, 'dffg')
          // let newForm = Object.assign(formData, data);
          setDate(data)
          setIsLoading(false)
        }
      })
      .catch((e) => setIsLoading(false))
  }
  const menu = [{}, {}]
  const menuAdvancedorder = [{}]
  const list = [{}]
  return (
    <div className="cart_container ">
      <div className="container-fluid pt-1 pb-2 px-5">
        <div className="row ">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xm-12 left-right-container">
            <div className="d-flex justify-content-between">
              <div className="text-black-00 fw-700 fs-24  cart-summary">
                Cart summary
              </div>
              <div className="add-more-div">
                <NormalButton
                  optionButton
                  label="Add more "
                  className="text-black-00 fw-700 fs-24 add-more"
                />
              </div>
            </div>
            <div className="row">
              {menu.length > 0 ? (
                menu.map((list, index) => (
                  <div
                    className="menu_card col-xl-12 col-lg-12 mt-3 pt-1 col-sm-12 col-md-12 position-relative "
                    key={index}
                  >
                    <MenuCard list={list} />
                  </div>
                ))
              ) : (
                <div className="col-12 mt-3 pt-1">
                  <p className="text-center fs-16 fw-600 text-black-1c">
                    No data found !!!
                  </p>
                </div>
              )}
            </div>
            <div className="fw-700 fs-18 text-primary-color pt-2 line-height-20">
              Advance order for tommorrow
            </div>
            <div className="row">
              {menu.length > 0 ? (
                menuAdvancedorder.map((list, index) => (
                  <div
                    className="menu_card col-xl-12 col-lg-12 mt-3 pt-1 col-sm-12 col-md-12 position-relative "
                    key={index}
                  >
                    <MenuCard list={list} />
                  </div>
                ))
              ) : (
                <div className="col-12 mt-3 pt-1">
                  <p className="text-center fs-16 fw-600 text-black-1c">
                    No data found !!!
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12  col-xm-12 left-right-container">
            <div className="text-black-00 fw-700 fs-24">Price summary</div>
            <div>
              <div className="text-black-00 fw-700 fs-18 mb-2 mt-4">
                Available Now
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="fs-16 fw-400 mb-0">Item Total </h4>
                <p>₹430.00</p>
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="fs-16 fw-400 mb-0">Delivery charges </h4>
                <p>₹50.00</p>
              </div>
            </div>
            <hr />
            <div>
              <div className="text-black-00 fw-700 fs-18 mb-2">
                Advance order
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="fs-16 fw-400 mb-0">Item Total </h4>
                <p>₹430.00</p>
              </div>
              <div className="d-flex justify-content-between">
                <h4 className="fs-16 fw-400 mb-0">Delivery charges </h4>
                <p>₹50.00</p>
              </div>
            </div>
            <hr />

            <div className="d-flex justify-content-between">
              <div>
                Tax <br />
                (estimated for india )
              </div>
              <div>₹50.00</div>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <div className="text-primary-color fw-700 fs-18">Grand Total</div>
              <div className="text-primary-color fw-700 fs-18">₹1092.00</div>
            </div>
            <hr />
            <div className="d-flex justify-content-center mt-5">
              <NormalButton
                label="Create order"
                className="bg-primary-color text-white-FF create-order-btn"
                onClick={() => history.push('/index/order-creation')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export const CartComp = CartClass

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllCartById,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(CartComp)
