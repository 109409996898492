import React, { useState } from 'react'
import './style.scss'
import Shallots from '../../assets/images/Shallots.png'
import nonvegIcon from 'assets/images/nonveg.png'
import vegIcon from 'assets/images/veg.png'
import { NormalButton } from 'component/common/NormalButton'
const MenuCard = ({ list }) => {
  const [counter, setCounter] = useState(1)
  const incrementCounter = () => setCounter(counter + 1)
  let decrementCounter = () => {
    if (counter > 1) setCounter(counter - 1)
  }
  let { preference = [] } = list
  let imagePreference = preference.includes('Non-Vegetarian')
    ? nonvegIcon
    : preference.includes('Vegetarian')
    ? vegIcon
    : ''
  if (counter <= 0) {
    decrementCounter = () => setCounter(1)
  }
  return (
    <div className=" bg-white-FF p-3 d-flex justify-content-start menu-cart content-media ">
      <div className="">
        <div className="foodimage">
          {/* <img src={list.dishImage} alt="" /> */}
          <img src={Shallots} alt="" />
          <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
            {/* {list.status} */}
          </div>
        </div>
      </div>
      <div className=" menucard_description ml-5">
        <div className="d-flex justify-content-between">
          <div className="d-flex ">
            <div className=" foodname fs-18 line-height-20 fw-700 text-primary-color ">
              {/* {list.name} */}
              Chicken Salad
            </div>
            <div className="nonVageIcon mb-1 line-height-20  ">
              <img src={nonvegIcon} alt="" srcset="" />
            </div>
          </div>

          <div className=" order_status text-primary-color fs-18 line-height-20 fw-700 d-flex">
            {/* ₹ {list.price}
             */}
            ₹230
          </div>
        </div>

        <p className="about-cart-min-height fs-14 text-grey-7c line-height-16  fw-400">
          {/* {list.about}  */}
          About dish Lorem ipsum dummy text lorem ipsum dummy text lorem ipsum
          dummy text more
        </p>
        <div className="d-flex justify-content-between flex-wrap type-count-container ">
          <div className="d-flex type-menu-container ">
            <span className="type_menu fs-14 mr-2   line-height-16 text-yellow-C1 bg-yellow-C2 ">
              Sugar Free
            </span>
            <span className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2 ">
              Egg
            </span>
          </div>
          <div className="d-flex ">
            <div className="count-contain-minus">
              <NormalButton
                label="-"
                className="count text-black-25 fs-20 bg-white-FF count-btn p-0"
                onClick={() => decrementCounter()}
              />
            </div>
            <div className="px-2">
              <NormalButton
                label={counter}
                primary
                className="count text-white-FF fs-14 bg-primary-color count-num-btn p-0"
              />
            </div>
            <div className="count-contain-plus">
              <NormalButton
                label="+"
                className="count text-black-25 fs-20 bg-white-FF count-btn p-0"
                onClick={() => incrementCounter()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuCard
