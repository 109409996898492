import { generateQuery, addQuery } from "service/helperFunctions";

//auth Api
export const authApi = {
  loginApi: {
    api: "auth/login",
    method: "post",
    baseURL: "auth",
  },
  signUpApi: {
    api: "user/sendOTPtoUser",
    method: "post",
    baseURL: "auth",
  },
  signUpChefApi: {
    api: "chef/sendOTPtoChef",
    method: "post",
    baseURL: "auth",
  },
  verifyUserApi: {
    api: "otp/verify",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordApi: {
    api: "auth/forgotPassword",
    method: "post",
    baseURL: "auth",
  },
  resetPasswordApi: {
    api: "auth/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  chefChangePasswordPostLogin: {
    api: "chef/changePassword",
    method: "post",
    baseURL: "auth",
  },
  userChangePasswordPostLogin: {
    api: "user/changePassword",
    method: "post",
    baseURL: "auth",
  },
  userSwitch: {
    api: "user/getUserByChefId",
    method: "post",
    baseURL: "auth",
  },
  resendOtpMobile: {
    api: "otp/resendMobileOtp",
    method: "put",
    baseURL: "auth",
  },
  resendOtpEmail: {
    api: "otp/resendEmailOtp",
    method: "put",
    baseURL: "auth",
  },
  getNotificationCount: {
    url: "getNotificationCount",
    method: "get",
    baseURL: "normal",
    chefId: null,
    get api() {
      return this.url + "/" + this.chefId;
    },
  },
  getNotificationList: {
    url: "getAllNotification",
    method: "get",
    baseURL: "normal",
    query: {
      page: 4,
      limit: 10,
    },
    chefId: null,
    get api() {
      return this.url + "/" + this.chefId + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateNotificationList: {
    api: "updateNotification",
    method: "put",
    baseURL: "auth",
  },
};

// file upload api
export const uploadsApi = {
  imageUpload: {
    api: "fileUpload/singleImage",
    method: "post",
    baseURL: "normal",
  },
  fileUpload: {
    api: "fileUpload/file",
    method: "post",
    baseURL: "normal",
  },
};
// customer apis
export const customerApi = {
  createCustomer: {
    api: "user/createprofile",
    method: "put",
    baseURL: "normal",
  },
  getCustomerById: {
    api: "user/getUserByUserId",
    method: "post",
    baseURL: "normal",
  },
};
// cart management apis
export const cartManageApi = {
  addToCart: {
    api: "cart/addToCart",
    method: "post",
    baseURL: "normal",
  },
  getAllCart: {
    api: "user/getAllCart",
    method: "get",
    baseURL: "normal",
  },
};
// chefdetailsApi
export const chefdetailsApi = {
  // getMenuApi: {
  //   api: 'user/viewMenuWithChef',
  //   method: 'post',
  //   baseURL: 'normal',
  // },
  getMenuApi: {
    url: "user/viewMenuWithChef",
    method: "post",
    baseURL: "normal",
    query: {
      page: 1,
      limit: 10,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getMenuWithChef: {
    api: "user/getone/ChefProfileByBrandUrl",
    method: "get",
    baseURL: "normal",
  },
  getCusineList: {
    api: "menu/cuisineList",
    method: "get",
    baseURL: "normal",
  },
  foodlabelList: {
    api: "menu/foodlabelList",
    method: "get",
    baseURL: "normal",
  },
  createDish: {
    api: "menu/createMenu",
    method: "post",
    baseURL: "normal",
  },
  updateDish: {
    api: "menu/updateMenu",
    method: "put",
    baseURL: "normal",
  },
  menuAvailability: {
    api: "menu/availability",
    method: "put",
    baseURL: "normal",
  },
  deleteDish: {
    api: "menu/deleteMenu",
    method: "delete",
    baseURL: "normal",
  },
  chefUpdate: {
    api: "chef/createChefProfile",
    method: "put",
    baseURL: "normal",
  },
  chefQuite: {
    api: "/chef/quietPortal",
    method: "post",
    baseURL: "normal",
  },
  deactivateChef: {
    api: "chef/deactivateChef",
    method: "put",
    baseURL: "normal",
  },
  deleteChef: {
    api: "chef/deleteChef",
    method: "delete",
    baseURL: "normal",
  },
  getByBrandName: {
    api: "chef/bySlug",
    method: "post",
    baseURL: "normal",
  },
  chefEditUpdate: {
    api: "chef/editChefProfile",
    method: "put",
    baseURL: "normal",
  },
  getSingle: {
    api: "chef/getone/Profile",
    method: "get",
    baseURL: "normal",
  },
  getPdf: {
    api: "download/file",
    method: "get",
    baseURL: "normal",
  },
  chefCompleted: {
    api: "chef/profileComplete",
    method: "put",
    baseURL: "normal",
  },

  // getChefDetailsApi: {
  //   api: "user/viewChefWithMenu",
  //   method: "get",
  //   baseURL: "normal",
  // },
  getChefDetailsApi: {
    url: "user/viewChefWithMenu",
    method: "get",
    baseURL: "normal",
    query: {
      country: "",
      state: "",
      city: "",
      area: "",
      page: 1,
      limit: 10,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  // getChefDetailsApi: {
  //   url: "user/viewChefWithMenu",
  //   method: "get",
  //   baseURL: "normal",
  //   query: {
  //     city: '',
  //   },
  //   chefId: null,
  //   get api() {
  //     return this.url + "/" + this.chefId + generateQuery(this.query);
  //   },
  //   set addQuery({ key, payload }) {
  //     this.query[key] = payload;
  //   },
  // },
  // contactUsApi: {
  //   api: 'user/chatWithAdmin',
  //   method: 'post',
  //   baseURL: 'normal',
  // },
  contactUsApi: {
    api: "user/contactUs",
    method: "post",
    baseURL: "normal",
  },
  filterCuisineApi: {
    url: "user/viewChefWithFilter",
    method: "post",
    baseURL: "normal",
    query: {
      page: 1,
      limit: 10,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

// customer apis
export const orderReviewApi = {
  reviewAddApi: {
    api: "user/addTestimonial",
    method: "post",
    baseURL: "normal",
  },
};
