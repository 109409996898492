import React, { useState } from 'react'
import { NormalButton } from 'component/common/NormalButton'
import { history } from 'service/helpers'

const SignUpOptions = ({ disabled = false }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <div className="sign-up-popup-select py-1 mb-3">
      <NormalButton
        viewButton
        label="Create New Account"
        className="w-100 fw-700 "
        onClick={toggle}
        disabled={disabled}
      />
      <ul className={`dropdown ${modal ? 'active' : ''}`}>
        <li
          className="d-flex align-items-center"
          onClick={() => history.push(`/index/home`)}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />
            <label className="form-check-label" for="flexRadioDefault1">
              Sign up as a Chef
            </label>
          </div>
        </li>
        <li
          className="d-flex align-items-center"
          onClick={() => history.push(`/auth/customeraccount`)}
        >
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />
            <label className="form-check-label" for="flexRadioDefault1">
              Sign up as a Customer
            </label>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SignUpOptions
