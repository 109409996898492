import React, { Component } from "react";
import "./footer.scss";
import { NormalButton } from "component/common/NormalButton";
import logo from "assets/svg/footerLogo.svg";
// import mobileLogo from 'assets/svg/logo.svg'
import mobileLogo from "assets/svg/mobilefooter.svg";
import scrollUp from "assets/images/footer-up-button.png";
import Instagram from "assets/images/Instagram.png";
import facebook from "assets/images/facebook.png";
export class Footer extends Component {
  handleActive = (path) => {
    let { history } = this.props;
    let { location } = history || {};
    let { pathname = "" } = location || {};
    if (path === pathname) {
      return "text-gold fw-700";
    } else {
      return "text-white-FF";
    }
  };
  scrollTop = () => {
    let element = document.getElementById("sub-content");
    console.log(element, "element");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  render() {
    let { history } = this.props;
    return (
      <div className="Footer bg-primary-color ">
        <div className="container">
          <div className="row pt-md-5 pt-3">
            <div className="col-12">
              <div className="row">
                {/* <div className="col-md-3 fs-16"> */}
                <div className="col-md-5 fs-16">
                  <div className="d-md-block d-none">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="d-md-none d-block">
                    <img src={mobileLogo} alt="logo" />
                  </div>
                </div>
                {/* <div className="col-12 col-md-3 footer-list mt-md-0 mt-3 px-md-5 d-flex flex-column align-items-md-end align-items-start justify-content-center"> */}
                <div className="col-12 col-md-4 footer-list mt-md-0 mt-3 px-md-5 d-flex flex-column align-items-md-end align-items-start justify-content-center">
                  <a
                    href="/index/faq"
                    className={`${this.handleActive(
                      "/index/faq"
                    )} text-decoration-none`}
                  >
                    FAQ
                  </a>
                  <a
                    href="/index/mission"
                    className={`${this.handleActive(
                      "/index/mission"
                    )} text-decoration-none`}
                  >
                    Mission
                  </a>
                  <a
                    href="/index/aboutUs"
                    className={`${this.handleActive(
                      '/index/aboutUs',
                    )} text-decoration-none`}
                  >
                    About Us
                  </a>
                  <a
                    href="/index/pp"
                    className={`${this.handleActive(
                      "/index/pp"
                    )} text-decoration-none`}
                  >
                    Privacy Policy
                  </a>
                  <a
                    href="/index/t&c"
                    className={`${this.handleActive(
                      "/index/t&c"
                    )} text-decoration-none`}
                  >
                    Terms & Conditions
                  </a>
                  {/* <a
                    href="/index/l&r"
                    className={`${this.handleActive(
                      "/index/l&r"
                    )} text-decoration-none mb-0`}
                  >
                    Licenses & Registrations
                  </a> */}
                </div>
                <div className="col-md-3 col-12 mt-md-0 mt-3 ps-md-5 pt-0 border-left d-flex flex-column justify-content-center">
                  {/* <p className="mb-0 fs-16 fw-700 text-white ">
                    Get in touch with us at{' '}
                  </p>
                  <p className="text-underline mt-0 fw-500 cursor-pointer mb-0">
                    <a
                      href="mailto:support@thecheflane.com"
                      className="text-gold "
                    >
                      support@thecheflane.com
                    </a>
                  </p> */}
                  <div className="d-flex justify-content-start mt-3 mb-3">
                    <NormalButton
                      secondary
                      label="Contact Us"
                      className="text-primary-color bg-white rounded fw-700  px-4"
                      onClick={() => history.push("/index/contactus")}
                    />
                  </div>
                  <div className="">
                    <span>
                      <a
                        href="https://www.facebook.com/thecheflane"
                        target="_blank"
                      >
                        <img src={facebook} alt="icons" />
                      </a>
                    </span>
                    <span className="mx-2">
                      <a
                        href="https://www.instagram.com/the.chef.lane/"
                        target="_blank"
                      >
                        <img src={Instagram} alt="icons" />
                      </a>
                    </span>
                  </div>
                  {/* <p className="text-gold text-underline mt-3 fw-500 fs-18 cursor-pointer">
                    FFSAI certificate
                  </p> */}
                </div>
                {/* <div className="col-md-3 col-12 mt-md-0 mt-3 ps-md-5 pt-0 border-left d-flex flex-column justify-content-center">
                  <p className="mb-0 fs-16 fw-700 text-white ">
                    Chef Lane Online Services Private Limited{' '}
                  </p>

                  <div className="d-flex justify-content-start mt-2 mb-3"></div>
                  <p className="text-white">
                    SP7A Guindy Industrial Estate
                    <br />
                    Guindy
                    <br />
                    Chennai - 600032
                  </p>
                </div> */}
                <div className="col-lg-12 mt-md-3 order-sm-5 order-5 position-relative">
                  <div className="line my-3"></div>
                  <p className="mt-2 fs-16 fw-400 text-grey-ff copyrights">
                    Copyright &#169; 2022 Chef Lane Online Services Private Limited. All rights reserved.
                  </p>
                  <div
                    className="position-absolute scroll-to-top"
                    onClick={() => this.scrollTop()}
                  >
                    <img alt="scrollUp" src={scrollUp} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
