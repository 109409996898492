import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./style.scss";
import { NormalButton } from "component/common/NormalButton";
import { CommonPageLoader } from "component/common/CommonPageLoader";
import OrderDeliver  from "./OrderDeliver";
import ReviewOrderRate  from "./ReviewOrderRate";

import orderplace from "assets/images/orderplace.png";


const TrackOrderClass = ({ menuListApiCall }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderDeliver, setIsOrder] = useState(false);
  

  return (
    <div className="trackorder-container position-relative mt-3 mb-5">
      <div className="container">
        {/* <div className="p-4 container  "> */}
        <div className="pt-4">
          {isLoading ? (
            <CommonPageLoader />
          ) : (
            <>
              <div className="menucard_description ml-5">
                <div className="d-flex">
                  <div className="bitesheading col-lg-12  col-md-12 col-10   line-height-28 fs-24 fw-700 text-primary-color">
                    Track Order
                    {/* <span className="bitesheadingbracket line-height-20 fs-18 fw-700 text-primary-color">
                      ()
                    </span> */}
                  </div>

                  <div className="button_container d-flex justify-content-end ">
                    <NormalButton
                      label="Help"
                      className="applyNow bg-yellow-C1 text-white-FF line-height-18 fs-16 "
                      primary
                    />
                  </div>
                </div>
                <div className="Order-track mt-3">
                  <p className="fs-16 fw-400 text-grey-4f mb-2">
                    Your Order has been placed successfully with Chef lane
                    <br /> Please find the below order number XXXXXX and it is
                    estimated to be delivered in 20mins
                  </p>
                </div>
                <div className="order-items">
                  <p className="fs-16 fw-400 text-grey-4f">
                    Order Items - Cream of tartar X 2 | Dish name X 1 | Dish
                    name X 2
                  </p>
                </div>
              </div>
              {isOrderDeliver ? (
                <OrderDeliver/>
              ) : (
                <>
                  <ReviewOrderRate/>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};



export const TrackOrderComp = connect(
  null,
)(TrackOrderClass);
