import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { MissionComp } from "component/Mission";
export class MissionClass extends Component {
  componentDidMount() {
    let element = document.getElementById("mission");

    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <link
            rel="canonical"
            href="https://www.thecheflane.com/index/mission"
          />
          <title>
            Mission | Homemade food that suits your mood | For Home Chef | The
            Chef Lane
          </title>
          <meta
            property="og:title"
            content="Mission | Homemade food that suits your mood | For Home Chef | The Chef Lane"
          />
          <meta
            name="description"
            content="The Chef Lane: We have a single mission that is, to enhance the lives and businesses of home chefs. The chef Lane is the new revolution of the existing kitchen businesses by promoting the home cooked food futher."
          />
          <meta
            name="keywords"
            content="homemade cake, homemade chocolate, homemade pizza, home made chocolates, home made cake, homemade food, homemade cakes near me, homemade snacks, homemade chocolate cake, home made food, homemade birthday cake, homemade pickles, homemade sweets, home cooked, home chef, chef cooked, homebakers near me, good baker, cooked dinner, cooked dishes, home cooked food delivered, home made food online, cooked food list, home cooked meals, home made food near me, home cooked meals delivered, authentic food, homebakers, home bread bakery, hand crafted chocolate, handcrafted cake, handmade cake, handmade homemade chocolate, handmade biscuits, handmade cookies"
          />
        </Helmet>
        <div id="mission">
          <MissionComp />
        </div>
      </>
    );
  }
}
export const Mission = connect(null, null)(MissionClass);
