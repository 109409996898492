import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { FB } from "assets/svg/facebook.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import { fileUpload, imageUpload } from "action/FileUpload";
import plus from "../../../assets/images/plus.png";
import shareYellow from "../../../assets/images/shareYellow.png";
import ChefPdf from "component/common/ChefPdf";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { renderToString } from "react-dom/server";
import { convertToSlug, handleNameField } from "service/helperFunctions";
import { history } from "service/helpers";
import { Toast } from "service/toast";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import validate from "service/validation";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
import {
  NormalButton,
  NormalToggleSwitch,
  NormalCustomCheckbox,
  NormalDate,
  NormalRadio,
  CommonPageLoader,
  ImageCropComponent,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  chefEditUpdate,
  getSingle,
  deleteChef,
  getCusineList,
  getChefPdf,
} from "action/ChefDetailsAct";
import facebook from "assets/images/facebook.png";
import whatsapp from "assets/images/whatsapp.png";
import Instagram from "assets/images/Instagram.png";
import closeGray from "assets/images/closeGray.png";
import AddressDetails from "../AddressDetail";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import copyIcon from "assets/images/copyIcon.png";
import moment from "moment";
import { FRONT_END_PORT } from "utils/constants";
import { logout } from "service/utilities";
import { useReactToPrint } from "react-to-print";
const regExp = /[a-zA-Z]/g;

const initalState = {
  _id: localStorage.getItem("userId"),
  firstName: "",
  lastName: "",
  businessName: "",
  mobileNumber: "",
  whatsappNumber: "",
  sameAsMobileNumber: false,
  deliveryPartners: "OWN",
  email: "",
  chefImage: "",
  availabilityStatus: false,
  licenseExpiryDate: "",
  AboutDetails: {
    About: "",
    adminApproved: false,
    isEdit: false,
  },
  cuisine: [],
  LicenseDetails: {
    licenseNumber: "",
    licenseExpiryDate: new Date(),
    licenseFileUrl: "",
    adminApproved: false,
    isEdit: false,
  },
  // socialMediaLinks: {
  //   facebook: "",
  //   instagram: "",
  //   adminApproved: false,
  //   isEdit: false,
  // },
  facebook: {
    facebookLink: "",
    adminApproved: false,
    isEdit: false,
  },
  instagram: {
    instagramLink: "",
    adminApproved: false,
    isEdit: false,
  },
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    area: "",
    pincode: "",
    location: {
      lat: "",
      long: "",
    },
  },
};
function BasicDetail({
  chefEditUpdate,
  getCusineList,
  getSingle,
  active,
  fileUpload,
  imageUpload,
  deleteChef,
  dataFromParent,
  fetchChefDetailsParent,
  getChefPdf,
}) {
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [profileImgURL, setProfileImgURL] = useState();
  const [formData, setFormData] = useState(initalState);
  const [renderCuisnineList, setrenderCusinieList] = useState([]);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [cuisineList, setcuisineList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const cuisineList = ["North-Indian", "South-Indian", "Chinese"];
  const reference = useRef();
  const referenceFile = useRef();
  const [selectedImage, setSelectedImage] = useState();
  const [croppingImage, setCroppingImage] = useState();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [rawFile, setRawFile] = useState({});
  const [isuploading, setIsuploading] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [printLoader, setPrintLoader] = useState(false);
  const [printModel, setPrintModel] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isPendingItems, setIsPendingItems] = useState(false);
  const [error, setErrors] = useState({});
  const [errorFacebook, setErrorFacebook] = useState(true);
  const [errorInstagram, setErrorInstagram] = useState(true);

  const [pdfContent, setPdfContent] = useState();
  const wrapperRef = useRef(null);
  const componentRef = useRef();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleClose = () => {
    setSearch("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (active) {
      setIsEdit(false);
    }

    if (Object.keys(dataFromParent).length) {
      fetchChefDetails();
      fetchCusineList();
    }
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [active]);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
      setIsCopy(false);
    }
  };
  const fetchChefDetails = async () => {
    let newForm = Object.assign(formData, dataFromParent);
    // setFormData({ ...newForm })
    setFormData((prevState) => ({
      ...prevState,
      ...newForm,
    }));
    setSelectedCuisineList(newForm.cuisine ? newForm.cuisine : []);
  };
  const handleDownloadPdf = async (id) => {
    setIsDownload(true);
    await getChefPdf(id)
      .then((data) => {
        if (data.link) {
          var file_path = data.link;
          var a = document.createElement("A");
          a.href = file_path;
          a.target = "_blank";
          a.download = file_path.substr(file_path);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        setIsDownload(false);
      })
      .catch(() => {
        setIsDownload(false);
      });
  };
  const fetchCusineList = async () => {
    let id = localStorage.getItem("userId");
    setIsLoading(true);
    await getCusineList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch((e) => setIsLoading(false));
  };
  // const handleImageUpload = () => {
  //   setShowImageCrop(true);
  // };
  const handleBeforeImageCropping = (event) => {
    setCroppingImage(event.target.files[0]);
    setShowImageCrop(true);
  };
  const handleFileUpload = async (event, name = "chefImage") => {
    let keySet = name === "chefImage" ? setIsuploading : setIsDocumentuploading;
    var data = new FormData();
    let file = {};
    file = name === "chefImage" ? event : event.target.files[0];

    // if (name === 'chefImage') {
    // } else {
    //   file = event;
    //   console.log(event.file)
    // }
    setSelectedImage(file);
    let api = name === "chefImage" ? imageUpload : fileUpload;

    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      if (name === "chefImage")
        Toast({
          type: "error",
          message: "Please upload image file that is 5MB or less",
          time: 2000,
        });
      else
        Toast({
          type: "error",
          message: "Please upload document file that is 5MB or less",
          time: 2000,
        });
    } else {
      keySet(true);

      await api(data)
        .then((data) => {
          if (name === "chefImage") {
            setFormData((prevState) => ({
              ...prevState,
              [name]: data?.data?.url,
            }));
          } else {
            let { LicenseDetails } = { ...formData };
            LicenseDetails.licenseFileUrl = data?.data?.url;
            LicenseDetails.isEdit = true;
            LicenseDetails.adminApproved = null;
            setFormData((prevState) => ({
              ...prevState,
              LicenseDetails,
              isEdit: true,
              status: "PENDING",
            }));
          }
          keySet(false);
        })
        .catch(() => {
          keySet(false);
        });
    }
  };

  const mapCusine = () => {
    setShow(false);
    setrenderCusinieList(selectedCuisineList);
  };

  const handleSelectedCuisine = (e) => {
    console.log(e?.target?.checked);

    if (e?.target?.value) {
      let value = e?.target?.name;
      setSelectedCuisineList([...selectedCuisineList, value]);
      console.log(selectedCuisineList);
    } else {
      setSelectedCuisineList(
        selectedCuisineList.filter((item) => item !== e.target.name)
      );
      console.log(selectedCuisineList);
    }
    // let { cuisineDetails } = { ...formData }
    // cuisineDetails.isEdit = true
    // setFormData({ ...formData, cuisineDetails })
  };

  const submitBasicDetails = async () => {
    let flag =
      formData.countryCode !== "+91"
        ? validator.current.allValid()
        : validator.current.allValid() && validatorLicence.current.allValid();

    let socialMediaFlag = true;
    let socialMediaFlagInsta = true;
    if (
      formData.facebook.facebookLink !== "" &&
      formData.facebook.facebookLink !== undefined
    ) {
      setErrorFacebook(isUrlValid(formData.facebook.facebookLink));
      socialMediaFlag = isUrlValid(formData.facebook.facebookLink);
    }
    if (
      formData.instagram.instagramLink !== "" &&
      formData.instagram.instagramLink !== undefined
    ) {
      setErrorInstagram(isUrlValid(formData.instagram.instagramLink));
      socialMediaFlagInsta = isUrlValid(formData.instagram.instagramLink);
    }

    if (flag && socialMediaFlag && socialMediaFlagInsta) {
      setIsLoading(true);
      // let { cuisineDetails } = { ...formData }
      formData.cuisine = selectedCuisineList;
      formData["masterChefId"] = localStorage.getItem("userId");
      formData["isNewRequest"] = false;
      formData["firstName"] = handleNameField(formData.firstName);
      formData["lastName"] = handleNameField(formData.lastName);
      delete formData["_id"];
      delete formData["__v"];
      // setFormData({ ...formData, })
      console.log(formData, "basicDetails");
      let isAlert = formData.isEdit;
      await chefEditUpdate(formData)
        .then(() => {
          if (isAlert && !formData?.autoApprove) {
            setIsPendingItems(true);
          } else {
            setIsLoading(false);
            setIsEdit(false);
            scrollTop();
            fetchChefDetailsParent();
          }
          // setIsEditAdmin(false)
          // handleFormChange("addressDetails");
        })
        .catch((e) => setIsLoading(false));
    } else {
      if (formData.countryCode === "+91") {
        validatorLicence.current.showMessages();
        scrollTop("FoodLicense");
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please fill up all the required fields",
        time: 3000,
      });
      if (formData.countryCode === "+91" && validatorLicence.current.allValid())
        scrollTop();
      if (formData.countryCode !== "+91") scrollTop();
    }
  };

  const handleSearch = (e) => {
    let {
      target: { value },
    } = e;
    setSearch(value);
  };
  const handleCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
    setIsCopy(false);
  };

  const printPdf = () => {
    // setPrintLoader(true)
    // window.html2canvas = html2canvas
    // const string = renderToString(<ChefPdf />)
    html2canvas(pdfContent)
      .then((canvas) => {
        const dataURL = canvas.toDataURL();
        const pdf = new jsPDF();

        pdf.addImage(dataURL, "JPEG", 0, 0, 0, 0);

        pdf.save("saved.pdf");
      })
      .then(() => {
        setPrintLoader(false);
      });
  };
  const scrollTop = (key = "custom-profile") => {
    let element = document.getElementById(key);
    console.log(element, "element");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };
  const handleChange = (e) => {
    let {
      target: { name, value },
    } = e;

    if (name === "countryCode") {
      if (formData.sameAsMobileNumber) {
        setFormData((prevState) => ({
          ...prevState,
          sameAsMobileNumber: e.target.value,
          whatsappNumber: formData.mobileNumber,
          whatsappCountryCode: value,
        }));
      }
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        mobileNumber: "",
        //whatsappNumber: "",
      }));
    }

    if (name === "whatsappCountryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        //whatsappNumber: formData.mobileNumber,
        whatsappNumber: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (formData.sameAsMobileNumber && name === "mobileNumber") {
      setFormData((prevState) => ({
        ...prevState,
        whatsappNumber: value,
      }));
    }
  };
  return (
    <>
      {active === "address" ? (
        <AddressDetails formData={formData} setFormData={setFormData} />
      ) : (
        <div id="custom-profile">
          <div className="basic_detail_inner_container">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="header">Basic Details</div>
                {!isEdit && (
                  <NormalButton
                    primary
                    label="Edit"
                    className="fw-700"
                    disabled={isLoading}
                    onClick={() => setIsEdit(true)}
                  />
                )}
              </div>
              <hr></hr>
            </div>
            <div className="profile_pic_container d-flex justify-content-between">
              <div className="d-flex profil-contain">
                <div className="profile_pic flex-column">
                  <div className="box">
                    {isuploading ? (
                      <CommonPageLoader />
                    ) : (
                      <>
                        {
                          !!formData.chefImage ? (
                            <img src={formData.chefImage} alt="Thumb" />
                          ) : (
                            <span className="d-flex">
                              <img src={userUpload} alt="image" />
                            </span>
                          )
                          // <>
                          //   {selectedImage && (
                          //     <img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                          //   )}
                          // </>
                        }
                      </>
                    )}
                  </div>
                  {isEdit && (
                    <div className="upload_btn">
                      <input
                        ref={reference}
                        onChange={(e) => {
                          e.target.files[0] &&
                            handleBeforeImageCropping(e, "chefImage");
                        }}
                        type="file"
                        style={{ display: "none" }}
                        // multiple={false}
                        accept="image/*"
                      />

                      <NormalButton
                        label=" Upload Image"
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={() => {
                          reference.current.click();
                          //handleImageUpload();
                        }}
                        disabled={isuploading}
                      />
                      {isEdit && (
                        <p className="fs-12 alert-for-upload-profile mb-0 mt-2">
                          <sup className="text-danger fs-14">*</sup>
                          Please upload image file that is 5MB or less
                        </p>
                      )}
                      {validator.current.message(
                        "Image",
                        formData.chefImage,
                        "required"
                      )}
                    </div>
                  )}
                </div>
                <div className="availability mb-3">
                  <div>
                    <Form.Label className="mb-3">Availability </Form.Label>
                    <div className="d-flex align-items-center">
                      <NormalToggleSwitch
                        name="availability "
                        onChange={(e) =>
                          setFormData((prevState) => ({
                            ...prevState,
                            availabilityStatus: e.target.checked,
                          }))
                        }
                        checked={formData.availabilityStatus}
                        disabled={!isEdit}
                      />
                      <div className="check-value">
                        {formData.availabilityStatus ? "YES" : "NO"}
                      </div>
                    </div>
                  </div>
                  <div className="share-buttons">
                    {!isEdit && (
                      <div ref={wrapperRef} className="position-relative">
                        <span>
                          <NormalButton
                            label="Share"
                            className="w-100 fw-500 outline-gray-btn"
                            onClick={() => {
                              setShowShare(!showShare);
                              setIsCopy(false);
                            }}
                            prefix={shareYellow}
                          />
                        </span>
                        {showShare && (
                          <div className="share-position">
                            <NormalButton
                              disabled={isDownload}
                              label="as .pdf"
                              className="w-100 fw-500 outline-gray-btn"
                              onClick={() => {
                                console.log("object");
                                // handlePrint()
                                handleDownloadPdf(formData._id);
                              }}
                              // prefix={shareYellow}
                            />
                            <div className="">
                              <NormalButton
                                label="Copy link"
                                className="w-100 fw-500 outline-gray-btn"
                                onClick={() => {
                                  handleCopyText(
                                    `${FRONT_END_PORT}${convertToSlug(
                                      formData.businessNameSlug
                                    )}`
                                  );
                                  setShowShare(false);
                                }}
                                // prefix={shareYellow}
                              />
                              {isCopy && (
                                <div className="position-copy">
                                  <p className="mb-0 text-gold">
                                    doodlebluelive.com:2013/
                                    {formData.businessName}
                                  </p>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleCopyText(
                                        `${FRONT_END_PORT}${convertToSlug(
                                          formData.businessNameSlug
                                        )}`
                                      );
                                    }}
                                  >
                                    <img src={copyIcon} alt="icon" />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {!isEdit && (
                  <div className="mt-4">
                    <span>
                      <NormalButton
                        label="Delete Profile "
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={() => setShowDeactivate(true)}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="body">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>First Name{isEdit && <sup>*</sup>}</Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter First Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          firstName: value,
                        }));
                      }}
                      value={formData.firstName}
                    />
                    {validator.current.message(
                      "First Name",
                      formData.firstName,
                      "required|min:3|max:20"
                    )}
                  </Form.Group>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>Last Name{isEdit && <sup>*</sup>}</Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter Last Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          lastName: value,
                        }));
                      }}
                      value={formData.lastName}
                    />
                    {validator.current.message(
                      "Last Name",
                      formData.lastName,
                      "required|max:20"
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Business Name{isEdit && <sup>*</sup>}
                    </Form.Label>
                    <Form.Control
                      className={!isEdit ? "remove-height" : ""}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="text"
                      autoComplete="off"
                      placeholder={`${isEdit ? "Enter Brand Name" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          businessName: value,
                        }));
                      }}
                      value={formData.businessName}
                    />
                    {validator.current.message(
                      "Business Name",
                      formData.businessName,
                      "required|min:3|max:50"
                    )}
                  </Form.Group>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>
                      Mobile Number{isEdit && <sup>*</sup>}
                    </Form.Label>
                    <div
                      className={
                        isEdit
                          ? "d-flex country-mobile "
                          : "d-flex country-mobile hide-box"
                      }
                    >
                      <div className="country-code-dropdown">
                        <CountryCodeDropDown
                          value={
                            formData.countryCode === undefined ||
                            formData.countryCode === ""
                              ? "+91"
                              : formData.countryCode
                          }
                          readOnly={isEdit ? false : true}
                          plaintext={isEdit ? false : true}
                          disabled={true}
                        />
                      </div>
                      <Form.Control
                        className={!isEdit ? "remove-height" : ""}
                        disabled={true}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        autoComplete="off"
                        placeholder={`${isEdit ? "Enter Mobile Number" : ""}`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          if (!regExp.test(value)) {
                            setFormData((prevState) => ({
                              ...prevState,
                              mobileNumber: value,
                            }));
                          }
                        }}
                        value={formData.mobileNumber}
                      />
                      {validator.current.message(
                        "Mobile Number",
                        formData.mobileNumber,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>Email ID{isEdit && <sup>*</sup>}</Form.Label>
                    <a
                      className="email text-grey-7c text-underline"
                      href={`mailto:${formData.email}`}
                    >
                      <Form.Control
                        className={
                          !isEdit ? "remove-height cursor-pointer" : ""
                        }
                        disabled={true}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="email"
                        placeholder={`${isEdit ? "Enter Email ID" : ""}`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          setFormData((prevState) => ({
                            ...prevState,
                            email: value,
                          }));
                        }}
                        value={formData.email}
                      />
                    </a>
                    {validator.current.message(
                      "Email",
                      formData.email,
                      "required|email"
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Form.Group>
                    <Form.Label>Whatsapp Number</Form.Label>
                    <div
                      className={
                        isEdit
                          ? "d-flex country-mobile "
                          : "d-flex country-mobile hide-box"
                      }
                    >
                      {!isEdit && (
                        <p className="d-flex fs-16 mb-0 align-items-center fw-600 me-1">
                          {" "}
                          {formData.whatsappCountryCode}
                        </p>
                      )}
                      {isEdit && (
                        <div className="country-code-dropdown">
                          {formData.whatsappNumber === "" && !isEdit ? (
                            ""
                          ) : (
                            <CountryCodeDropDown
                              onClick={(e) => {
                                handleChange({
                                  target: {
                                    name: "whatsappCountryCode",
                                    value: e,
                                  },
                                });
                              }}
                              value={
                                formData?.whatsappCountryCode
                                  ? formData?.whatsappCountryCode
                                  : "+91"
                              }
                              disabled={formData.sameAsMobileNumber}
                            />
                          )}
                        </div>
                      )}
                      <Form.Control
                        className={!isEdit ? "remove-height" : ""}
                        disabled={formData.sameAsMobileNumber}
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="number"
                        autoComplete="nope"
                        placeholder={`${isEdit ? "Enter Number" : ""}`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          if (!regExp.test(value)) {
                            setFormData((prevState) => ({
                              ...prevState,
                              whatsappNumber: value,
                            }));
                          }
                        }}
                        value={formData.whatsappNumber}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    </div>
                    {/* {validator.current.message(
                      'Whatsapp',
                      formData.whatsappNumber,
                      'required',
                    )} */}
                  </Form.Group>
                </div>
                {isEdit && (
                  <div className="col-sm-12 col-md-12 col-lg-4 d-flex align-items-center">
                    <Form.Group className="mb-0">
                      {/* <Form.Label>Email ID</Form.Label> */}
                      <NormalCustomCheckbox
                        name="sameAsMobileNumber"
                        checked={formData.sameAsMobileNumber}
                        value={formData.sameAsMobileNumber}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          if (value) {
                            setFormData((prevState) => ({
                              ...prevState,
                              sameAsMobileNumber: e.target.value,
                              whatsappNumber: formData.mobileNumber,
                            }));
                          } else {
                            setFormData((prevState) => ({
                              ...prevState,
                              sameAsMobileNumber: e.target.value,
                              whatsappNumber: "",
                            }));
                          }
                          // setFormData({ ...formData, sameAsMobileNumber: e.target.checked });
                        }}
                        label={"Same as mobile number"}
                      />
                      {/* {validator.current.message('Whatsapp', formData.whatsappNumber, 'required')} */}
                    </Form.Group>
                  </div>
                )}
              </div>

              <div className="cusine-blocks">
                <Form.Label className="mb-0">
                  Cuisine{isEdit && <sup>*</sup>}
                </Form.Label>
                {/* Cuisine<br></br> */}
                {isEdit ? (
                  <div className="cuisine_list">
                    <span className="button-width">
                      <NormalButton
                        label="Add Cuisine"
                        className="w-100 fw-700 outline-gray-btn"
                        onClick={handleShow}
                        prefix={plus}
                      />
                      {validator.current.message(
                        "Cuisine",
                        selectedCuisineList,
                        "required"
                      )}
                    </span>
                    {/* <Button variant="outline-dark" onClick={handleShow}>
                  + &ensp;Add Cuisine
                </Button> */}
                    {selectedCuisineList?.map((item) => (
                      <div className="cusine_item">
                        {item}&ensp;
                        <b
                          className="rmv_cuisine_item"
                          onClick={() => {
                            setSelectedCuisineList(
                              selectedCuisineList.filter(
                                (items) => items !== item
                              )
                            );
                            setrenderCusinieList(selectedCuisineList);
                          }}
                        >
                          &#10006;
                        </b>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="mt-0">
                    {selectedCuisineList?.map((item, index) => (
                      <span className="fw-700 text-black-25" key={index}>
                        {(index ? ", " : "") + item}
                      </span>
                    ))}
                  </div>
                )}
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop={true}
                  keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title>
                      <b>Select Cuisine</b>
                    </Modal.Title>
                    <span class="cursor-pointer" onClick={handleClose}>
                      <img src={closeBtn} alt="icon" />
                    </span>
                  </Modal.Header>
                  {/* <hr></hr> */}
                  <Modal.Body>
                    <div className="model_body">
                      <div className="search-outer">
                        <div class="input-group  search-input-group">
                          <input
                            type="text"
                            id="searchValue"
                            name="searchValue"
                            value={search}
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                            className="form-control border-right-0 searchInput"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                          />
                          <div class="input-group-append ">
                            <span
                              class="input-group-text bg-white border-left-0 cursor-pointer"
                              onClick={() => {
                                setSearch("");
                              }}
                            >
                              <img
                                className="mailIcon"
                                src={closeGray}
                                alt="search"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="list-body">
                        <div className="row">
                          {cuisineList
                            .filter((item) =>
                              search === ""
                                ? item
                                : String(item)
                                    .toLowerCase()
                                    .match(search.toLowerCase())
                            )
                            .map((item) => {
                              return (
                                <div className="col-6 mb-2">
                                  <NormalCustomCheckbox
                                    name={item}
                                    checked={selectedCuisineList.includes(item)}
                                    value={selectedCuisineList.includes(item)}
                                    onChange={(e) => {
                                      handleSelectedCuisine(e);
                                    }}
                                    label={item}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="pt-0">
                    <div className="d-flex justify-content-start w-100">
                      <NormalButton
                        label="Discard"
                        className="white-btn mr-3"
                        onClick={() => {
                          setSelectedCuisineList([]);
                          setShow(false);
                          setSearch("");
                        }}
                      />
                      <span className="m-2" />

                      <NormalButton
                        primary
                        label="Save"
                        className="fw-700"
                        disabled={isLoading}
                        onClick={() => {
                          mapCusine();
                          setSearch("");
                        }}
                      />
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm-12 col-md-11">
                <Form.Group>
                  <Form.Label className="mb-0">
                    Delivery Partners{isEdit && <sup>*</sup>}
                  </Form.Label>
                  {isEdit ? (
                    <div className="d-flex flex-wrap">
                      <span className="mx-2">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label={"Own delivery partners"}
                          name="OWN"
                          value={formData.deliveryPartners === "OWN"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              deliveryPartners: e.target.name,
                            }));
                          }}
                          checked={formData.deliveryPartners === "OWN"}
                          disabled={false}
                        />
                      </span>
                      <span className="mx-4">
                        <NormalRadio
                          disabled={true}
                          className=" border-0 rounded-0"
                          label={"Platform delivery partners (Coming soon)"}
                          name="PLATFORM"
                          value={formData.deliveryPartners === "PLATFORM"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              deliveryPartners: e.target.name,
                            }));
                            setShowPlatformAlert(true);
                          }}
                          checked={formData.deliveryPartners === "PLATFORM"}
                        />
                      </span>
                    </div>
                  ) : (
                    <p className="fw-700 text-black-25">
                      {formData.deliveryPartners === "OWN"
                        ? "Own delivery partners"
                        : "Use platform delivery partners"}
                    </p>
                  )}

                  {validator.current.message(
                    "Delivery Partners",
                    formData.deliveryPartners,
                    "required"
                  )}
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="basic_detail_inner_container mt-4">
            <div className="header">
              <div>About me</div>
              <hr></hr>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <div className="header">About </div>
              <hr></hr>

              <NormalButton
                  primary
                  label="Edit"
                  className="fw-700"
                  disabled={isLoading}
                  onClick={() => setIsEditAdmin(true)}
                />
            </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-10">
                <Form.Group>
                  <Form.Label className="mb-0">
                    About me{isEdit && <sup>*</sup>}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height w-100" : "w-100"}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${
                      isEdit ? "About you in 500 characters or less" : ""
                    }`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { AboutDetails } = { ...formData };
                      AboutDetails.About = value;
                      AboutDetails.isEdit = true;
                      AboutDetails.adminApproved = null;
                      setFormData((prevState) => ({
                        ...prevState,
                        AboutDetails,
                        isEdit: true,
                        status: "PENDING",
                      }));
                    }}
                    value={formData.AboutDetails.About}
                  />
                  {validator.current.message(
                    "About me",
                    formData.AboutDetails.About,
                    "required|min:10|max:500"
                  )}
                </Form.Group>
              </div>
            </div>

            <br></br>
            <div className="header" id="FoodLicense">
              <div>Food License</div>
              <hr></hr>
            </div>

            {/* <div className="body"> */}
            <div className="row">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <Form.Group>
                  <Form.Label className="mb-0">
                    License Number
                    {/* {isEdit && formData.countryCode === "+91" && <sup>*</sup>} */}
                  </Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    placeholder={`${isEdit ? "Enter License Number" : "-"}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { LicenseDetails } = { ...formData };
                      LicenseDetails.licenseNumber = value;
                      LicenseDetails.isEdit = true;
                      LicenseDetails.adminApproved = null;
                      setFormData((prevState) => ({
                        ...prevState,
                        LicenseDetails,
                        isEdit: true,
                        status: "PENDING",
                      }));
                    }}
                    value={formData.LicenseDetails.licenseNumber}
                  />
                  {/* {validatorLicence.current.message(
                    "License Number",
                    formData.LicenseDetails.licenseNumber,
                    "required"
                  )} */}
                </Form.Group>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <div className="custom-file-upload">
                  <Form.Group>
                    <Form.Label className="mb-0">
                      License File
                      {/* {isEdit && formData.countryCode === "+91" && <sup>*</sup>} */}
                    </Form.Label>
                    {isEdit ? (
                      <div className="position-relative">
                        <Form.Control
                          className={!isEdit ? "remove-height" : ""}
                          disabled={isDocumentuploading}
                          type="file"
                          ref={referenceFile}
                          placeholder="Enter..."
                          className="custom-edited-upload"
                          accept="application/pdf,image/*"
                          onChange={(e) => {
                            e.target.files[0] &&
                              handleFileUpload(e, "licenseFile");
                            // if (e.currentTarget.files[0]) {

                            //   setRawFile(e.currentTarget.files[0])
                            //   setSelectedImage(e.currentTarget.files[0])
                            // }
                          }}
                        />
                        <p className="fileNAme text-black-25">
                          {isDocumentuploading ? (
                            "loading..."
                          ) : (
                            <>
                              {formData.LicenseDetails.licenseFileUrl
                                ? formData.LicenseDetails.licenseFileUrl
                                    .split("/")
                                    .pop()
                                    .split("--")
                                    .pop()
                                : ""}
                            </>
                          )}
                        </p>
                        {/* <p className='fileNAme'>{formData.LicenseFileDetails.licenseFileUrl}</p> */}
                        <NormalButton
                          label="Upload"
                          className="fw-700 fs-14 file-btn"
                          disabled={isDocumentuploading}
                          onClick={() => referenceFile.current.click()}
                        />
                        {isEdit && (
                          <p className="fs-12 alert-for-upload mb-0">
                            {/* {isEdit && formData.countryCode === "+91" && (
                              <sup className="text-danger fs-14">*</sup>
                            )} */}
                            Please upload file that is 5MB or less
                          </p>
                        )}
                        {/* {validatorLicence.current.message(
                          "License File",
                          formData.LicenseDetails.licenseFileUrl,
                          "required"
                        )} */}
                      </div>
                    ) : (
                      <p className="fw-700">
                        {formData.LicenseDetails.licenseFileUrl
                          ? formData.LicenseDetails.licenseFileUrl
                              .split("/")
                              .pop()
                              .split("--")
                              .pop()
                          : "-"}
                      </p>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-5 col-lg-4">
                <Form.Group>
                  <Form.Label className="mb-0">
                    License Expiry Date
                    {/* {isEdit && formData.countryCode === "+91" && <sup>*</sup>} */}
                  </Form.Label>
                  {formData.LicenseDetails.licenseExpiryDate !== "" ? (
                    <NormalDate
                      isReadOnly={!isEdit}
                      placeholder="Enter Date"
                      name="licenseExpiryDate"
                      showYearDropdown={true}
                      value={
                        formData.LicenseDetails.licenseExpiryDate &&
                        new Date(formData.LicenseDetails.licenseExpiryDate)
                      }
                      isIcon={false}
                      minDate={new Date(moment().add(10, "days"))}
                      className="form-control mini-date"
                      onChange={(e) => {
                        let { LicenseDetails } = { ...formData };
                        LicenseDetails.licenseExpiryDate = e.target.value;
                        LicenseDetails.isEdit = true;
                        LicenseDetails.adminApproved = null;

                        setFormData((prevState) => ({
                          ...prevState,
                          LicenseDetails,
                          isEdit: true,
                          status: "PENDING",
                        }));
                      }}
                    />
                  ) : (
                    <>
                      {isEdit ? (
                        <NormalDate
                          isReadOnly={!isEdit}
                          placeholder="Enter Date"
                          name="licenseExpiryDate"
                          showYearDropdown={true}
                          value={
                            formData.LicenseDetails.licenseExpiryDate &&
                            new Date(formData.LicenseDetails.licenseExpiryDate)
                          }
                          isIcon={false}
                          minDate={new Date(moment().add(10, "days"))}
                          className="form-control mini-date"
                          onChange={(e) => {
                            let { LicenseDetails } = { ...formData };
                            LicenseDetails.licenseExpiryDate = e.target.value;
                            LicenseDetails.isEdit = true;
                            LicenseDetails.adminApproved = null;

                            setFormData((prevState) => ({
                              ...prevState,
                              LicenseDetails,
                              isEdit: true,
                              status: "PENDING",
                            }));
                          }}
                        />
                      ) : (
                        <p className="fw-700 text-black-25">-</p>
                      )}
                    </>
                  )}

                  {/* {validatorLicence.current.message(
                    "License Expiry",
                    formData.LicenseDetails.licenseExpiryDate,
                    "required"
                  )} */}
                </Form.Group>
              </div>
            </div>

            <div className="header">
              <div>Social Media</div>
              <hr></hr>
            </div>

            <div className="body">
              <div
                className={`item3 ${
                  !isEdit ? "test-margin" : "text-social-margin"
                }`}
              >
                <span
                  className={`text-nowrap test-instagram ${
                    !errorFacebook ? "test-facebook" : ""
                  }`}
                >
                  <img className="me-1" src={facebook} />
                  &ensp;Facebook - &ensp;
                </span>
                {isEdit ? (
                  <div
                    className={`col-12 col-xs-12 col-md-12 instagram-facebook col-lg-10 ${
                      !errorFacebook ? "test-facebook" : ""
                    }`}
                  >
                    <Form.Group className="mb-0">
                      <Form.Control
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        className="mb-0"
                        type="text"
                        placeholder={`${
                          isEdit
                            ? "Enter complete URL of your Facebook Profile Page"
                            : ""
                        }`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { facebook } = { ...formData };
                          facebook.facebookLink = value;
                          facebook.isEdit = true;
                          facebook.adminApproved = null;
                          if (value === "") {
                            setErrorFacebook(true);
                          }
                          setFormData((prevState) => ({
                            ...prevState,
                            facebook,
                            isEdit: true,
                            status: "PENDING",
                          }));
                        }}
                        value={formData.facebook.facebookLink}
                      />
                      <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                        {/* <sup className="text-danger fs-14">*</sup> */}
                        Enter URL in format www.facebook.com/thecheflane
                      </p>

                      {/* {validator.current.message(
                        'Facebook',
                        formData.socialMediaLinks.facebook,
                        'url',
                      )} */}

                      {!errorFacebook && (
                        <span class="error-message fs-12">
                          Please enter a valid Facebook URL
                        </span>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  formData.facebook?.facebookLink && (
                    <a
                      href={
                        formData.facebook.facebookLink.includes(
                          "http" || "https"
                        )
                          ? formData.facebook.facebookLink
                          : "https://" + formData.facebook.facebookLink
                      }
                      target="_blank"
                      className="fw-700 text-black-25"
                    >
                      {formData.facebook.facebookLink}
                    </a>
                  )
                )}
              </div>

              <div className={`item3 ${!isEdit ? "test-margin" : ""}`}>
                <span className="text-nowrap test-instagram">
                  <img className="" src={Instagram} />
                  &ensp;Instagram - &ensp;
                </span>
                {isEdit ? (
                  <div className="col-xs-12 col-md-12 instagram-facebook col-lg-10">
                    <Form.Group className="mb-0">
                      <Form.Control
                        plaintext={isEdit ? false : true}
                        readOnly={isEdit ? false : true}
                        type="text"
                        className="mb-0"
                        placeholder={`${
                          isEdit
                            ? "Enter complete URL of your Instagram Profile Page"
                            : ""
                        }`}
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { instagram } = { ...formData };
                          instagram.instagramLink = value;
                          instagram.isEdit = true;
                          instagram.adminApproved = null;
                          if (value === "") {
                            setErrorInstagram(true);
                          }
                          setFormData((prevState) => ({
                            ...prevState,
                            instagram,
                            isEdit: true,
                            status: "PENDING",
                          }));
                        }}
                        value={formData.instagram.instagramLink}
                      />
                      {/* {validator.current.message(
                        'Instagram',
                        formData.socialMediaLinks.instagram,
                        'url',
                      )} */}
                      <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                        {/* <sup className="text-danger fs-14">*</sup> */}
                        Enter URL in format www.instagram.com/the.chef.lane
                      </p>

                      {!errorInstagram && (
                        <span class="error-message fs-12">
                          Please enter a valid Instagram URL
                        </span>
                      )}
                    </Form.Group>
                  </div>
                ) : (
                  formData.instagram?.instagramLink && (
                    <a
                      href={
                        formData.instagram.instagramLink.includes(
                          "http" || "https"
                        )
                          ? formData.instagram.instagramLink
                          : "https://" + formData.instagram.instagramLink
                      }
                      target="_blank"
                      className="fw-700 text-black-25"
                    >
                      {formData.instagram.instagramLink}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
          {isEdit && (
            <div className="d-flex mt-3">
              <NormalButton
                label="Cancel"
                className="white-btn mr-3"
                onClick={() => {
                  setIsEdit(false);
                  fetchChefDetailsParent();
                }}
              />
              <span className="m-2" />

              <NormalButton
                primary
                label="Save"
                className="fw-700"
                disabled={isLoading || isuploading}
                onClick={() => submitBasicDetails()}
              />
            </div>
          )}
          {/* {isEditAdmin && (
            <div className="d-flex mt-3">
              <NormalButton
                label="Cancel"
                className="white-btn mr-3"
                onClick={() => setIsEditAdmin(false)}
              />
              <span className="m-2" />

              <NormalButton
                primary
                label="Save"
                className="fw-700"
                disabled={isLoading}
                onClick={() => submitBasicDetails()}
              />
            </div>
          )} */}

          <Modal
            show={showDeactivate}
            onHide={() => setShowDeactivate(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Body>
              <div className="alert-deActive mt-5">
                <h4 className="mb-0">
                  Are you Sure you want to
                  <br /> Delete?
                </h4>
              </div>
              <Modal.Footer>
                <div className="d-flex deactivate-block">
                  <NormalButton
                    label="Yes, Delete"
                    className="w-100 white-btn mr-3"
                    disabled={deactivateLoader}
                    onClick={() => {
                      setdeactivateLoader(true);
                      let body = {
                        id: formData._id,
                      };
                      let id = formData._id;
                      deleteChef(id)
                        .then((data) => {
                          if (data.deletedData) {
                            setShowDeactivate(false);
                            logout();
                          }
                          setdeactivateLoader(false);
                        })
                        .catch(() => {
                          setdeactivateLoader(false);
                          setShowDeactivate(true);
                        });
                    }}
                  />
                  <span className="m-2" />
                  <NormalButton
                    primary
                    label="No, Keep my Account"
                    className="w-100 fw-700"
                    disabled={deactivateLoader}
                    onClick={() => setShowDeactivate(false)}
                  />
                </div>
              </Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            show={showPlatformAlert}
            onHide={() => setShowPlatformAlert(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Header className="bg-white border-0">
              <Modal.Title></Modal.Title>
              <span
                class="cursor-pointer"
                onClick={() => setShowPlatformAlert(false)}
              >
                <img src={closeBtn} alt="icon" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div className="alert-deActive mt-5 pb-5">
                <h4 className="mb-2">
                  Choosing platform delivery partners, may <br />
                  result in limited radius for delivery.
                </h4>
              </div>
              <Modal.Footer></Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal
            // size={'xl'}
            show={printModel}
            onHide={() => setPrintModel(false)}
            backdrop={true}
            keyboard={false}
            className="deactivate-modal"
            centered
          >
            <Modal.Header className="border-0">
              <Modal.Title></Modal.Title>
              <span class="cursor-pointer" onClick={() => setPrintModel(false)}>
                <img src={closeBtn} alt="icon" />
              </span>
            </Modal.Header>
            <Modal.Body>
              {/* <ChefPdf chefId={formData._id} /> */}
              <Modal.Footer></Modal.Footer>
            </Modal.Body>
          </Modal>
          <div className="test-pdf">
            <div style={{ display: "none" }}>
              {/* <ChefPdf ref={componentRef} /> */}
              {!!formData._id && (
                <ComponentToPrint ref={componentRef} chefId={formData._id} />
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        show={isPendingItems}
        onHide={() => setIsPendingItems(false)}
        backdrop={false}
        keyboard={false}
        className="deactivate-modal chef-approval-model"
        centered
      >
        <Modal.Body>
          <div className="alert-deActive mt-5">
            <h4 className="mb-0">
              The changes have been submitted
              <br />
              for approval.
            </h4>
          </div>
          <Modal.Footer>
            <div className="d-flex deactivate-block">
              <NormalButton
                primary
                label="Ok"
                className="w-100 fw-700"
                onClick={() => {
                  setIsLoading(false);
                  setIsEdit(false);
                  fetchChefDetailsParent();
                  setIsPendingItems(false);
                  scrollTop();
                }}
              />
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <ImageCropComponent
        setShowImageCrop={setShowImageCrop}
        showImageCrop={showImageCrop}
        handleFileUpload={handleFileUpload}
        setCroppingImage={setCroppingImage}
        croppingImage={croppingImage}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefEditUpdate,
      getSingle,
      fileUpload,
      imageUpload,
      getCusineList,
      deleteChef,
      getChefPdf,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(BasicDetail);
class ComponentToPrint extends React.Component {
  render() {
    return <>{/* <ChefPdf chefId={this.props.chefId} /> */}</>;
  }
}
