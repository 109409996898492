import React from "react";
import "./NormalInput.scss";

export const NormalInput = ({
  className = "",
  placeholder = "",
  label = "",
  onChange,
  value = "",
  name,
  disabled = false,
  type = "text",
  max = "",
  min = "",
  icon,
}) => {
  return (
    <div className="normal-input">
      {label !== "" ? (
        <div>
          <label className="font-weight-normal mb-1">{label}</label>
        </div>
      ) : null}

      <input
        className={"form-control w-100" + className}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        min={0}
        placeholder={placeholder}
        minLength={min}
        maxLength={max}
        onChange={(e) => {
          let body = {};
          let tempVal = e.target.value;

          body = {
            target: {
              name: e.target.name,
              value: tempVal,
            },
          };

          onChange(body);
        }}
      />
      <span className="icon">{icon}</span>
    </div>
  );
};
