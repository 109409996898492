import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { OrderplacedComp } from "component/Orderplaced";
export class OrderplacedClass extends Component {
  render() {
    return <OrderplacedComp />;
  }
}
export const Orderplaced = connect(null, null)(OrderplacedClass);
