import data from "mockData/listData";
import "./style.scss";
import React, { useEffect, useState } from "react";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";

export const List = ({ title = "Why try The Chef Lane?" }) => {
  const [userLocation, setUserLocation] = useState("India");

  //life cycle
  useEffect(() => {
    setTimeout(() => {
      getLocation();
    }, 200);
  }, []);

  //get Location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    }
  };

  //show Position
  const showPosition = (position) => {
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    if (position) {
      setTimeout(() => {
        navigatorGeoLocation(lat, lon).then((response) => {
          let data = response.results;
          let address = extractAddressFields(data[0].address_components);
          if (address && address.region)
            if (!!address) setUserLocation(address.region);
        });
      }, 1000);
    }
  };

  //show Error
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };

  return (
    <div className="bg-gold-ff why-chef-lane">
      <div className="container">
        <div className="row py-md-5 py-4">
          <div className="col-lg-7 col-12 mx-auto">
            <h2 className="mb-md-5 mb-4">{title}</h2>
            {data.map((list, index) => {
              let { isForAll = true, codesandbox, pointone = "" } = list;
              return isForAll || (!isForAll && userLocation === "India") ? (
                <div className="d-flex align-items-center my-4" key={index}>
                  <div className="flex-shrink-0">
                    <img src={codesandbox} alt="codesandbox" />
                  </div>
                  <p className="flex-grow-1 ms-3 ps-md-1 mt-2 mt-md-0">
                    {pointone}
                  </p>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
