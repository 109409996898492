import React, { Component } from 'react'
import { Navbar } from 'component/common/Navbar'
import 'assets/scss/layouts/WebsiteLayout.scss'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Footer } from 'component/common/Footer'
import { PageIcons } from '../component/common/PageIcons/index'

export class HomeLayout extends Component {
  state = {
    profilemenu: false,
    menuOpenClass: false,
    authToken: '',
  }
  componentDidMount = () => {
    let authToken = localStorage.getItem('AuthToken')
    if (!authToken) {
      // history.push("/");
    } else {
      this.setState({ authToken })
    }
  }
  //handle Profile Menu
  handleProfileMenu = (event, active) => {
    event.stopPropagation()
    this.setState({
      profilemenu: active,
    })
  }
  //handle Side nav
  handleSidenav = () => {
    let { menuOpenClass } = this.state

    this.setState({
      menuOpenClass: !menuOpenClass,
    })
  }

  render() {
    let { children } = this.props
    let { menuOpenClass } = this.state
    return (
      <div id="main-content" onClick={(e) => this.handleProfileMenu(e, false)}>
        <div className="content-wrapper">
          <div className="content-layout">
            <Navbar props={this.props} />
            <div id="sub-content">{children}</div>
            {/* <PageIcons /> */}
            <Footer history={this.props.history} />
          </div>
        </div>
      </div>
    )
  }
}
