import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { UserDetailComp } from "component/UserDetail/UserDetail";
export class UserDetailClass extends Component {
  render() {

    return <UserDetailComp />;

  }
}
export const UserDetail = connect(null, null)(UserDetailClass);
