export const chefDataType = {
  chefData: 'CHEF_DATA',
}
export const notificationType = {
  notificationListData: 'NOTIFICATION_LIST_DATA',
}
export const userLocationType = {
  userLocation: 'USER_LOCATION',
  currentPageChef: 'CURRENT_PAGE_CHEF',
  currentPageMenu: 'CURRENT_PAGE_MENU',
}
