import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { OrderCreationComp } from "./../../component/OrderCreation/index";
export class OrderCreationClass extends Component {
  render() {
    return <OrderCreationComp />;
  }
}
export const OrderCreation = connect(null, null)(OrderCreationClass);
