import React, { useState, useEffect } from 'react'
import { NormalButton } from './../NormalButton/index'
import { NormalSearch } from './NormalSearch'
import './style.scss'
import { NormalCheckbox } from './../NormalCheckbox/index'

export const MultiSelect = (props) => {
  const [causine, setCausine] = useState([])
  const [search, setSearch] = useState('')
  const [filterList, setFilterList] = useState([])
  // const [seletedCausine, setSelectedCausine] = useState(props.selectedCusine);
  console.log(props.selectedCusine)

  // const cuisine = [
  //   "South Indian",
  //   "Chinese",
  //   "North Indian",
  //   "Turkish",
  //   "Chinese",
  //   "Chinese",
  //   "Chinese",
  //   "Chinese",
  // ];

  useEffect(() => {
    props.selectedCusine && setCausine([...props.selectedCusine])
  }, [props.selectedCusine])
  console.log(props.uniqueCuisineLoc, 'uniqueCuisineLoc')

  useEffect(() => {
    setFilterList(
      props.uniqueCuisineLoc.filter((searchUsers) => {
        console.log(searchUsers, 'searchUsers')
        return (
          searchUsers &&
          searchUsers.toLowerCase().includes(search.toLowerCase())
        )
      }),
    )
  }, [search, props.uniqueCuisineLoc])

  const handleChange = (e) => {
    console.log(e.target.value, e.target.name)
    var value = e.target.name

    if (e.target.value) {
      setCausine([...causine, value])
    } else {
      setCausine([...causine.filter((v) => v != value)])
    }
  }
  console.log(causine, 'bb')
  const handleSubmit = (e) => {
    e.preventDefault()
    props.handleCausine(causine)
    props.setShowCausineFilter(false)
  }
  const handleClearAll = (e) => {
    e.preventDefault()
    props.handleCausine([])
    props.setShowCausineFilter(false)
  }

  return (
    <div className="mutiselect ">
      <div className="checkBoxsearch">
        <NormalSearch
          placeholder="search"
          value={search}
          name="searchValue"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <hr />
      <form action="">
        <div className="checkboxOptionContainer">
          <div className=" row">
            {filterList.length != 0 ? (
              filterList.map((list, index) => (
                <div className="sol-xs-12 col-sm-6 mb-3">
                  <NormalCheckbox
                    label={list}
                    onChange={(e) => handleChange(e)}
                    name={list}
                    key={index}
                    checked={causine.includes(list) ? true : false}
                  />
                </div>
              ))
            ) : (
              <div className="col-12 mt-3 pt-1">
                <p className="text-center fs-16 fw-600 text-black-1c">
                  No data found !!!
                </p>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="button_container d-flex justify-content-end ">
          <NormalButton
            label="Clear all"
            className="clear text-black-25 line-height-18 fs-16 mx-3 fw-400"
            onClick={handleClearAll}
          />
          <NormalButton
            label="Apply"
            className="applyNow text-white-FF line-height-18 fs-16 "
            onClick={handleSubmit}
            primary
          />
        </div>
      </form>
    </div>
  )
}
