import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { FB } from "assets/svg/facebook.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import { fileUpload, imageUpload } from "action/FileUpload";
import plus from "../../../assets/images/plus.png";
import shareYellow from "../../../assets/images/shareYellow.png";
import ChefPdf from "component/common/ChefPdf";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { renderToString } from "react-dom/server";
import { convertToSlug } from "service/helperFunctions";
import { history } from "service/helpers";
import { Toast } from "service/toast";
import { handleNameField } from "service/helperFunctions";

import {
  NormalButton,
  NormalToggleSwitch,
  NormalCustomCheckbox,
  NormalDate,
  NormalRadio,
  CommonPageLoader,
  PageIcons,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deactivateChef, getCusineList } from "action/ChefDetailsAct";
import { createCustomer } from "action/CustomerDetailsAct";
import facebook from "assets/images/facebook.png";
import whatsapp from "assets/images/whatsapp.png";
import Instagram from "assets/images/Instagram.png";
import closeGray from "assets/images/closeGray.png";
import AddressDetails from "../AddressDetail";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import copyIcon from "assets/images/copyIcon.png";
import moment from "moment";
import { FRONT_END_PORT } from "utils/constants";
import { logout } from "service/utilities";
import { useReactToPrint } from "react-to-print";
const regExp = /[a-zA-Z]/g;

const initalState = {
  _id: localStorage.getItem("userId"),
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  userTypeRole: "customer",
  AddressDetails: {
    country: "",
    state: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    location: {
      lat: "",
      long: "",
    },
  },
};
function BasicDetail({
  createCustomer,
  getCusineList,
  active,
  fileUpload,
  imageUpload,
  deactivateChef,
  dataFromParent,
  fetchChefDetailsParent,
}) {
  const [show, setShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [profileImgURL, setProfileImgURL] = useState();
  const [formData, setFormData] = useState(initalState);
  const [renderCuisnineList, setrenderCusinieList] = useState([]);
  const [selectedCuisineList, setSelectedCuisineList] = useState([]);
  const [cuisineList, setcuisineList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const cuisineList = ["North-Indian", "South-Indian", "Chinese"];
  const reference = useRef();
  const referenceFile = useRef();
  const [selectedImage, setSelectedImage] = useState();
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  const [rawFile, setRawFile] = useState({});
  const [isuploading, setIsuploading] = useState(false);
  const [deactivateLoader, setdeactivateLoader] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [printLoader, setPrintLoader] = useState(false);
  const [printModel, setPrintModel] = useState(false);
  const [pdfContent, setPdfContent] = useState();
  const wrapperRef = useRef(null);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (active) {
      setIsEdit(false);
    }
    if (Object.keys(dataFromParent).length) fetchChefDetails();
    fetchCusineList();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [active]);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowShare(false);
      setIsCopy(false);
    }
  };
  const fetchChefDetails = async () => {
    let newForm = Object.assign(formData, dataFromParent);
    // setFormData({ ...newForm })
    setFormData((prevState) => ({
      ...prevState,
      ...newForm,
    }));
    setSelectedCuisineList(newForm.cuisine ? newForm.cuisine : []);
  };
  const fetchCusineList = async () => {
    setIsLoading(true);
    await getCusineList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch((e) => setIsLoading(false));
  };
  const handleFileUpload = async (event, name) => {
    let keySet = name === "chefImage" ? setIsuploading : setIsDocumentuploading;
    var data = new FormData();
    let file = {};
    file = event.target.files[0];
    // if (name === 'chefImage') {
    // } else {
    //   file = event;
    //   console.log(event.file)
    // }
    setSelectedImage(file);
    let api = name === "chefImage" ? imageUpload : fileUpload;

    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      Toast({
        type: "error",
        message: "File size should be less than 5MB",
        time: 2000,
      });
    } else {
      keySet(true);

      await api(data)
        .then((data) => {
          if (name === "chefImage") {
            setFormData((prevState) => ({
              ...prevState,
              [name]: data?.data?.url,
            }));
          } else {
            let { LicenseDetails } = { ...formData };
            LicenseDetails.licenseFileUrl = data?.data?.url;
            LicenseDetails.isEdit = true;
            LicenseDetails.adminApproved = null;
            setFormData((prevState) => ({
              ...prevState,
              LicenseDetails,
              isEdit: true,
              status: "PENDING",
            }));
          }
          keySet(false);
        })
        .catch(() => {
          keySet(false);
        });
    }
  };

  const mapCusine = () => {
    setShow(false);
    setrenderCusinieList(selectedCuisineList);
  };

  const handleSelectedCuisine = (e) => {
    console.log(e?.target?.checked);

    if (e?.target?.value) {
      let value = e?.target?.name;
      setSelectedCuisineList([...selectedCuisineList, value]);
      console.log(selectedCuisineList);
    } else {
      setSelectedCuisineList(
        selectedCuisineList.filter((item) => item !== e.target.name)
      );
      console.log(selectedCuisineList);
    }
    // let { cuisineDetails } = { ...formData }
    // cuisineDetails.isEdit = true
    // setFormData({ ...formData, cuisineDetails })
  };

  const submitBasicDetails = async () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      formData["userTypeRole"] = "customer";
      formData["isNewRequest"] = false;
      formData["firstName"] = handleNameField(formData.firstName);
      formData["lastName"] = handleNameField(formData.lastName);
      delete formData["__v"];
      // setFormData({ ...formData, })
      console.log(formData, "basicDetails");
      await createCustomer(formData)
        .then(() => {
          setIsLoading(false);
          setIsEdit(false);
          fetchChefDetailsParent();
          // setIsEditAdmin(false)
          // handleFormChange("addressDetails");
        })
        .catch((e) => setIsLoading(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };

  const handleSearch = (e) => {
    let {
      target: { value },
    } = e;
    setSearch(value);
  };
  const handleCopyText = (copyText) => {
    navigator.clipboard.writeText(copyText);
    setIsCopy(false);
  };

  const printPdf = () => {
    // setPrintLoader(true)
    // window.html2canvas = html2canvas
    // const string = renderToString(<ChefPdf />)
    html2canvas(pdfContent)
      .then((canvas) => {
        const dataURL = canvas.toDataURL();
        const pdf = new jsPDF();

        pdf.addImage(dataURL, "JPEG", 0, 0, 0, 0);

        pdf.save("saved.pdf");
      })
      .then(() => {
        setPrintLoader(false);
      });
  };
  return (
    <>
      <div id="custom-profile-customer">
        <div className="basic_detail_inner_container">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="header">Basic Details</div>
              {!isEdit && (
                <NormalButton
                  primary
                  label="Edit"
                  className="fw-700"
                  disabled={isLoading}
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>
            <hr></hr>
          </div>

          <div className="body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4 d-block d-lg-none">
                <div className="d-flex align-items-start justify-content-end">
                  {/* {!isEdit && (
                    <div className="mt-0">
                      <span>
                        <NormalButton
                          label="Delete Profile "
                          className="w-100 fw-700 outline-gray-btn"
                          onClick={() => setShowDeactivate(true)}
                        />
                      </span>
                    </div>
                  )} */}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    autoComplete="off"
                    placeholder={`${isEdit ? "Enter First Name" : ""}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        firstName: value,
                      }));
                    }}
                    value={formData.firstName}
                  />
                  {validator.current.message(
                    "First Name",
                    formData.firstName,
                    "required|min:3|max:20"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-4">
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    autoComplete="off"
                    placeholder={`${isEdit ? "Enter Last Name" : ""}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        lastName: value,
                      }));
                    }}
                    value={formData.lastName}
                  />
                  {validator.current.message(
                    "Last Name",
                    formData.lastName,
                    "required|max:20"
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 d-none d-lg-block">
                <div className="d-flex align-items-start justify-content-end">
                  {/* {!isEdit && (
                    <div className="mt-0">
                      <span>
                        <NormalButton
                          label="Delete Profile "
                          className="w-100 fw-700 outline-gray-btn"
                          onClick={() => setShowDeactivate(true)}
                        />
                      </span>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <Form.Group>
                  <Form.Label>Email ID</Form.Label>
                  <a
                    className=" email text-grey-7c text-underline "
                    href={`mailto:${formData.email}`}
                  >
                    <Form.Control
                      className={!isEdit ? "remove-height cursor-pointer" : ""}
                      disabled={true}
                      plaintext={isEdit ? false : true}
                      readOnly={isEdit ? false : true}
                      type="email"
                      placeholder={`${isEdit ? "Enter Email ID" : ""}`}
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          email: value,
                        }));
                      }}
                      value={formData.email}
                    />
                  </a>
                  {validator.current.message(
                    "Email",
                    formData.email,
                    "required|email"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-4">
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    className={!isEdit ? "remove-height" : ""}
                    disabled={true}
                    plaintext={isEdit ? false : true}
                    readOnly={isEdit ? false : true}
                    type="text"
                    autoComplete="off"
                    placeholder={`${isEdit ? "Enter Mobile Number" : ""}`}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      if (!regExp.test(value)) {
                        setFormData((prevState) => ({
                          ...prevState,
                          mobileNumber: value,
                        }));
                      }
                    }}
                    value={formData.mobileNumber}
                  />
                  {validator.current.message(
                    "Mobile Number",
                    formData.mobileNumber,
                    "required|phone|max:13"
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {/* <div className="header">
            <div>Address Details</div>
            <hr></hr>
          </div> */}
          <AddressDetails
            isEdit={isEdit}
            validator={validator}
            formData={formData}
            setFormData={setFormData}
            updateValidator={updateValidator}
          />
        </div>
        {isEdit && (
          <div className="d-flex mt-3">
            <NormalButton
              label="Cancel"
              className="white-btn mr-3"
              onClick={() => {
                setIsEdit(false);
                fetchChefDetailsParent();
              }}
            />
            <span className="m-2" />

            <NormalButton
              primary
              label="Save"
              className="fw-700"
              disabled={isLoading}
              onClick={() => submitBasicDetails()}
            />
          </div>
        )}

        <Modal
          show={showDeactivate}
          onHide={() => setShowDeactivate(false)}
          backdrop={true}
          keyboard={false}
          className="deactivate-modal"
          centered
        >
          <Modal.Body>
            <div className="alert-deActive mt-5">
              <h4 className="mb-0">
                Are you Sure you want to
                <br /> Delete?
              </h4>
            </div>
            <Modal.Footer>
              <div className="d-flex deactivate-block">
                <NormalButton
                  label="Yes, Delete"
                  className="w-100 white-btn mr-3"
                  disabled={deactivateLoader}
                  onClick={() => {
                    setdeactivateLoader(true);
                    let body = {
                      chefId: localStorage.getItem("userId"),
                      deactivateId: true,
                    };
                    deactivateChef(body)
                      .then((data) => {
                        if (data) {
                          setdeactivateLoader(false);
                          setShowDeactivate(false);
                          logout();
                        }
                      })
                      .catch(() => {
                        setdeactivateLoader(false);
                        setShowDeactivate(true);
                      });
                  }}
                />
                <span className="m-2" />
                <NormalButton
                  primary
                  label="No, Keep my Account"
                  className="w-100 fw-700"
                  disabled={deactivateLoader}
                  onClick={() => setShowDeactivate(false)}
                />
              </div>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCustomer,
      fileUpload,
      imageUpload,
      getCusineList,
      deactivateChef,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(BasicDetail);
