import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { CreateaccountComp } from 'component/auth/login/index'
export class CreateaccountClass extends Component {
  render() {
    return (
      <React.Fragment>
        <CreateaccountComp />
      </React.Fragment>
    )
  }
}
export const Createaccount = connect(null, null)(CreateaccountClass)
