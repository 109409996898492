import React, { Component } from 'react'

export class Pagination extends Component {
  handlePagination = (direction) => {
    try {
      let { page = 1 } = this.props.pageMeta || {}
      let pageNumber = eval(`${page} ${direction} ${1}`)
      console.log(pageNumber, 'pageNumber')

      this.props.handleChange({ page: pageNumber })
    } catch (err) {
      console.log(err)
    }
  }

  handleLastPage = (totalPages) => {
    this.props.handleChange({ page: totalPages })
  }

  render() {
    let { totalPages = 10, page = 1 } = this.props.pageMeta || {}
    if (totalPages <= 1) {
      return ''
    }

    let { totalMembers = 0, isTotalEntriesNeeded = false } = this.props

    return (
      <div className="pagination-area flex-column">
        {isTotalEntriesNeeded ? (
          <div>
            <p className="fs-16 mb-2 fw-600 text-primary-color d-flex justify-content-center align-items-center">
              You have total of {totalMembers} members
            </p>
          </div>
        ) : null}
        <div className="d-flex text-center w-100 justify-content-center pr-4">
          <p className="m-0 d-flex align-items-center">
            <button
              className={`btn-effect table-left-arrow ${
                page == 1 ? 'disabled' : 'cursor-pointer'
              }`}
              disabled={page == 1}
              onClick={(e) => page != 1 && this.handlePagination('-')}
            >
              <span className="icon-page-left pr-3" />
              Previous
            </button>

            <span className="current-page">{page}</span>
            {/* <span className="total-page">{totalPages}</span> */}

            <button
              className={`btn-effect table-right-arrow ${
                page == totalPages ? 'disabled' : 'cursor-pointer'
              }`}
              disabled={page == totalPages}
              onClick={(e) => page != totalPages && this.handlePagination('+')}
            >
              Next
              <span className="pl-3 icon-page-right" />
            </button>
          </p>
        </div>
      </div>
    )
  }
}
