import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CloseButton } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import getSymbolFromCurrency from "currency-symbol-map";
import {
  NormalButton,
  NormalSelect,
  NormalToggleSwitch,
  NormalCustomCheckbox,
  NormalRadio,
  CommonPageLoader,
  ImageCropComponent,
} from "component/common";
import { Pagination } from "component/common/Pagination";

import AboutChef from "component/ChefDatail/AboutChef";
import { history } from "service/helpers";
import { imageUpload } from "action/FileUpload";
import {
  getMenuDetails,
  createDish,
  upDateDish,
  chefCompleted,
  foodlabelList,
  deleteDish,
  menuAvailability,
} from "action/ChefDetailsAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import editIcon from "assets/images/editIcon.png";
import deleteIcon from "assets/images/deleteIcon.png";
import searchIcon from "assets/images/search.png";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import SimpleReactValidator from "simple-react-validator";
import { Toast } from "service/toast";

let containArray = [
  {
    value: "Veg",
    checked: false,
  },
  {
    value: "Keto",
    checked: false,
  },
  {
    value: "Glutten-free",
    checked: false,
  },
  {
    value: "Sugar-free",
    checked: false,
  },
];
function MenuDetails({
  getMenuDetails,
  menuAvailability,
  foodlabelList,
  imageUpload,
  upDateDish,
  createDish,
  fetchChefDetails,
  chefCompleted,
  deleteDish,
  dataFromParent,
  currentPageMenu,
}) {
  const [show, setShow] = React.useState(false);
  const [editId, setEditId] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [preference, setPreference] = React.useState([]);

  // const [AdvanceOrderType, setAdvanceOrderType] = React.useState("Immediate Order");
  // const [typeOfMenu, setMenuType] = React.useState("QuickBites");
  const [isPendingItems, setIsPendingItems] = useState(false);

  const [selectedImage, setSelectedImage] = React.useState();
  const [croppingImage, setCroppingImage] = useState();
  const [dishImage, setDishImgURl] = React.useState();
  const reference = React.useRef();
  const [updateValidator, setUpdateValidator] = useState(true);
  const [pageMeta, setPageMeta] = useState(null);
  const [showImageCrop, setShowImageCrop] = useState(false);

  const [formData, setFormData] = React.useState({
    name: "",
    about: "",
    typeOfMenu: "Available Now",
    price: "",
    AdvanceOrderType: "",
    dishImage: "",
    adminApproved: null,
    isEdit: false,
    dishCategory: "Veg",
    DishOrderCount: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [contains, setContains] = useState(containArray);
  const [search, setSearch] = useState("");
  const [isuploading, setIsuploading] = useState(false);
  const wrapperRef = useRef(null);
  const handleClose = () => {
    handleReset();
    validator.current.hideMessages();
    validatorAdvance.current.hideMessages();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorAdvance = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  useEffect(() => {
    if (currentPageMenu) {
      menuListApiCallFunc(currentPageMenu);
    } else {
      menuListApiCallFunc();
    }
    fetchCusineList();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);
  const fetchCusineList = async () => {
    let id = localStorage.getItem("userId");
    setIsLoading(true);
    await foodlabelList()
      .then(({ data }) => {
        if (data.length) {
          let newData = data.map((item) => {
            return {
              value: item,
              checked: false,
            };
          });
          setPreference(newData);
        }
        // setIsLoading(false);
      })
      .catch((e) => setIsLoading(false));
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setToggle(false);
    }
  };
  const menuListApiCallFunc = (page = 1) => {
    let payload = {
      chefId: localStorage.getItem("userId"),
    };
    let query = {
      page: page,
      limit: 10,
    };
    setIsLoading(true);
    getMenuDetails(payload, query)
      .then(({ data }) => {
        if (data.list) {
          setList(data.list);
          setPageMeta(data.pageMeta);
        }
        console.log(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleBeforeImageCropping = (event) => {
    setCroppingImage(event.target.files[0]);
    setShowImageCrop(true);
  };
  const handleFileUpload = async (event) => {
    setSelectedImage(event);

    var data = new FormData();
    data.append("file", event);
    let file = event;
    let size = file.size;
    if (size > 1048576 * 5) {
      Toast({
        type: "error",
        message: "Please upload image file that is 5MB or less",
        time: 2000,
      });
    } else {
      setIsuploading(true);

      await imageUpload(data)
        .then(({ data }) => {
          setFormData((prevState) => ({
            ...prevState,
            dishImage: data?.url,
          }));
          setIsuploading(false);
        })
        .catch(() => {
          setIsuploading(false);
        });
    }
  };

  const handleAddDish = async () => {
    formData["chefId"] = localStorage.getItem("userId");
    formData["preference"] = preference
      .filter((item) => item.checked)
      .map((item) => {
        return item.value;
      });
    console.log(formData, "formData");
    let flag =
      formData.typeOfMenu === "Available Now"
        ? validator.current.allValid()
        : validator.current.allValid() && validatorAdvance.current.allValid();
    if (flag) {
      setIsLoading(true);

      if (editId === "") {
        formData["isEdit"] = true;
        formData["isNewRequest"] = false;
        formData["status"] = "PENDING";
        delete formData["masterMenuId"];

        createDish(formData)
          .then((data) => {
            if (data) {
              handleClose();
              menuListApiCallFunc();
              if (!dataFromParent?.autoApprove) setIsPendingItems(true);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        formData["masterMenuId"] = editId;
        formData["isEdit"] = true;
        formData["status"] = "PENDING";
        formData["isNewRequest"] = false;
        formData["adminApproved"] = null;
        // formData['chefId'] = localStorage.getItem("userId");
        upDateDish(formData)
          .then((data) => {
            if (data) {
              menuListApiCallFunc();
              handleClose();
              if (!dataFromParent?.autoApprove) setIsPendingItems(true);
            }
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    } else {
      if (formData.typeOfMenu !== "Available Now") {
        validatorAdvance.current.showMessages();
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
    }
  };

  const handleComplete = async () => {
    setIsLoading(true);
    let id = localStorage.getItem("userId");
    chefCompleted(id)
      .then((data) => {
        if (data) {
          fetchChefDetails();
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleAvailabilityChange = async (list, checked) => {
    let { _id, adminApproved } = list;
    // list.dishIsAvailable = e.target.checked;
    // let clone = [...data];
    // clone[index] = list;
    // setList(clone)
    setIsLoading(true);
    let body = {
      menuId: _id,
      chefId: localStorage.getItem("userId"),
      adminApproved: adminApproved,
      dishIsAvailable: checked,
    };
    // let id = localStorage.getItem("userId");
    menuAvailability(body)
      .then((data) => {
        if (data) {
          menuListApiCallFunc();
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleDeleteDish = async (list) => {
    let { _id } = list;

    setIsLoading(true);
    // let id = localStorage.getItem("userId");
    deleteDish(_id)
      .then((data) => {
        if (data) {
          menuListApiCallFunc();
        }
        // setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handleEdit = (data) => {
    console.log(data);
    let {
      about,
      name,
      typeOfMenu,
      AdvanceOrderType,
      dishImage,
      price,
      dishCategory,
      orderType,
    } = data;

    setFormData((prevState) => ({
      ...prevState,
      name,
      about,
      dishImage,
      typeOfMenu,
      price,
      orderType,
      dishCategory,
      AdvanceOrderType,
    }));
    // setPreference(preference);
    // setMenuType(typeOfMenu);
    // setAdvanceOrderType(AdvanceOrderType);
    // setDishImgURl(dishImage);
    let clone = [...preference];
    clone.map((item) => {
      item.checked = data.preference.includes(item.value);
      return item;
    });
    setPreference(clone);
    setEditId(data._id);
    handleShow();
  };
  const handleReset = () => {
    let defaultValue = {
      name: "",
      about: "",
      typeOfMenu: "Available Now",
      price: "",
      AdvanceOrderType: "",
      dishImage: "",
      adminApproved: null,
      isEdit: false,
      dishCategory: "Veg",
      DishOrderCount: "",
    };
    // validator.current.hideMessages();
    // setFormData(defaultValue)
    setFormData((prevState) => ({
      ...prevState,
      ...defaultValue,
    }));
    setPreference(
      preference.map((item) => {
        item.checked = false;
        return item;
      })
    );
  };
  const handlePagination = (page) => {
    menuListApiCallFunc(page.page);
  };

  return (
    <div id="menu-custom">
      <div className="menu_inner_container">
        <div className="menu_header">
          <div className="header">Menu</div>
          <div className="btn headerButton" onClick={handleShow}>
            Add Dish
          </div>
        </div>
        <hr></hr>
        <div>
          {!isLoading ? (
            <>
              {list.length > 0 ? (
                <div className="row">
                  {list.map((item, index) => {
                    return (
                      <MenuCard
                        key={index}
                        index={index}
                        handleAvailabilityChange={handleAvailabilityChange}
                        list={item}
                        handleEdit={handleEdit}
                        handleDeleteDish={handleDeleteDish}
                      />
                    );
                  })}
                  <div className="">
                    {pageMeta ? (
                      <Pagination
                        handleChange={handlePagination}
                        pageMeta={pageMeta}
                      />
                    ) : null}
                  </div>
                </div>
              ) : (
                <h3 className="text-center">No Dish Available</h3>
              )}
            </>
          ) : (
            <CommonPageLoader />
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="bg-primary-color rounded-top">
          <Modal.Title className=" text-white">Add Dish</Modal.Title>
          <span class="cursor-pointer" onClick={handleClose}>
            <img src={closeBtn} alt="icon" />
          </span>
        </Modal.Header>
        {/* <hr></hr> */}
        <Modal.Body style={{ backgroundColor: "#F0F2F5" }}>
          <div className="modal_body add-dish-chef">
            <div className="modal_body_left">
              <div className="bg-white p-3 ">
                <div className="image_box">
                  {isuploading ? (
                    <CommonPageLoader />
                  ) : (
                    <>
                      {
                        !!formData.dishImage ? (
                          <img src={formData.dishImage} alt="Thumb" />
                        ) : (
                          <span className="d-flex">
                            <img src={userUpload} alt="image" />
                          </span>
                        )
                        // <>
                        //   {selectedImage && (
                        //     <img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                        //   )}
                        // </>
                      }
                    </>
                  )}
                </div>
                <div className="upload_btn image-upload">
                  <input
                    ref={reference}
                    onChange={(e) => {
                      e.target.files[0] &&
                        handleBeforeImageCropping(e, "chefImage");
                    }}
                    type="file"
                    style={{ display: "none" }}
                    // multiple={false}
                    accept="image/*"
                  />
                  <NormalButton
                    label=" Upload Image"
                    className="w-100 fw-700 outline-gray-btn"
                    onClick={() => {
                      reference.current.click();
                      //setShowImageCrop(true)
                    }}
                    disabled={isuploading}
                  />
                  <p className="fs-12 alert-for-upload-food mb-0 mt-2">
                    {/* <sup className="text-danger fs-14">*</sup> */}
                    Please upload image file that is 5MB or less
                  </p>

                  {/* {validator.current.message(
                    'Image',
                    formData.dishImage,
                    'required',
                  )} */}
                </div>
              </div>
            </div>
            <div className="modal_body_right rounded">
              <div className=" row">
                <div className=" col-sm-6">
                  <Form.Group>
                    <Form.Label>
                      Name<sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Dish Name"
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;

                        setFormData((prevState) => ({
                          ...prevState,
                          name: value,
                        }));
                      }}
                      value={formData.name}
                    />
                    {validator.current.message(
                      "Name",
                      formData.name,
                      "required|min:3"
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-6">
                  <Form.Group>
                    <Form.Label>
                      Price<sup>*</sup>
                    </Form.Label>
                    <div class="input-group  search-input-group">
                      <div class="input-group-append Custom-left">
                        <span
                          class="input-group-text bg-white border-right-0"
                          id="basic-addon2"
                        >
                          {dataFromParent.currency
                            ? getSymbolFromCurrency(dataFromParent.currency)
                            : "₹"}
                        </span>
                      </div>
                      <Form.Control
                        type="number"
                        placeholder="Enter Price..."
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          setFormData((prevState) => ({
                            ...prevState,
                            price: value,
                          }));
                        }}
                        value={formData.price}
                      />
                    </div>
                    {validator.current.message(
                      "Price",
                      formData.price,
                      "required|numeric"
                    )}
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-0">
                    <Form.Label>About dish</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter here..."
                      onChange={(e) => {
                        let {
                          target: { value },
                        } = e;
                        setFormData((prevState) => ({
                          ...prevState,
                          about: value,
                        }));
                      }}
                      value={formData.about}
                    />
                    {validator.current.message(
                      "About",
                      formData.about,
                      "max:150"
                    )}
                  </Form.Group>
                  <br></br>
                  <div>
                    <div>
                      {/* <Form.Label className={`${formData.typeOfMenu === 'Available Now' ? 'text-grey-9d' : ''}`} >Advanced Order type </Form.Label> */}

                      <span className="mx-2">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label={"Veg"}
                          name="Veg"
                          value={formData.dishCategory === "Veg"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              dishCategory: e.target.name,
                            }));
                          }}
                          checked={formData.dishCategory === "Veg"}
                        />
                        <span className="mx-0">
                          <img src={vegIcon} alt="icons" />
                        </span>
                      </span>
                      <span className="mx-4">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label={"Non Veg"}
                          name="Non Veg"
                          value={formData.dishCategory === "Non Veg"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              dishCategory: e.target.name,
                            }));
                          }}
                          checked={formData.dishCategory === "Non Veg"}
                        />
                        <span className="mx-0">
                          <img src={nonvegIcon} alt="icons" />
                        </span>
                      </span>
                    </div>
                    <br />
                    <div>
                      <Form.Label>
                        Food Labels
                        {/* <sup>*</sup> */}
                      </Form.Label>

                      <div ref={wrapperRef} className="position-relative">
                        <div
                          className="multi-temp-select-outline"
                          onClick={(e) => {
                            setToggle(true);
                          }}
                        >
                          <div className="d-flex foods-list">
                            {preference.map((items, index) => {
                              return (
                                <>
                                  {items.checked && (
                                    <div className="contains_item">
                                      {items.value}&ensp;
                                      <b
                                        className="rmv_cuisine_item"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          items.checked = false;
                                          let clone = [...preference];
                                          clone[index] = items;
                                          console.log(items);
                                          console.log(clone);
                                          setPreference(clone);
                                        }}
                                      >
                                        &#10006;
                                      </b>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                        {toggle && (
                          <div className="position-options">
                            <div className="search-outer">
                              <div class="input-group  search-input-group">
                                <input
                                  type="text"
                                  id="searchValue"
                                  name="searchValue"
                                  value={search}
                                  onChange={(e) => {
                                    let {
                                      target: { value },
                                    } = e;
                                    setSearch(value);
                                  }}
                                  className="form-control border-right-0 searchInput"
                                  placeholder="Search"
                                  aria-label="Search"
                                  aria-describedby="basic-addon2"
                                />
                                <div class="input-group-append ">
                                  <span
                                    class="input-group-text bg-white border-left-0"
                                    id="basic-addon2"
                                  >
                                    <img
                                      className="mailIcon"
                                      src={searchIcon}
                                      alt="search"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="list-body">
                                <div className="row">
                                  {preference
                                    .filter((item) =>
                                      search === ""
                                        ? item
                                        : String(item.value)
                                            .toLowerCase()
                                            .match(search.toLowerCase())
                                    )
                                    .map((item, index) => {
                                      return (
                                        <div
                                          className="col-12 mb-2"
                                          key={index}
                                        >
                                          <NormalCustomCheckbox
                                            name={item.value}
                                            checked={item.checked}
                                            value={item.checked}
                                            onChange={(e) => {
                                              // let flag =
                                              //   preference.filter(
                                              //     (item) => item.checked
                                              //   ).length > 2;
                                              if (e.target.value) {
                                                item.checked = e.target.value;
                                                let clone = [...preference];
                                                let nweIndex = clone.findIndex(
                                                  (ar) =>
                                                    ar.value === e.target.name
                                                );
                                                clone[nweIndex] = item;
                                                setPreference(clone);
                                              }
                                            }}
                                            label={item.value}
                                            // disable={
                                            //   preference.filter(
                                            //     (item) => item.checked,
                                            //   ).length > 2
                                            // }
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* {validator.current.message(
                        "Preference",
                        preference.filter((item) => item.checked),
                        "required"
                      )} */}
                    </div>
                    <br></br>
                    <div>
                      <Form.Label>Type of Menu </Form.Label>
                      <br></br>
                      <span className="mx-2">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label={"Available Now"}
                          name="Available Now"
                          value={formData.typeOfMenu === "Available Now"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              typeOfMenu: e.target.name,
                              AdvanceOrderType: "",
                              DishOrderCount: "",
                            }));
                            validatorAdvance.current.hideMessages();
                          }}
                          checked={formData.typeOfMenu === "Available Now"}
                          disabled={false}
                        />
                      </span>
                      <span className="mx-4">
                        <NormalRadio
                          className=" border-0 rounded-0"
                          label={"Advance Order"}
                          name="Advance Order"
                          value={formData.typeOfMenu === "Advance Order"}
                          onChange={(e) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              typeOfMenu: e.target.name,
                            }));
                          }}
                          checked={formData.typeOfMenu === "Advance Order"}
                          disabled={false}
                        />
                      </span>
                    </div>
                    <br></br>
                    <div>
                      <Form.Label
                        className={`mb-2 ${
                          formData.typeOfMenu === "Available Now"
                            ? "text-grey-9d"
                            : ""
                        }`}
                      >
                        Advance Order type{" "}
                        {formData.typeOfMenu !== "Available Now" && (
                          <sup>*</sup>
                        )}{" "}
                      </Form.Label>
                      <br></br>
                      <div className="row">
                        <div className=" col-sm-12">
                          <Form.Group>
                            {/* <Form.Label>Name</Form.Label> */}
                            <Form.Control
                              disabled={formData.typeOfMenu === "Available Now"}
                              type="text"
                              placeholder="Order type in 30 characters "
                              autoComplete="off"
                              onChange={(e) => {
                                let {
                                  target: { value },
                                } = e;

                                setFormData((prevState) => ({
                                  ...prevState,
                                  AdvanceOrderType: value,
                                }));
                              }}
                              value={formData.AdvanceOrderType}
                            />
                            {validatorAdvance.current.message(
                              "Advance Order Type",
                              formData.AdvanceOrderType,
                              "required|min:3|max:30"
                            )}
                          </Form.Group>
                        </div>
                        {/* <div className="col-3">
                          <Form.Group>
                            <NormalSelect
                              value={formData.DishOrderCount}
                              options={optionNumber}
                              placeholder={'0'}
                              name={'DishOrderCount'}
                              onChange={(e) => {
                                let {
                                  target: { value, name },
                                } = e

                                setFormData((prevState) => ({
                                  ...prevState,
                                  DishOrderCount: value,
                                }))
                              }}
                              disabled={formData.typeOfMenu === 'Available Now'}
                            />
                             {validator.current.message('About', formData.DishOrderCount, 'required|min:3|max:150')} 
                          </Form.Group>
                        </div>
                        <div className="col-4">
                          <Form.Group>

                            <NormalSelect
                              value={formData.AdvanceOrderType}
                              options={options}
                              placeholder={'Select'}
                              name={'AdvanceOrderType'}
                              onChange={(e) => {
                                let {
                                  target: { value, name },
                                } = e

                                setFormData((prevState) => ({
                                  ...prevState,
                                  AdvanceOrderType: value,
                                }))
                              }}
                              disabled={formData.typeOfMenu === 'Available Now'}
                            />
                            {validator.current.message('About', formData.AdvanceOrderType, 'required|min:3|max:150')}
                          </Form.Group>
                        </div> */}
                      </div>
                    </div>
                    {/* {formData.typeOfMenu === 'Advance order' ? validator.current.message('Order Type', formData.AdvanceOrderType, 'required') : ''} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mt-3">
            <NormalButton
              label="Cancel"
              className="white-btn mr-3"
              disabled={isLoading}
              onClick={() => handleClose()}
            />
            <span className="m-2" />

            <NormalButton
              primary
              label={editId === "" ? "Save" : "Update"}
              className=""
              disabled={isLoading}
              onClick={() => {
                handleAddDish();
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isPendingItems}
        onHide={() => {
          setIsPendingItems(false);
          setEditId("");
        }}
        backdrop={false}
        keyboard={false}
        className="deactivate-modal chef-approval-model"
        centered
      >
        <Modal.Body>
          <div className="alert-deActive mt-5">
            <h4 className="mb-0">
              {/* {editId === ''
                ? 'Your new menu added is '
                : 'Your edit to the menu'}
              <br /> submitted to the admin for approval.
              <br /> */}
              The changes have been submitted
              <br />
              for approval.
            </h4>
          </div>
          <Modal.Footer>
            <div className="d-flex deactivate-block">
              <NormalButton
                primary
                label="Ok"
                className="w-100 fw-700"
                onClick={() => {
                  setIsPendingItems(false);
                  setEditId("");
                  // scrollTop()
                }}
              />
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      <ImageCropComponent
        setShowImageCrop={setShowImageCrop}
        showImageCrop={showImageCrop}
        handleFileUpload={handleFileUpload}
        setCroppingImage={setCroppingImage}
        croppingImage={croppingImage}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentPageMenu: state.commonStore.currentPageMenu,
});

// export default MenuDetails;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMenuDetails,
      createDish,
      chefCompleted,
      upDateDish,
      deleteDish,
      menuAvailability,
      imageUpload,
      foodlabelList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDetails);

const MenuCard = ({
  list,
  handleEdit,
  handleDeleteDish,
  handleAvailabilityChange,
  index,
}) => {
  let { preference = [] } = list;
  let imagePreference = preference.includes("Non Veg")
    ? nonvegIcon
    : preference.includes("Vegetarian")
    ? vegIcon
    : "";
  // const [pageViewMore, setpageViewMore] = useState(3);
  // const handleViewMore = () => {
  //   setpageViewMore();
  // };
  // const handleViewLess = () => {
  //   setpageViewMore(3);
  // };
  return (
    <div className="col-xs-12 col-sm-12 col-lg-6 mb-3">
      <div className="d-flex outline flex-column">
        <div className="">
          <div className="d-flex ">
            {list.dishImage ? (
              <>
                <div className="">
                  <div className="foodimage">
                    <img src={list.dishImage} alt="" />
                    <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                      {/* {list.status} */}
                    </div>
                  </div>
                </div>
                <div className="menucard_description ml-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex ">
                      <div className="foodname fs-18 line-height-20 fw-700 text-grey-7c ">
                        {list.name.length < 15 ? (
                          list.name
                        ) : (
                          <React.Fragment>
                            {list.name.substring(0, 14)}
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 300 }}
                              overlay={<Tooltip>{list.name}</Tooltip>}
                              containerPadding={20}
                            >
                              <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                                ...Read more
                              </span>
                            </OverlayTrigger>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end block-edit-delte">
                      <div className="nonVageIcons ">
                        {list.dishCategory && (
                          <img
                            src={
                              list.dishCategory === "Non Veg"
                                ? nonvegIcon
                                : vegIcon
                            }
                            alt=""
                          />
                        )}
                      </div>
                      <span
                        className="px-2 cursor-pointer"
                        onClick={() => {
                          handleEdit(list);
                        }}
                      >
                        <img src={editIcon} alt="icon" />
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleDeleteDish(list);
                        }}
                      >
                        <img src={deleteIcon} alt="icon" />
                      </span>
                    </div>
                    {/* <div className="order_status text-primary-color fs-16 line-height-18 fw-700">
                  {list.orderStatus}
                </div> */}
                  </div>
                  <p className="bites">{list.typeOfMenu}</p>

                  {list.preference ? (
                    <div className="order-Type-container">
                      {list.preference.length <= 2 ? (
                        list.preference.map((type, index) => (
                          <span key={index} className="order-Type">
                            {type}
                          </span>
                        ))
                      ) : (
                        <React.Fragment>
                          {list.preference.map(
                            (type, index) =>
                              index < 2 && (
                                <span key={index} className="order-Type">
                                  {type}
                                </span>
                              )
                          )}
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 200, hide: 300 }}
                            overlay={
                              <Tooltip>
                                {list.preference.slice(2).join(", ")}
                              </Tooltip>
                            }
                            containerPadding={20}
                          >
                            <span
                              className="text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500"
                              style={{
                                color: "#b58204",
                              }}
                            >
                              ...Read more
                            </span>
                          </OverlayTrigger>
                        </React.Fragment>
                      )}
                    </div>
                  ) : null}
                  {/* {list.preference.length &&
                      list.preference
                        .slice(0, pageViewMore)
                        .map((item, index) => {
                          return (
                            <span key={index} className="order-Type">
                              {item}
                            </span>
                          );
                        })} */}
                  {/* {list.preference.length && list.preference.length > 3 && (
                      <>
                        {pageViewMore && pageViewMore === 3 ? (
                          <div className="d-flex justify-content-center align-items-center  order-served-viewmore">
                            <span
                              className="fs-14 text-primary-color cursor-pointer"
                              onClick={handleViewMore}
                            >
                              View More
                            </span>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center order-served-viewmore">
                            <span
                              className="fs-14 text-primary-color cursor-pointer"
                              onClick={handleViewLess}
                            >
                              View Less
                            </span>
                          </div>
                        )}
                      </>
                    )} */}

                  {/* {list.contains ? (
            <div className="d-flex type-menu-container my-2">
              {list.contains.map((type, index) => (
                <span
                  key={index}
                  className="type_menu fs-14 mr-2 line-height-16 text-red-FE bg-red-FF "
                >
                  {type}
                </span>
              ))}
            </div>
          ) : null} */}
                </div>
              </>
            ) : (
              <div className="menucard_description ms-0 w-100">
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <div className="foodname fs-18 line-height-20 fw-700 text-grey-7c ">
                      {list.name.length < 15 ? (
                        list.name
                      ) : (
                        <React.Fragment>
                          {list.name.substring(0, 14)}
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 200, hide: 300 }}
                            overlay={<Tooltip>{list.name}</Tooltip>}
                            containerPadding={20}
                          >
                            <span className="text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                              ...Read more
                            </span>
                          </OverlayTrigger>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end block-edit-delte">
                    <div className="nonVageIcons ">
                      {list.dishCategory && (
                        <img
                          src={
                            list.dishCategory === "Non Veg"
                              ? nonvegIcon
                              : vegIcon
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <span
                      className="px-2 cursor-pointer"
                      onClick={() => {
                        handleEdit(list);
                      }}
                    >
                      <img src={editIcon} alt="icon" />
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        handleDeleteDish(list);
                      }}
                    >
                      <img src={deleteIcon} alt="icon" />
                    </span>
                  </div>
                  {/* <div className="order_status text-primary-color fs-16 line-height-18 fw-700">
                  {list.orderStatus}
                </div> */}
                </div>
                <p className="bites">{list.typeOfMenu}</p>

                {/* {list.preference.length &&
                    list.preference
                      .slice(0, pageViewMore)
                      .map((item, index) => {
                        return (
                          <span key={index} className="order-Type">
                            {item}
                          </span>
                        );
                      })} */}
                {list.preference ? (
                  <div className="order-Type-container">
                    {list.preference.length <= 2 ? (
                      list.preference.map((type, index) => (
                        <span key={index} className="order-Type">
                          {type}
                        </span>
                      ))
                    ) : (
                      <React.Fragment>
                        {list.preference.map(
                          (type, index) =>
                            index < 2 && (
                              <span key={index} className="order-Type">
                                {type}
                              </span>
                            )
                        )}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={
                            <Tooltip>
                              {list.preference.slice(2).join(", ")}
                            </Tooltip>
                          }
                          containerPadding={20}
                        >
                          <span
                            className="text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500"
                            style={{
                              color: "#b58204",
                            }}
                          >
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                ) : null}
                {/* {list.preference.length && list.preference.length > 3 && (
                    <>
                      {pageViewMore && pageViewMore === 3 ? (
                        <div className="d-flex justify-content-center align-items-center  order-served-viewmore">
                          <span
                            className="fs-14 text-primary-color cursor-pointer"
                            onClick={handleViewMore}
                          >
                            View More
                          </span>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center order-served-viewmore">
                          <span
                            className="fs-14 text-primary-color cursor-pointer"
                            onClick={handleViewLess}
                          >
                            View Less
                          </span>
                        </div>
                      )}
                    </>
                  )} */}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex availbilty-width-block">
          <div className="avail-width">
            <Form.Label className="mt-1 fs-12 mb-1">Availability </Form.Label>
            <div className="d-flex align-items-center">
              <NormalToggleSwitch
                name="availability "
                onChange={(e) => {
                  handleAvailabilityChange(list, e.target.checked);
                }}
                checked={list.dishIsAvailable}
              />
              <div className="check-value ml-2">
                {list.dishIsAvailable ? "YES" : "NO"}
              </div>
            </div>
          </div>
          <p className="about-height fs-14 text-grey-7c line-height-18  fw-400 mb-0">
            {list.about.length < 30 ? (
              list.about
            ) : (
              <React.Fragment>
                {list.about.substring(0, 29)}{" "}
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 200, hide: 300 }}
                  overlay={<Tooltip>{list.about}</Tooltip>}
                  containerPadding={20}
                >
                  <span className="text-yellow-C1 cursor-pointer">
                    ...Read more
                  </span>
                </OverlayTrigger>
              </React.Fragment>
            )}
            {/* {list.about} */}
          </p>

          <div className="d-none mobile-edit-delete">
            <span
              className="px-2 cursor-pointer"
              onClick={() => {
                handleEdit(list);
              }}
            >
              <img src={editIcon} alt="icon" />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                handleDeleteDish(list);
              }}
            >
              <img src={deleteIcon} alt="icon" />
            </span>
          </div>
        </div>
        <div className="d-flex mt-1">
          <p className="mb-0 fw-700 text-primary-color text-start">
            {list.AdvanceOrderType}
          </p>
        </div>
      </div>
    </div>
  );
};
