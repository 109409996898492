import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { TrackOrderComp } from "component/TrackOrder/TrackOrder";
export class TrackOrderClass extends Component {
  render() {

    return <TrackOrderComp />;

  }
}
export const TrackOrder = connect(null, null)(TrackOrderClass);