import { addQuery } from 'service/helperFunctions'
import { orderReviewApi } from '../service/apiVariables'

import { userLocationType } from 'service/actionType'

// create customer
export const AddReview = (body) => (
  dispatch,
  getState,
  { api, Toast },
) => {
  return new Promise((resolve, reject) => {
    api({ ...orderReviewApi.reviewAddApi, body })
      .then((response) => {
        resolve(response)
      })
      .catch(({ message }) => {
        reject(Toast({ type: 'error', message, time: 2000 }))
      })
  })
}
