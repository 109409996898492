import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cheflist from 'assets/images/cheflist.png'
import vegIcon from 'assets/images/veg.png'
import { NormalButton } from "component/common/NormalButton";
import OrderAvailable from "./OrderAvailable";
import OrderAdvanced from "./OrderAdvanced";
import OrderServedAvailable from"./OrderServedAvailable";
function CustomerOrder() {
  const [vegcategory, setvegcategory] = React.useState(false)
  return (
    <>
     <div className="customer_detail_inner_container">
          <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="header">Active orders</div>
                
              </div>
              <hr></hr>
          </div>
          <div id="order-custom" className="">
              <p className="fs-18 fw-700 text-gold mb-0"> Order ID: 12345</p>
               <div className="availble-advance-order">
                    <div className="row mt-2">
                                <div className="d-flex">
                                      <div className="bitesheading col-6 line-height-36 fs-18 fw-700 text-primary-color mb-2 ">
                                        Available Now
                                      </div>
                                      <div className="col-6 d-flex justify-content-end align-items-center">
                                       <div className="fs-14 d-flex align-items-center text-gold-tt title-order-block  p-2   bg-yellow-C3">
                                           Getting prepared
                                       </div>
                                      </div>
                                </div>
                                
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-4 bg-white-f0 p-3 order-card ">
                                  <OrderAvailable/>
                              </div>
                    </div>
                    <div className="row">
                              <div className="d-flex">
                                      <div className="bitesheading col-6 line-height-36 fs-18 fw-700 text-primary-color mb-2 ">
                                      Advanced order
                                      </div>
                                      <div className="col-6 d-flex justify-content-end align-items-center">
                                        <div className="d-flex fs-14 text-secondary-color  title-order-block justify-content-end p-2  align-items-center bg-white-de">
                                            Will be prepared soon
                                        </div>
                                      </div>
                                </div>
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-4 bg-white  p-3 order-card ">
                                  <OrderAdvanced/>
                              </div>
                    </div>
              </div>
          </div>
    </div>
    <div className="customer_detail_inner_container mt-5">
          <div>
              <div className="d-flex align-items-center justify-content-between">
                  <div className="header">Order History</div>
              </div>
              <hr></hr>
          </div>
          <div id="order-custom" className="">
              <div className="d-flex">
                                      <div className="bitesheading col-6 line-height-36 fs-18 fw-700 text-gold mb-2 ">
                                           Order ID: 12345
                                      </div>
                                      <div className="col-6 d-flex justify-content-end align-items-center">
                                       <div className="d-flex fs-14 text-green-C3  title-order-block justify-content-end p-2   align-items-center bg-white-de">
                                         Delivered
                                       </div>
                                      </div>
                </div>
               <div className="availble-advance-order">
                    <div className="row mt-2">
                              <div className="col-xs-12 col-sm-12 col-lg-12 mb-2 bg-grey-f0 p-3 ">
                                  <OrderServedAvailable/>
                              </div>
                    </div>
              </div>
          </div>
    </div>
    </>
  )
}

// export default CustomerOrder;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(CustomerOrder)
