export const validationRules = () => {
  let passwordValidation = {
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_#%*?&])[A-Za-z\d@_#$!%*?&]*$/,
      flags: 'i',
      message:
        '^Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
    },
  }
  return {
    email: {
      presence: {
        allowEmpty: false,
        message: '^Email is required',
      },
      format: {
        pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
        flags: 'i',
        message: '^Email id is incorrect',
      },
    },
    firstName: {
      presence: {
        allowEmpty: false,
        message: '^ First name is required',
      },
      format: {
        pattern: /^[a-zA-z ]*$/,
        flags: 'i',
        message: 'must contain only alphabets and spaces',
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: '^ Last name is required',
      },
      format: {
        pattern: /^[a-zA-z ]*$/,
        flags: 'i',
        message: 'must contain only alphabets and spaces',
      },
    },
    mobileNumber: {
      presence: {
        allowEmpty: false,
        message: '^Mobile number is required',
      },
      format: {
        pattern: /^[0-9]*$/,
        flags: 'i',
        message: '^Please enter valid mobile number',
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: '^Password is required',
      },
      ...passwordValidation,
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: '^Confirm password is required',
      },
      equality: {
        attribute: 'password',
        message: '^Both password must match',
        comparator: function (v1, v2) {
          return JSON.stringify(v1) === JSON.stringify(v2)
        },
      },
    },
  }
}
