import React, { useState, useEffect, useRef } from 'react'

import {
  CommonPageLoader,
  NormalButton,
  NormalDate,
  PageIcons,
} from 'component/common'
import BasicDetail from './BasicDetail/BasicDetail'
import CustomerOrder from './CustomerOrder'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SideBar from './sideBar'
// import UnderTrial from './userTrial'
import { getSingle, chefEditUpdate } from 'action/ChefDetailsAct'
import { getCustomerById } from 'action/CustomerDetailsAct'
import SimpleReactValidator from 'simple-react-validator'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import { fileUpload } from 'action/FileUpload'
import logo from 'assets/images/alertLogo.png'
import './BasicDetail/styles.scss'
import { Toast } from 'service/toast'

function CustomerComponent({
  getSingle,
  fileUpload,
  chefEditUpdate,
  getCustomerById,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState()
  const [active, setActive] = useState('basicDetail')

  useEffect(() => {
    fetchChefDetails()
  }, [])
  const fetchChefDetails = async () => {
    let id = localStorage.getItem('userId')
    let userDetails = JSON.parse(localStorage.getItem('userDetailsWeb'))
    let body = {
      userTypeRole: userDetails.userTypeRole,
      id: localStorage.getItem('userId'),
    }
    setIsLoading(true)
    await getCustomerById(body)
      .then(({ data }) => {
        if (!!data) {
          // if (data.status === 'SUSPENDED') {
          //   data.LicenseDetails = {
          //     licenseNumber: '',
          //     licenseExpiryDate: null,
          //     licenseFileUrl: '',
          //     adminApproved: false,
          //     isEdit: false,
          //   }
          //   setFormData(data)
          //   setIsLicense(true)
          // }
          data.isEdit = false
          setFormData(data)
          setIsLoading(false)
        }
      })
      .catch((e) => setIsLoading(false))
  }

  return (
    <section>
      {/* {isTrial && <UnderTrial />} */}
      <div className="container-fluid container-lg py-4 minimum-height">
        {!isLoading ? (
          <div className="row">
            <div className="col-md-3">
              <SideBar active={active} setActive={setActive} data={formData} />
            </div>
            <div className="col-md-9 ">
              {active === 'basicDetail' ? (
                <BasicDetail
                  active={active}
                  dataFromParent={formData}
                  fetchChefDetailsParent={fetchChefDetails}
                />
              ) : active === 'customerOrder' ? (
                <>
                  <CustomerOrder />
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <CommonPageLoader />
        )}
      </div>
      {!isLoading && <PageIcons />}
    </section>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getSingle, fileUpload, chefEditUpdate, getCustomerById },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(CustomerComponent)
