import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import './date.scss'
import iconImg from 'assets/svg/calender.svg'
// import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

export class NormalDate extends Component {
  openDatepicker = () => this._calendar.setOpen(true)
  render() {
    let {
      className = 'form-control w-100',
      placeholder = 'DD/MM/YY',
      onChange,
      value = '',
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = false,
      showMonthDropdown = false,
      maxdate = 'false',
      minDate = null,
      timeInput = false,
      label = '',
      isIcon = false,
      showTimeSelect = false,
      timeIntervals = 30,
    } = this.props
    console.log(value)
    return (
      <>
        {!showTimeSelect ? (
          <div className="normal-date-input">
            {label !== '' ? (
              <div className="fs-16 text-black font-Gilroy pb-2 mb-1 line-height-20">
                {label}
              </div>
            ) : (
              ''
            )}
            {!isReadOnly ? (
              <div className="position-relative">
                <DatePicker
                  ref={(c) => (this._calendar = c)}
                  calendarIcon={true}
                  timeInputLabel={timeInput && 'Time:'}
                  showTimeInput={timeInput}
                  className={className}
                  disabled={disabled}
                  placeholderText={placeholder}
                  selected={value}
                  showYearPicker={showYearPicker}
                  showMonthYearPicker={showMonthYearPickerStatus}
                  showFullMonthYearPicker={showFullMonthYearPickerStatus}
                  showMonthDropdown={showMonthDropdown}
                  showYearDropdown={showYearDropdown}
                  scrollableYearDropdown={showYearDropdown}
                  dateFormat={'dd/MM/yyyy'}
                  // maxDate={maxdate && new Date()}
                  minDate={minDate}
                  autoComplete="off"
                  onChange={(date) => {
                    let body = {}

                    body = {
                      target: {
                        name: name,
                        value: date,
                      },
                    }

                    onChange(body)
                  }}
                  name={name}
                />
                {isIcon ? (
                  <img
                    className="calender-icon"
                    src={iconImg}
                    onClick={this.openDatepicker}
                  />
                ) : (
                  ''
                )}
              </div>
            ) : (
              <p className="fw-700">{moment(value).format('DD/MM/YYYY')}</p>
            )}
          </div>
        ) : (
          <div className="normal-date-input">
            {label !== '' ? (
              <div className="fs-14 text-black font-Gilroy pb-2 mb-1 line-height-20">
                {label}
              </div>
            ) : (
              ''
            )}
            {!isReadOnly ? (
              <DatePicker
                filterTime={this.filterPassedTime}
                showTimeSelect={showTimeSelect}
                timeIntervals={timeIntervals}
                showTimeSelectOnly
                l={false}
                // timeInputLabel={timeInput && "Time:"}
                // showTimeInput={timeInput}
                className={className}
                disabled={disabled}
                placeholderText={placeholder}
                selected={value}
                // showYearPicker={showYearPicker}
                // showMonthYearPicker={showMonthYearPickerStatus}
                // showFullMonthYearPicker={showFullMonthYearPickerStatus}
                // showMonthDropdown={showMonthDropdown}
                // showYearDropdown={showYearDropdown}
                dateFormat="h:mm aa"
                // maxDate={maxdate && new Date()}
                // minDate={mindate && new Date()}
                autoComplete="off"
                onChange={(date) => {
                  let body = {}

                  body = {
                    target: {
                      name: name,
                      value: date,
                    },
                  }

                  onChange(body)
                }}
                name={name}
              />
            ) : (
              <p>{value}</p>
            )}
          </div>
        )}
      </>
    )
  }
}
