import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { HomeComp } from "component/Home/Home";
export class HomeClass extends Component {
  render() {
    return <HomeComp />;
  }
}
export const Home = connect(null, null)(HomeClass);
