import {
  chefDataType,
  notificationType,
  userLocationType,
} from 'service/actionType'

const initialState = {
  chefData: {},
  notificationListData: {},
  userLocation: {},
  currentPageChef: 1,
  currentPageMenu: 1,
}
const commonReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case chefDataType.chefData:
      return {
        ...state,
        chefData: action.payload,
      }
    case notificationType.notificationListData:
      return {
        ...state,
        notificationListData: action.payload,
      }
    case userLocationType.userLocation:
      return {
        ...state,
        userLocation: action.payload,
      }
    case userLocationType.currentPageChef:
      return {
        ...state,
        currentPageChef: action.payload,
      }
    case userLocationType.currentPageMenu:
      return {
        ...state,
        currentPageMenu: action.payload,
      }
    default:
      return state
  }
}

export default commonReducer
