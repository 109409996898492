import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import CartComp from '../../component/Cart/index'

export class CartSummaryClass extends Component {
  render() {
    return <CartComp />
  }
}
export const CartSummary = connect(null, null)(CartSummaryClass)
