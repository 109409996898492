import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getChefDetails } from "action/ChefDetailsAct";
import { filterCuisine } from "action/ChefDetailsAct";
import { bindActionCreators } from "redux";
import { NormalButton } from "component/common/NormalButton";
import { MultiSelect, PageIcons } from "component/common";
import crossIcon from "../../assets/svg/Cross.svg";
import "./style.scss";
import { history } from "service/helpers";
import { Pagination } from "component/common/Pagination";
import { Dropdown } from "reactstrap";
import egg from "../../assets/images/egg.png";
import veg from "../../assets/images/veg.png";
import nonveg from "../../assets/images/nonveg.png";
import { Navbar } from "component/common/Navbar";
import { useLocation } from "react-router-dom";
import { CommonPageLoader } from "component/common";
import { convertToSlug } from "service/helperFunctions";
import { getCusineList } from "action/ChefDetailsAct";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const UserDetailClass = (props) => {
  const [chefDetails, setChefDetails] = useState([]);
  const [showCausineFilter, setShowCausineFilter] = useState(false);
  const [selectedCusine, setSelectedCusine] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [cuisineList, setcuisineList] = useState([]);
  const [pageMeta, setPageMeta] = useState(null);

  //life cycle
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let queryValue = useQuery();

  useEffect(() => {
    fetchCusineList();
    if (props.currentPageChef) {
      getChefDetailsByApiCallFunc(props.currentPageChef);
    } else {
      getChefDetailsByApiCallFunc();
    }
  }, [
    queryValue.get("city"),
    queryValue.get("area"),
    queryValue.get("state"),
    queryValue.get("country"),
  ]);
  const handleCusineFilter = () => {
    setShowCausineFilter(!showCausineFilter);
  };
  // get all cuisines
  const fetchCusineList = async () => {
    let id = localStorage.getItem("userId");
    setIsLoading(true);
    await props
      .getCusineList()
      .then(({ data }) => {
        // setIsLoading(false)
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch((e) => setIsLoading(false));
  };
  const getChefDetailsByApiCallFunc = (page = 1) => {
    console.log(queryValue.get("area"), "testarea");
    let query = {
      country: queryValue.get("country") ? queryValue.get("country") : "",
      city: queryValue.get("city") ? queryValue.get("city") : "",
      area:
        queryValue.get("area") === queryValue.get("city")
          ? ""
          : queryValue.get("area"),
      state: queryValue.get("state") ? queryValue.get("state") : "",
      page,
    };
    setIsLoading(true);
    props
      .getChefDetailsApi(query)
      .then((response) => {
        let { data } = response;
        if (Array.isArray(data.list)) {
          setChefDetails(data.list);
          setPageMeta(data.pageMeta);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // let uniqueCuisineLoc = [...new Set(array)]
  let uniqueCuisineLoc = cuisineList;
  const handleCausine = (name, page = 1) => {
    var cusine = name;
    setSelectedCusine(cusine);

    if (name.length == 0) {
      getChefDetailsByApiCallFunc();
    } else {
      let body = {
        filters: {
          cuisine: cusine,
          //city: queryValue.get("city") ? [queryValue.get("city")] : [],
          //country: queryValue.get("country") ? [queryValue.get("country")] : [],
          city: queryValue.get("city") ? queryValue.get("city") : [],
          country: queryValue.get("country") ? queryValue.get("country") : [],
        },
      };
      let query = {
        page,
      };
      setIsLoading(true);
      props
        .filterCuisineApi(body, query)
        .then((data) => {
          //setChefDetails(data);
          if (Array.isArray(data?.chefDetails)) {
            setChefDetails(data?.chefDetails);
            setPageMeta(data?.pageMeta);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const handlecancel = (list, page = 1) => {
    const filterData = selectedCusine.filter((v) => v !== list);
    setSelectedCusine([...filterData]);
    if (filterData.length === 0) {
      getChefDetailsByApiCallFunc();
    } else {
      let body = {
        filters: {
          cuisine: filterData,
          // city: queryValue.get("city") ? [queryValue.get("city")] : [],
          city: queryValue.get("city") ? queryValue.get("city") : [],
          country: queryValue.get("country") ? queryValue.get("country") : [],
        },
      };
      let query = {
        page,
      };
      setIsLoading(true);
      props
        .filterCuisineApi(body, query)
        .then((data) => {
          //setChefDetails(data);
          if (Array.isArray(data?.chefDetails)) {
            setChefDetails(data?.chefDetails);
            setPageMeta(data?.pageMeta);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const handleChef = (list) => {
    let chefdetail = {
      _id: list._id,
      name: list.firstName,
      age: list.age,
      lastName: list.lastName,
      chefImage: list.chefImage,
      cuisineDetails: list.cuisine,
      AboutDetails: list.AboutDetails,
      LicenseNumberDetails: list.LicenseNumberDetails,
      deactivateId: list.deactivateId,
      preference: list.preference,
      facebook: list.facebook,
      instagram: list.instagram,
      status: list.status,
      email: list.email,
      mobileNumber: list.mobileNumber,
      availabilityStatus: list.availabilityStatus,
      AddressDetails: list.AddressDetails,
      businessName: list.businessName,
    };

    localStorage.setItem("chefdetail", JSON.stringify(chefdetail));
    history.push(`/${convertToSlug(list.businessNameSlug)}`);
  };
  const token = localStorage.getItem("authToken");

  const handlePagination = (page) => {
    if (selectedCusine?.length) {
      handleCausine(selectedCusine, page);
    } else {
      getChefDetailsByApiCallFunc(page);
    }
  };
  return (
    <div className="user position-relative">
      <Navbar />
      <div className="container">
        <Dropdown isOpen={showCausineFilter} toggle={handleCusineFilter}>
          <div className="d-flex flex-wrap pt-4 mb-3 pb-3">
            <NormalButton
              // disabled={chefDetails.length === 0}
              optionButton
              label="Cuisine"
              className=""
              isArrow={true}
              onClick={handleCusineFilter}
            />

            {selectedCusine &&
              selectedCusine.map((list) => (
                <div
                  className="bg-yellow-color col-2  filter_data   "
                  onClick={() => handlecancel(list)}
                >
                  <div className="filter_data_applied">
                    <span className="text-white-FF line-height-18 fs-16 fw-700">
                      {list}
                    </span>
                    <img src={crossIcon} alt="" />
                  </div>
                </div>
              ))}
            {/* <Dropdown isOpen={showCausineFilter} toggle={handleCusineFilter}> */}
            {/* <div> */}
            {showCausineFilter && (
              <MultiSelect
                handleCausine={handleCausine}
                uniqueCuisineLoc={uniqueCuisineLoc}
                setShowCausineFilter={setShowCausineFilter}
                selectedCusine={selectedCusine}
              />
            )}
            {/* </div> */}
          </div>
        </Dropdown>
        {!isLoading && (
          <div className="fs-16">
            {pageMeta?.totalCount} Result{pageMeta?.totalCount > 1 ? "s" : ""}{" "}
            found for{" "}
            {queryValue.get("area")
              ? queryValue.get("area") === queryValue.get("city")
                ? queryValue.get("")
                : queryValue.get("area")
              : queryValue.get("city")
              ? queryValue.get("city")
              : queryValue.get("state")
              ? queryValue.get("state")
              : queryValue.get("country")
              ? queryValue.get("country")
              : ""}{" "}
            location
          </div>
        )}
      </div>
      <div className="container mt-4 pb-4 ">
        {!isLoading ? (
          chefDetails.length > 0 ? (
            <div className="row">
              {chefDetails.map((list, index) => {
                return (
                  <React.Fragment key={index}>
                    {!list.availabilityStatus && (
                      <div className="col-md-6 col-12 mb-md-4 mb-3 cursor-pointer ">
                        <div className="card deactivate bg-white mobile-media media p-3 position-relative">
                          {/* <div className="card  media p-3"> */}
                          <div className="position-absolute blur-certfication">
                            <p className="blur mb-0">
                              This Chef is currently Unavailable .
                            </p>
                          </div>
                          <div className="d-flex mb-1 chefimage">
                            <div className="flex-shrink-0">
                              <img src={list.chefImage} alt="" />
                            </div>
                            <div className="flex-grow-1 ms-3 fs-18 fw-700 overflow-hidden">
                              <div className="row space">
                                <p className="col-12 col-md-8 mb-0 fs-16 text-black-25">
                                  {list.businessName}
                                </p>
                                <div className="col-12 col-md-8">
                                  <div className=" mb-0">
                                    {/* {list.preference[0] ||
                                          list.preference[1] ||
                                          (list.preference[2] === "Veg" && (
                                            <img src={veg} alt="" />
                                          ))}
                                        {list.preference[0] ||
                                          list.preference[1] ||
                                          (list.preference[2] === "NonVeg" && (
                                            <img src={nonveg} alt="" />
                                          ))}
                                        {list.preference[0] ||
                                          list.preference[1] ||
                                          (list.preference[2] ===
                                            "Eggetarian" && (
                                            <img src={egg} alt="" />
                                          ))} */}
                                  </div>
                                </div>
                              </div>
                              <div className="fs-16 fw-700 text-secondary-color">
                                <div className="d-block dd">
                                  {/* {list.cuisine.join(", ")} */}
                                  {list.cuisine.join(", ").length < 30 ? (
                                    list.cuisine.join(", ")
                                  ) : (
                                    <React.Fragment>
                                      {list.cuisine.join(", ").substring(0, 29)}{" "}
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 200, hide: 300 }}
                                        overlay={
                                          <Tooltip>
                                            {list.cuisine.join(", ")}
                                          </Tooltip>
                                        }
                                        containerPadding={20}
                                      >
                                        <span className="text-yellow-C1 cursor-pointer fs-14 fw-500">
                                          ...Read more
                                        </span>
                                      </OverlayTrigger>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              {!!token ? (
                                <div className="fs-14 fw-400 text-grey-7c addressrespon mt-1 line-height-16">
                                  {list.AddressDetails &&
                                    list.AddressDetails.address1}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.address2}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.city}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.area}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.country}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.state}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.pincode}
                                </div>
                              ) : (
                                <p className="fw-700 fs-16 text-grey-b3 mt-2 mb-0">
                                  Please login to view chef details
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {list.islicensedExpried && (
                      <div className="col-md-6 col-12 mb-md-4 mb-3 position-relative p-0">
                        <div className="position-absolute blur-certfication media ">
                          <p className="blur mb-0">
                            This Chef’s License has been expired.
                          </p>
                        </div>
                        <div className="card deactivate bg-white mobile-media media p-3">
                          <div className="d-flex mb-1">
                            <div className="flex-shrink-0 chefimage">
                              <img src={list.chefImage} alt="" />
                            </div>
                            <div className="flex-grow-1 ms-3 fs-18 fw-700 overflow-hidden">
                              <div className="row space">
                                <p className="col-12 col-md-8 mb-0 fs-16 text-black-25">
                                  {list.businessName}
                                </p>
                                <div className="col-12 col-md-4 mb-0"> */}
                    {/* {list.preference[0] ||
                                        list.preference[1] ||
                                        (list.preference[2] === 'Veg' && (
                                          <img src={veg} alt="" />
                                        ))}
                                      {list.preference[0] ||
                                        list.preference[1] ||
                                        (list.preference[2] === 'NonVeg' && (
                                          <img src={nonveg} alt="" />
                                        ))}
                                      {list.preference[0] ||
                                        list.preference[1] ||
                                        (list.preference[2] ===
                                          'Eggetarian' && (
                                          <img src={egg} alt="" />
                                        ))} */}
                    {/* </div>
                              </div>
                              <div className="fs-16 fw-700 text-secondary-color">
                                <div className="d-block dd">
                                  {list.cuisine.join(", ").length < 30 ? (
                                    list.cuisine.join(", ")
                                  ) : (
                                    <React.Fragment>
                                      {list.cuisine.join(", ").substring(0, 29)}{" "}
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 200, hide: 300 }}
                                        overlay={
                                          <Tooltip>
                                            {list.cuisine.join(", ")}
                                          </Tooltip>
                                        }
                                        containerPadding={20}
                                      >
                                        <span className="text-yellow-C1 cursor-pointer fs-14 fw-500">
                                          ...Read more
                                        </span>
                                      </OverlayTrigger>
                                    </React.Fragment>
                                  )}
                                </div>
                              </div>
                              {!!token ? (
                                <div className="fs-14 fw-400 text-grey-7c addressrespon mt-1 line-height-16">
                                  {list.AddressDetails &&
                                    list.AddressDetails.address1}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.address2}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.city}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.area}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.country}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.state}
                                  ,{" "}
                                  {list.AddressDetails &&
                                    list.AddressDetails.pincode}
                                </div>
                              ) : (
                                <p className="fw-700 fs-16 text-grey-b3 mt-2 mb-0">
                                  Please login to view chef details
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {list.availabilityStatus &&
                      !list.deactivateId &&
                      !list.islicensedExpried && (
                        <div
                          className="col-md-6 col-12 mb-md-4 mb-3 cursor-pointer position-relative"
                          key={index}
                          onClick={() => handleChef(list)}
                        >
                          <div className="card bg-white mobile-media media p-3">
                            <div className="d-flex mb-1">
                              <div className="flex-shrink-0 chefimage">
                                <img src={list.chefImage} alt="" />
                              </div>
                              <div className="flex-grow-1 ms-3 fs-18 fw-700 overflow-hidden">
                                <div className="row space">
                                  <p className="col-12 col-md-8 mb-0 fs-16 text-black-25">
                                    {list.businessName}
                                  </p>
                                  <div className="col-12 col-md-4">
                                    <div className=" mb-0">
                                      {/* {(list.preference[0] ||
                                          list.preference[1] ||
                                          list.preference[2]) === 'Veg' && (
                                          <img src={veg} alt="" />
                                        )}
                                      </div>
                                      <div className="mb-0">
                                        {(list.preference[0] ||
                                          list.preference[1] ||
                                          list.preference[2]) === 'NonVeg' && (
                                          <img src={nonveg} alt="" />
                                        )}
                                      </div>
                                      <div className="mb-0">
                                        {(list.preference[0] ||
                                          list.preference[1] ||
                                          list.preference[2]) ===
                                          'Eggetarian' && (
                                          <img src={egg} alt="" />
                                        )} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="fs-16 fw-700 text-secondary-color">
                                  <div className="d-block">
                                    {/* {list.cuisine.join(", ")} */}
                                    {list.cuisine.join(", ").length < 30 ? (
                                      list.cuisine.join(", ")
                                    ) : (
                                      <React.Fragment>
                                        {list.cuisine
                                          .join(", ")
                                          .substring(0, 29)}{" "}
                                        <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 200, hide: 300 }}
                                          overlay={
                                            <Tooltip>
                                              {list.cuisine.join(", ")}
                                            </Tooltip>
                                          }
                                          containerPadding={20}
                                        >
                                          <span className="text-yellow-C1 cursor-pointer fs-14 fw-500">
                                            ...Read more
                                          </span>
                                        </OverlayTrigger>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                                {!!token ? (
                                  <div className="fs-14 fw-400 text-grey-7c addressrespon mt-1 line-height-16">
                                    {list.AddressDetails &&
                                      list.AddressDetails.address1}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.address2}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.city}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.area}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.country}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.state}
                                    ,{" "}
                                    {list.AddressDetails &&
                                      list.AddressDetails.pincode}
                                  </div>
                                ) : (
                                  <p className="fw-700 fs-16 text-grey-b3 mt-2 mb-0">
                                    Please login to view chef details
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div className="col-12 mt-3 pt-5">
              <p className="text-center fs-16 fw-600 text-black-1c">
                No data found !!!
              </p>
            </div>
          )
        ) : (
          <CommonPageLoader />
        )}

        <div className="">
          {pageMeta ? (
            <Pagination handleChange={handlePagination} pageMeta={pageMeta} />
          ) : null}
        </div>
        {/* <PageIcons /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPageChef: state.commonStore.currentPageChef,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getChefDetailsApi: getChefDetails,
      filterCuisineApi: filterCuisine,
      getCusineList,
    },
    dispatch
  );
};

let component = UserDetailClass;
export const UserDetailComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
