import slugify from "slugify";
//add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = ["page", "limit", "city", "area", "state", "country"];

  keys.map((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).map((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

//generate Query
export const generateQuery = (query) => {
  let url = "";

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }
  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] == "" ||
        query[key] == null ||
        key == "url_id" ||
        (query[key] !== null && query[key].toString().trim() == "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index != 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

//get User Details
export const getUserDetails = () => {
  let userDetails = {};
  if (
    localStorage.getItem("authToken") &&
    localStorage.getItem("userDetailsWeb")
  ) {
    userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  }
  return userDetails;
};
// get address
export const extractAddressFields = (components) => {
  var formatted = {
    state: "",
    street: "",
    pincode: "",
    area: "",
    city: "",
    country: "",
    region: "",
  };
  console.log(components, "components");
  components.forEach((element) => {
    if (element.types.indexOf("administrative_area_level_1") !== -1) {
      formatted.state = element.long_name;
    }
    if (element.types.indexOf("country") !== -1) {
      formatted.country = element.short_name;
    }
    if (element.types.indexOf("country") !== -1) {
      formatted.region = element.long_name;
    }
    if (element.types.indexOf("locality") !== -1) {
      formatted.city = element.long_name;
    }
    // if (element.types.indexOf("administrative_area_level_2") !== -1) {
    //   formatted.city = formatted.city ? formatted.city : element.long_name;
    // }
    if (element.types.indexOf("administrative_area_level_2") !== -1) {
      formatted.city = element.long_name;
    }

    if (
      element.types.indexOf("sublocality") !== -1 &&
      element.types.indexOf("sublocality_level_1")
    ) {
      formatted.area = element.long_name;
    }
    if (element.types.indexOf("locality") !== -1) {
      formatted.area = formatted.area ? formatted.area : element.long_name;
    }
    if (element.types.indexOf("postal_code") !== -1) {
      formatted.pincode = element.long_name;
    }
    if (element.types.indexOf("street_number") !== -1) {
      formatted.street = element.long_name;
    }
    if (element.types.indexOf("route") !== -1) {
      formatted.street += `${formatted.street ? ", " : ""}${element.long_name}`;
    }
    if (
      element.types.indexOf("sublocality") !== -1 &&
      element.types.indexOf("sublocality_level_2") !== -1
    ) {
      formatted.street += `${formatted.street ? ", " : ""}${element.long_name}`;
    }
  });
  return formatted;
};

// get slug names
export const convertToSlug = (name) => {
  return slugify(name, {
    replacement: "-", // replace spaces with replacement character, defaults to -
    remove: undefined, // remove characters that match regex, defaults to undefined
    lower: true, // convert to lower case, defaults to false
    strict: true, // strip special characters except replacement, defaults to false
    locale: "vi", // language code of the locale to use
  });
};

export const handleNameField = (data) => {
  if (data) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  } else {
    return data;
  }
};
