import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton, Marker } from "component/common";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefEditUpdate } from "action/ChefDetailsAct";
import * as L from "leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";
import { Toast } from "service/toast";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import markerIcon from "assets/svg/marker.svg";

import GoogleMapReact from "google-map-react";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import currentLocation from "assets/svg/currentLocation.svg";

// setup
// const provider = new OpenStreetMapProvider();

// search
// const results = async(input)=> await provider.search({ query: input.value });
// const results = await provider.search({ query: input.value });
const provider = new OpenStreetMapProvider();

function AddressDetails({
  chefEditUpdate,
  formData,
  setFormData,
  setFormDataAddress,
}) {
  const [updateValidator, setUpdateValidator] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [getLocationLoader, setGetLocationLoader] = useState(false);
  const [address, setAddress] = useState("");
  const [defaultZoom, setDefaultZoom] = useState(18);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  useEffect(() => {
    // var theMarker = {};
    // let { AddressDetails } = formData
    // var latlng = AddressDetails.location ?
    //   L.latLng(Number(AddressDetails.location.lat), Number(AddressDetails.location.long)) :
    //   L.latLng(13.0827, 80.2707);
    // var map = L.map('map').setView([latlng.lat, latlng.lng], 13);
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    // }).addTo(map);
    // var marker = L.marker(latlng, {
    //   draggable: false,
    //   autoPan: true,
    //   // icon: myIcon
    // }).addTo(map);
    // const searchControl = new GeoSearchControl({
    //   style: 'button',
    //   notFoundMessage: 'Sorry, that address could not be found.',
    //   searchLabel: 'Enter address',
    //   autoClose: true,
    //   provider: new OpenStreetMapProvider(),
    //   showMarker: true,
    //   marker: marker,
    // });
    // map.on('geosearch/showlocation', function (result) {
    //   console.log(result)
    //   // let { location } = result;
    //   let lat = result.location.y;
    //   let lng = result.location.x;
    //   if (marker) {
    //     // map.removeControl(marker);
    //   }
    //   let { AddressDetails } = { ...formData };
    //   AddressDetails["location"] = {
    //     "lat": String(lat),
    //     "long": String(lng)
    //   };
    //   setFormData({ ...formData, AddressDetails });
    //   marker.setLatLng([lat, lng])
    // });
    // map.on('click', function (e) {
    //   let lat = e.latlng.lat;
    //   let lng = e.latlng.lng;
    //   console.log("You clicked the map at LAT: " + lat + " and LONG: " + lng);
    //   let { AddressDetails } = { ...formData };
    //   AddressDetails["location"] = {
    //     "lat": String(lat),
    //     "long": String(lng)
    //   };
    //   setFormData({ ...formData, AddressDetails });
    //   marker.setLatLng([lat, lng]);
    // });
    // map.addControl(searchControl);
    // const form = document.querySelector('form');
    // const input = form.querySelector('input[type="text"]');
    // form.addEventListener('submit', async (event) => {
    //   event.preventDefault();
    //   console.log(input.value)
    //   provider.search({ query: '...' }).then(function (result) {
    //     console.log(result); // » [{}, {}, {}, ...]
    //   });
    // });
  }, []);
  const submitAddressDetails = async () => {
    formData["masterChefId"] = localStorage.getItem("userId");
    formData["isNewRequest"] = false;

    delete formData["_id"];
    delete formData["__v"];
    if (validator.current.allValid()) {
      setIsLoading(true);
      await chefEditUpdate(formData)
        .then(() => {
          setIsLoading(false);
          setIsEdit(false);
        })
        .catch((e) => setIsLoading(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please fill up all the required fields",
        time: 3000,
      });
      scrollTop();
    }
  };
  var optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    setGetLocationLoader(true);
    setAddress("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        optionsPosition
      );
      setDefaultZoom(12);
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
        time: 2000,
      });
    }
  };
  const showPosition = (position) => {
    console.log(position, "newww currrrrt");
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: lat,
      long: lon,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    // this.timer = setTimeout(() => {
    navigatorGeoLocation(lat, lon).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      console.log(address);
      handleLocationGoogle(address);
      setGetLocationLoader(false);
    });
    // }, 100)
    // this.displayLocation(lat, lon)
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };
  const handleLatLng = (data) => {
    console.log(data, "data");
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: data.lat,
      long: data.lng,
    };

    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
  };
  const handleMApClick = ({ lat, lng }) => {
    // this.timer = setTimeout(() => {
    let userLocation = navigatorGeoLocation(lat, lng).then((response) => {
      let data = response.results;
      console.log("from googel", data);
      let address = extractAddressFields(data[0].address_components);
      console.log(address);
      handleLocationGoogle(address);
    });
    console.log("navvvv", userLocation);
    // }, 100)
  };
  const handleLocationGoogle = (e) => {
    console.log(e, "eeeeee");
    let { country, city, area, pincode, region, state, street } = e;
    let { AddressDetails } = formData;
    AddressDetails.country = region;
    AddressDetails.city = city;
    AddressDetails.area = area;
    AddressDetails.pincode = pincode;
    AddressDetails.state = state;
    AddressDetails.address1 = street;
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    scrollTop();
  };
  const scrollTop = () => {
    let element = document.getElementById("Address");
    console.log(element, "element");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  let {
    AddressDetails: { location },
  } = formData;
  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      // minZoom: 11,
      // maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },
      draggableCursor: "pointer",

      // zoomControl: false,
      // scrollwheel: false,
      // clickableIcons: false,
      // draggable: false,
    };
  };
  return (
    <div id="address-custom">
      <div className="address_detail_inner_container">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="header">Map</div>
            {!isEdit && (
              <NormalButton
                primary
                label="Edit"
                className="fw-700"
                disabled={isLoading}
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
          <hr></hr>
        </div>
        <div className="body">
          <div className="row">
            {/* <div className="col-12">
              <div className="header">Map</div>
              <hr />
            </div> */}
            <div className=" col-xs-12 col-sm-12 mb-5">
              <div id="map-outline" className="position-relative">
                <div id="map">
                  <GoogleMapReact
                    center={
                      location
                        ? {
                            lat: Number(location.lat),
                            lng: Number(location.long),
                          }
                        : {
                            lat: 13.0827,
                            lng: 80.2707,
                          }
                    }
                    zoomControl={isEdit}
                    scrollwheel={isEdit}
                    clickableIcons={false}
                    draggable={isEdit}
                    defaultZoom={18}
                    zoom={defaultZoom}
                    onClick={(e) => {
                      if (isEdit) {
                        let { lat, lng } = e;
                        if (isEdit) {
                          let { AddressDetails } = formData;
                          AddressDetails.location = {
                            lat: lat,
                            long: lng,
                          };
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                          handleMApClick(e);
                        }
                      } else {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.long}`
                        );
                      }
                      // let { lat, lng } = e;
                      // if (isEdit) {
                      //   let { AddressDetails } = formData;
                      //   AddressDetails.location = {
                      //     lat: lat,
                      //     long: lng,
                      //   };
                      //   setFormData((prevState) => ({
                      //     ...prevState,
                      //     AddressDetails,
                      //   }));
                      //   handleMApClick(e);
                      // }
                    }}
                    options={getMapOptions}
                  >
                    {location && (
                      <Marker
                        lat={location.lat}
                        lng={location.long}
                        name="My Marker"
                        color=""
                        isInfo={true}
                        infoWindowOpen={infoWindowOpen}
                        infoWindowContent={
                          <div className="info-outline-small d-flex justify-content-center">
                            <div className="">
                              <span className="text-red fs-12">
                                You are here!
                              </span>
                            </div>
                            <span className="rectang"></span>
                          </div>
                        }
                        children={
                          <img
                            src={markerIcon}
                            // onClick={() => {
                            //   setInfoWindowOpen(!infoWindowOpen)
                            // }}
                            alt="icon"
                          />
                        }
                      />
                    )}
                  </GoogleMapReact>
                  {isEdit && (
                    <LocationSearchInput
                      disabled={!isEdit}
                      isMap={"map"}
                      address={address}
                      handleAddress={(e) => handleLocationGoogle(e)}
                      placeholder=""
                      handleSelect={(address) => setAddress(address)}
                      handleLatLng={handleLatLng}
                      handleFind={() => {}}
                      handleCurrentLocation={() => getLocation()}
                      getLocationLoader={getLocationLoader}
                    />
                  )}
                  {isEdit && (
                    <div
                      id="custom-current-loaction"
                      className="verify d-flex align-items-center newEdit cursor-pointer "
                      onClick={() => getLocation()}
                      title="Current location"
                    >
                      <img
                        src={currentLocation}
                        alt="icons"
                        className={
                          getLocationLoader ? "opacity-5" : "opacity-1"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12" id="Address">
              <div className="header">Address Details</div>
              <hr />
            </div>
            <div className="col-xs-12 col-sm-4">
              <Form.Group>
                <Form.Label>
                  Country
                  {/* {isEdit && <sup>*</sup>} */}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Country" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.country = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.country}
                />
                {validator.current.message(
                  "Country",
                  formData.AddressDetails.country,
                  "required|min:3|alpha_space"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-4">
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter State" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.state = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.state}
                />
                {/* {validator.current.message(
                  'State',
                  formData.AddressDetails.state,
                  'required|alpha_space',
                )} */}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>Address line 1{isEdit && <sup>*</sup>}</Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address1 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address1}
                />
                {validator.current.message(
                  "Address",
                  formData.AddressDetails.address1,
                  "required"
                )}
              </Form.Group>
            </div>
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>Address line 2</Form.Label>
                <Form.Control
                  // disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Optional Address" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.address2 = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.address2}
                />
              </Form.Group>
            </div>
            <div className="col-sm-8">
              <Form.Group>
                <Form.Label>
                  Area
                  {/* {isEdit && <sup>*</sup>} */}
                </Form.Label>
                <Form.Control
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Area Name" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.area = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.area}
                />
                {/* {validator.current.message(
                  "Area",
                  formData.AddressDetails.area,
                  "required"
                )} */}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <Form.Group>
                <Form.Label>
                  City
                  {/* {isEdit && <sup>*</sup>} */}
                </Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  placeholder={`${isEdit ? "Enter City" : ""}`}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.city = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.city}
                />
                {validator.current.message(
                  "City",
                  formData.AddressDetails.city,
                  "required"
                )}
              </Form.Group>
            </div>

            <div className="col-xs-12 col-sm-4">
              <Form.Group>
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  disabled={true}
                  className={!isEdit ? "remove-height" : ""}
                  plaintext={isEdit ? false : true}
                  readOnly={isEdit ? false : true}
                  type="text"
                  autoComplete="off"
                  placeholder={`${isEdit ? "Enter Pin Code" : ""}`}
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { AddressDetails } = { ...formData };
                    AddressDetails.pincode = value;
                    setFormData((prevState) => ({
                      ...prevState,
                      AddressDetails,
                    }));
                  }}
                  value={formData.AddressDetails.pincode}
                />
                {/* {validator.current.message(
                  'Pincode',
                  formData.AddressDetails.pincode,
                  'required',
                )} */}
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      {isEdit && (
        <div className="d-flex mt-3">
          <NormalButton
            label="Cancel"
            className="white-btn mr-3"
            onClick={() => setIsEdit(false)}
          />
          <span className="m-2" />

          <NormalButton
            primary
            label="Save"
            className=""
            disabled={isLoading}
            onClick={() => submitAddressDetails()}
          />
        </div>
      )}
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefEditUpdate,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(AddressDetails);
