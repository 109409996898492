import React, { useEffect, useState } from 'react'
import bgimage from '../../../assets/images/Backround.png'
import pdfHead from '../../../assets/images/pdfHead.png'
import chefimage from '../../../assets/images/chefimage.png'
import pdfBg from '../../../assets/images/pdfBg.png'
import nonvegIcon from '../../../assets/images/nonveg.png'
import vegIcon from 'assets/images/veg.png'
import { CommonPageLoader, NormalButton } from 'component/common'
import getSymbolFromCurrency from 'currency-symbol-map'
import logo from 'assets/images/pdfLogo.png'
import * as jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import './style.scss'
import { history } from 'service/helpers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getMenuWithChef } from 'action/ChefDetailsAct'
import { convertToSlug } from 'service/helperFunctions'
import { FRONT_END_PORT } from 'utils/constants'

function ChefPdf(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    console.log(history)
    console.log(props.chefId)
    // fetchChefDetails()
  }, [])
  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }
  const fetchChefDetails = async () => {
    // let id = query.get('chefId')
    let id = props.chefId
    setIsLoading(true)
    await props
      .getMenuWithChef(id)
      .then(({ data }) => {
        if (!!data) {
          setData(data)
          let {
            chefDetails = {},
            AvailableDishList = [],
            AdvanceOrderList = [],
          } = data
          console.log(chefDetails.chefImage)
        }
        setIsLoading(false)
      })
      .then(() => {
        const timer = setTimeout(() => {
          // handleGeneratePdf()
        }, 500)
      })
      .catch((e) => setIsLoading(false))
  }
  const handleGeneratePdf = () => {
    console.log('pdf funct')

    const printArea = document.getElementById('pdfChef')
    console.log(printArea)
    var newTab = window.open()
    const pdf = new jsPDF()
    html2canvas(printArea)
      .then((canvas) => {
        const dataURL = canvas.toDataURL()

        pdf.addImage(dataURL, 'JPEG', 0, 0, 0, 0)

        pdf.save('saved.pdf')
      })
      .then(() => {
        // window.history.replaceState({}, '', '/')
        // window.location.reload()
        // window.blur()
        if (props.type === 'chef') {
          // history.push(`/index/activeProfile`)
        } else {
          let { chefDetails } = data
          // history.push(
          //   `/index/chef/${chefDetails?.businessName}`,
          // )
        }
        // newTab.location.href = pdf.output('dataurlstring')
        // newTab.document.close()
      })
    // window.close()
  }

  let { chefDetails = {}, AvailableDishList = [], AdvanceOrderList = [] } = data
  console.log(chefDetails.chefImage)
  let imageUrl = chefDetails.chefImage

  var divImage = {
    width: '96px',
    height: '104px',
    // backgroundImage: 'url(' + imageUrl + ');',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }
  const printPageArea = () => {
    window.print()
    document.margin = 'none'
  }
  console.log(chefDetails)
  return (
    <div className="section-to-print d-flex flex-column justify-content-center my-3 container">
      {/* <div className="pb-3 text-end">
        <NormalButton
          label="Back"
          className="white-btn mr-3"
          onClick={() => {
            if (props.type === 'chef') {
              history.push(`/index/activeProfile`)
            } else {
              let { chefDetails } = data
              history.push(`/chef/${chefDetails?.businessName}`)
            }
          }}
        />
        <span className="p-3" />
        <NormalButton
          primary
          label={'Print'}
          className=""
          disabled={isLoading}
          onClick={() => {
            printPageArea()
          }}
        />
      </div> */}
      {!isLoading ? (
        <div
          id="pdfChef"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            width: '100%',
          }}
        >
          <div
            id="pdfChef-head"
            className="graph-image graph-7"
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: '60% auto',
              width: '100%',
              backgroundPosition: 'right bottom',
            }}
          >
            <div id="pageHeader">
              {/* <img src={pdfBg} className="newAlter" alt="image" /> */}
            </div>
          </div>

          <div id="padding-for-page">
            <div
              id="pdfChef-wraper"
              style={{
                // backgroundImage: `url(${bgimage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                // padding: '10px',
                // visibility: `${props.printLoader ? 'visible' : 'hidden'}`,
                width: '100%',
              }}
            >
              <div id="pageHeader">
                {/* <img src={pdfHead} className="newAlter" alt="image" /> */}
              </div>
              <div
                id="head-pdf"
                style={{
                  textAlign: 'left',
                  // backgroundColor: '#08316d',
                  // padding: '20px',
                }}
              >
                <div
                  style={{
                    textAlign: 'left',
                    width: '100%',
                    height: '120px',
                  }}
                >
                  <img
                    src={pdfHead}
                    alt=""
                    // srcSet=""
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  // marginTop: '160px',
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    paddingTop: '12mm',
                    paddingLeft: '30mm',
                    paddingRight: '30mm',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ paddingTop: '0px' }}>
                    {/* <div
                // src={chefDetails.chefImage ? chefDetails.chefImage : chefimage}
                // src={chefimage}
                alt=""
                srcSet=""
                style={divImage}
              /> */}
                    <img
                      // src={chefDetails.chefImage ? chefDetails.chefImage : chefimage}
                      src={chefDetails.chefImage}
                      alt=""
                      // srcSet=""
                      style={divImage}
                    />
                  </div>
                  <div
                    style={{
                      // display: 'flex',

                      // width: '100%',
                      // alignItems: 'flex-start',
                      // flexDirection: 'column',
                      paddingLeft: '70px',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: '500',
                        margin: '20px 0px 8px 0px',
                        textAlign: 'left',
                      }}
                    >
                      Business Name: {chefDetails.businessName}
                    </div>

                    {/* <div style={{ fontWeight: '500', margin: '20px 0px 8px 0px' }}>
                  Chef: {chefDetails.firstName + ' ' + chefDetails.lastName}
                </div>
                <div style={{ fontWeight: '500', margin: '8px 0px' }}>
                  Business Name: {chefDetails.businessName}
                </div>
                <div style={{ fontWeight: '500', margin: '8px 0px' }}>
                  Mobile Number: {chefDetails.mobileNumber}
                </div>
                <div style={{ fontWeight: '500', margin: '8px 0px' }}>
                  Email: {chefDetails.email}
                </div> */}
                    <div
                      style={{
                        fontWeight: '500',
                        width: '500px',
                        margin: '15px 0px',
                        textAlign: 'left',
                      }}
                    >
                      Website :{' '}
                      {!!chefDetails.businessName && (
                        <span style={{ fontWeight: '700', color: '#D8AF49' }}>
                          {`${FRONT_END_PORT}${convertToSlug(
                            chefDetails.businessName,
                          )}`}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: '700',
                    color: '#08316D',
                    paddingTop: '60px',
                    fontSize: '24px',
                    lineHeight: '28px',
                  }}
                >
                  Food Menu
                </div>
              </div>
              <div
                className="food-content"
                style={{
                  paddingTop: '12mm',
                  paddingLeft: '30mm',
                  paddingRight: '30mm',
                }}
              >
                <div>
                  {
                    AvailableDishList.length > 0 && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '700',
                              color: '#08316D',
                              fontSize: '24px',
                              lineHeight: '28px',
                            }}
                          >
                            Immediate Order
                          </div>
                          <div
                            style={{
                              fontWeight: '700',
                              color: '#08316D',
                              fontSize: '24px',
                              lineHeight: '28px',
                            }}
                          >
                            Price
                          </div>
                        </div>
                        {AvailableDishList.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  width: '64%',
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: '700',
                                    color: '#08316D',
                                    fontSize: '16px',
                                    lineHeight: '18px',
                                    marginTop: '30px',
                                  }}
                                >
                                  {item.name}
                                  <span>
                                    <img
                                      src={
                                        item?.dishCategory === 'Non Veg'
                                          ? nonvegIcon
                                          : vegIcon
                                      }
                                      alt=""
                                      // srcSet=""
                                      style={{
                                        marginLeft: '13px',
                                        width: '13px',
                                        height: '13px',
                                      }}
                                    />
                                  </span>
                                  {/* <span>
                            <img
                              src={nonvegimage}
                              alt=""
                              srcSet=""
                              style={{
                                marginLeft: '10px',
                                width: '13px',
                                height: '13px',
                              }}
                            />
                          </span> */}
                                </div>
                                <div
                                  style={{
                                    fontWeight: '400',
                                    color: '#7C7C7C',
                                    fontSize: '14px',
                                    lineHeight: '16px',
                                    paddingTop: '15px',
                                    paddingBottom: '5px',
                                    textAlign: 'justify',
                                  }}
                                >
                                  {item.about}{' '}
                                </div>
                                <div
                                  className="cousine-section-pdf"
                                  style={{
                                    display: 'flex',
                                  }}
                                >
                                  {item.preference.length &&
                                    item.preference.map((item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          style={{
                                            fontWeight: '500',
                                            color: '#08316D',
                                            fontSize: '14px',
                                            lineHeight: '16px',
                                            color: '#D8AF49',
                                          }}
                                        >
                                          {(index ? ', ' : '') + item}
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: '700',
                                  color: '#08316D',
                                  fontSize: '16px',
                                  lineHeight: '18px',
                                  marginTop: '30px',
                                }}
                              >
                                <span>
                                  {item.currency
                                    ? getSymbolFromCurrency(item.currency)
                                    : '₹'}

                                  {item.price}
                                </span>
                                {/* ₹ {item.price} */}
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )
                    // : (
                    //   <div
                    //     style={{
                    //       fontWeight: '700',
                    //       color: '#d8af49',
                    //       fontSize: '24px',
                    //       lineHeight: '28px',
                    //       paddingTop: '30px',
                    //       textAlign: 'center',
                    //     }}
                    //   >
                    //     Immediate Order Empty ...!
                    //   </div>
                    // )
                  }
                </div>
                <div>
                  {
                    AdvanceOrderList.length > 0 && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '700',
                              color: '#08316D',
                              fontSize: '24px',
                              lineHeight: '28px',
                              paddingTop: '30px',
                            }}
                          >
                            Advance Order
                          </div>
                        </div>
                        {
                          // AdvanceOrderList.filter((item, index) => index < 2).map(
                          AdvanceOrderList.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div
                                  style={{
                                    width: '64%',
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: '700',
                                      color: '#08316D',
                                      fontSize: '16px',
                                      lineHeight: '18px',
                                      marginTop: '30px',
                                    }}
                                  >
                                    {item.name}
                                    <img
                                      src={
                                        item?.dishCategory === 'Non Veg'
                                          ? nonvegIcon
                                          : vegIcon
                                      }
                                      alt=""
                                      // srcSet=""
                                      style={{
                                        marginLeft: '10px',
                                        width: '13px',
                                        height: '13px',
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: '400',
                                      color: '#7C7C7C',
                                      fontSize: '14px',
                                      lineHeight: '16px',
                                      paddingTop: '15px',
                                      paddingBottom: '5px',
                                      textAlign: 'justify',
                                    }}
                                  >
                                    {item.about}{' '}
                                  </div>
                                  <div
                                    className="cousine-section-pdf"
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    {item.preference.length &&
                                      item.preference.map((item, index) => {
                                        return (
                                          <div
                                            key={index}
                                            style={{
                                              fontWeight: '500',
                                              color: '#08316D',
                                              fontSize: '14px',
                                              lineHeight: '16px',
                                              color: '#D8AF49',
                                            }}
                                          >
                                            {(index ? ', ' : '') + item}
                                          </div>
                                        )
                                      })}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontWeight: '700',
                                    color: '#08316D',
                                    fontSize: '16px',
                                    lineHeight: '18px',
                                    marginTop: '30px',
                                  }}
                                >
                                  <span>
                                    {item.currency
                                      ? getSymbolFromCurrency(item.currency)
                                      : '₹'}

                                    {item.price}
                                  </span>
                                  {/* ₹ {item.price} */}
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                    )
                    // : (
                    //   <div
                    //     style={{
                    //       fontWeight: '700',
                    //       color: '#d8af49',
                    //       fontSize: '20px',
                    //       lineHeight: '28px',
                    //       paddingTop: '30px',
                    //       textAlign: 'center',
                    //     }}
                    //   >
                    //     Advance Order Empty ...!
                    //   </div>
                    // )
                  }
                </div>
              </div>
              <div
                className="footer-print"
                style={{
                  textAlign: 'center',
                  paddingTop: '99px',
                  color: '#08316D',
                  fontWeight: '600',
                  lineHeight: '20px',
                }}
              >
                <span
                  style={{
                    textAlign: 'center',
                    paddingTop: '99px',
                    color: '#08316D',
                    fontWeight: '600',
                    lineHeight: '20px',
                    textDecoration: 'underline',
                  }}
                >
                  www.thecheflane.com
                </span>{' '}
                |{' '}
                <span
                  style={{
                    textAlign: 'center',
                    paddingTop: '99px',
                    color: '#08316D',
                    fontWeight: '600',
                    lineHeight: '20px',
                    textDecoration: 'underline',
                  }}
                >
                  support@thecheflane.com
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CommonPageLoader />
      )}
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getMenuWithChef,
    },
    dispatch,
  )
}

export default connect(null, mapDispatchToProps)(ChefPdf)
