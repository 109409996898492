import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { Button } from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import { NormalButton, NormalInput } from 'component/common'
import { history } from 'service/helpers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { chefUpdate, chefQuite } from 'action/ChefDetailsAct'
import closeBtn from 'assets/images/cloceBtn.png'
import logo from 'assets/images/alertLogo.png'
import correct from '../../../assets/images/correct.png'
import wrong from '../../../assets/images/wrong.png'
function UnderReviewTrial() {
  const [reviewDetails, setreviewDetails] = useState({
    message: '',
  })
  const [active, setactive] = useState(1);
  const [isLoading, setIsLoading] = useState(false)
  const [isAlert, setIsAlert] = useState(true)
  const [isQuit, setIsQuit] = useState(false)
  const [error, setErrors] = useState({});
  const handleInputChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setreviewDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    }),
  )
  let isExpired = false
  let isblocked = false
  return (
    <div>
      <Modal
        show={isAlert}
        //   onHide={() => setIsAlert(true)}
        backdrop={true}
        keyboard={false}
        className="deactivate-modal-order"
        centered
      >
        <Modal.Header className="bg-white border-0">
          <Modal.Title></Modal.Title>
          {!isExpired ? (
            <span class="cursor-pointer" onClick={() => setIsAlert(false)}>
              <img src={closeBtn} alt="icon" />
            </span>
          ) : (
            ''
          )}
        </Modal.Header>
        <Modal.Body className="review-popup">
          <div className="review-ratingorder">
            <div className="bitesheading col-lg-12  col-md-12 col-10 mb-5 line-height-28 fs-24 fw-700 text-primary-color">
              Rate & review your order
            </div>
            <p className="fs-16 fw-400 text-grey-4f mb-5">
              Order Items - Cream of tartar X 2 | Dish name X 1 | Dish name X 2
            </p>
            <div className="review-deActive mt-3 py-3 mb-4 px-4">
              <div className="text-black-25 fs-16 mb-3">Rating</div>
              <div className="rating-count  fs-18  text-black">
                <span  className={`${
                active === 1 ? "text-primary-color faq-active" : "text-grey-9d"
              }`}   onClick={() => setactive(1)}>1</span>
                <span className={`${
                active === 2 ? "text-primary-color faq-active" : "text-grey-9d" }`}   onClick={() => setactive(2)}>2</span>
                <span className={`${
                  active === 3 ? "text-primary-color faq-active" : "text-grey-9d" }`}  onClick={() => setactive(3)}>3</span>
                <span className={`${
                  active === 4 ? "text-primary-color faq-active" : "text-grey-9d" }`}   onClick={() => setactive(4)}>4</span>
                <span className={`${
                  active === 5 ? "text-primary-color faq-active" : "text-grey-9d" }`}   onClick={() => setactive(5)}>5</span>
              </div>
            </div>
            <div className="review-message">
              <textarea
                className="mt-1 d-flex flex-column w-100 form-control"
                name="message"
                placeholder="Review"
                value={reviewDetails.message}
                onChange={handleInputChange}
                rows="4"
              />
            </div>
          </div>
          <Modal.Footer className="w-100 mt-4 justify-content-center">
            <>
              <div className="d-flex pb-3">
                <NormalButton
                  primary
                  label="Submit"
                  className="px-5 mt-3 fw-700"
                  disabled={isLoading}
                />
              </div>
            </>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(UnderReviewTrial)
