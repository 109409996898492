import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import Form from 'react-bootstrap/Form'
import { Button } from 'reactstrap'
import SimpleReactValidator from 'simple-react-validator'
import { NormalButton } from 'component/common'
import { history } from 'service/helpers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { chefUpdate } from 'action/ChefDetailsAct'

function UnderReview({ formData }) {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (formData.status !== 'NEWREQUEST') {
      history.push(`/index/activeProfile`)
    }
  }, [])
  return (
    <div className="bg-outline">
      <div className="insidd">
        <h4 className="mt-5 text-primary-color fw-700">Under Review</h4>
        <p className="mb-0 text-center">
          Your Profile is under Review. You will receive a mail <br />
          once your profile is approved.
        </p>
        <div className="btnn-outline">
          {/* <NormalButton
                primary
                label="View Profile"
                className="w-100 fw-700"
                disabled={formData.status!=='APPROVED'}
                onClick={() => { 
                  history.push(`/index/activeProfile`)
                }}
              /> */}
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefUpdate,
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(UnderReview)
