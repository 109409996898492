class EventEmitterClass {
  constructor() {
    this.events = {};
    this.prevPath = "";
  }

  get getEventObj() {
    return this.events;
  }

  init(userId) {
    if (userId && window.pusher) {
      var channel = window.pusher.subscribe(`channel-${userId}`);
      channel.bind("count", () => {
        this.handler();
      });
    }
  }

  handler = () => {
    Object.keys(this.getEventObj).map((key) => {
      this.getEventObj[key]();
    });
  };

  addEventListener = (type, callback) => {
    this.events[type] = callback;
  };

  removeEvent = async (type) => {
    await delete this.events[type];
  };
}

export const EventEmitter = new EventEmitterClass();
