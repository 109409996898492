import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { FB } from "assets/svg/facebook.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import plus from "../../../assets/images/plus.png";
import {
  NormalButton,
  NormalToggleSwitch,
  NormalRadio,
  NormalDate,
  NormalCustomCheckbox,
  CommonPageLoader,
  ImageCropComponent,
} from "component/common";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefUpdate, getCusineList } from "action/ChefDetailsAct";
import { fileUpload, imageUpload } from "action/FileUpload";
import facebook from "assets/images/facebook.png";
import Instagram from "assets/images/Instagram.png";
import closeGray from "assets/images/closeGray.png";
import closeBtn from "assets/images/closeWhite.png";
import userUpload from "assets/images/userUpload.png";
import moment from "moment";
import { Toast } from "service/toast";
import { handleNameField } from "service/helperFunctions";
import { CountryCodeDropDown } from "component/common/CountryCodeDropDown";
const regExp = /[a-zA-Z]/g;
function BasicDetail({
  getCusineList,
  resetFormData,
  fileUpload,
  imageUpload,
  chefUpdate,
  renderCuisnineList = [],
  setrenderCusinieList,
  selectedCuisineList = [],
  setSelectedCuisineList,
  handleFormChange,
  formData,
  setFormData,
  setFormDataBasic,
}) {
  const [show, setShow] = useState(false);
  const [showPlatformAlert, setShowPlatformAlert] = useState(false);
  const [updateValidator, setUpdateValidator] = useState(true);
  const [profileImgURL, setProfileImgURL] = useState();
  const [rawFile, setRawFile] = useState();
  const referenceFile = useRef();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const handleClose = () => {
    setShow(false);
    setSearch("");
  };
  const handleShow = () => setShow(true);
  const [cuisineList, setcuisineList] = useState([]);
  const reference = useRef();
  const [selectedImage, setSelectedImage] = useState([]);
  const [croppingImage, setCroppingImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isuploading, setIsuploading] = useState(false);
  const [isDocumentuploading, setIsDocumentuploading] = useState(false);
  const [search, setSearch] = useState("");
  const [errorFacebook, setErrorFacebook] = useState(true);
  const [errorInstagram, setErrorInstagram] = useState(true);
  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );
  const validatorLicence = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  useEffect(() => {
    fetchCusineList();
  }, []);
  const fetchCusineList = async () => {
    let id = localStorage.getItem("userId");
    setIsLoading(true);
    await getCusineList()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.length) {
          setcuisineList(data);
          // setFormData({ ...newForm });
          // setSelectedCuisineList(newForm.cuisineDetails ? newForm.cuisineDetails.cuisine : [])
        }
      })
      .catch((e) => setIsLoading(false));
  };
  const handleBeforeImageCropping = (event) => {
    setCroppingImage(event.target.files[0]);
    setShowImageCrop(true);
  };
  const handleFileUpload = async (event, name = "chefImage") => {
    let keySet = name === "chefImage" ? setIsuploading : setIsDocumentuploading;
    var data = new FormData();
    let file = {};
    file = name === "chefImage" ? event : event.target.files[0];
    let api = name === "chefImage" ? imageUpload : fileUpload;

    data.append("file", file);
    let size = file.size;
    if (size > 1048576 * 5) {
      if (name === "chefImage")
        Toast({
          type: "error",
          message: "Please upload image file that is 5MB or less",
          time: 2000,
        });
      else
        Toast({
          type: "error",
          message: "Please upload document file that is 5MB or less",
          time: 2000,
        });
    } else {
      keySet(true);

      await api(data)
        .then((data) => {
          if (name === "chefImage") {
            setFormData((prevState) => ({
              ...prevState,
              chefImage: data?.data?.url,
            }));
          } else {
            let { LicenseDetails } = { ...formData };
            LicenseDetails.licenseFileUrl = data?.data?.url;
            setFormData((prevState) => ({
              ...prevState,
              LicenseDetails,
            }));
          }
          keySet(false);
        })
        .catch(() => {
          keySet(false);
        });
    }
  };

  // const [renderCuisnineList, setrenderCusinieList] = useState([]);

  const mapCusine = () => {
    setShow(false);
    setrenderCusinieList(selectedCuisineList);
  };

  const handleSelectedCuisine = (e) => {
    console.log(e?.target?.value);
    if (e?.target?.value) {
      let value = e?.target?.name;
      setSelectedCuisineList([...selectedCuisineList, value]);
      console.log(selectedCuisineList);
    } else {
      setSelectedCuisineList(
        selectedCuisineList.filter((item) => item !== e.target.name)
      );
      console.log(selectedCuisineList);
    }
  };
  const isUrlValid = (userInput) => {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };
  const submitBasicDetails = async () => {
    console.log(formData);
    console.log(validator.current.allValid());
    let flag =
      formData.countryCode !== "+91"
        ? validator.current.allValid()
        : validator.current.allValid() && validatorLicence.current.allValid();

    let socialMediaFlag = true;
    let socialMediaFlagInsta = true;
    if (
      formData.facebook?.facebookLink !== "" &&
      formData.facebook?.facebookLink !== undefined
    ) {
      setErrorFacebook(isUrlValid(formData?.facebook?.facebookLink));
      socialMediaFlag = isUrlValid(formData.facebook?.facebookLink);
    }
    if (
      formData.instagram?.instagramLink !== "" &&
      formData.instagram?.instagramLink !== undefined
    ) {
      setErrorInstagram(isUrlValid(formData?.instagram?.instagramLink));
      socialMediaFlagInsta = isUrlValid(formData?.instagram?.instagramLink);
    }
    if (flag && socialMediaFlag && socialMediaFlagInsta) {
      setIsLoading(true);

      // let { cuisineDetails } = { ...formData }
      formData.firstName = handleNameField(formData.firstName);
      formData.lastName = handleNameField(formData.lastName);
      formData.cuisine = selectedCuisineList;
      // setFormData({ ...formData, cuisineDetails })
      console.log(formData, "basicDetails");
      await chefUpdate(formData)
        .then(() => {
          setIsLoading(false);
          handleFormChange("addressDetails");
        })
        .catch((e) => setIsLoading(false));
    } else {
      if (formData.countryCode === "+91") {
        validatorLicence.current.showMessages();
        scrollTop("FoodLicense");
      }
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please fill up all the required fields",
        time: 3000,
      });
      if (formData.countryCode === "+91" && validatorLicence.current.allValid())
        scrollTop();
      if (formData.countryCode !== "+91") scrollTop();
    }
  };

  if (
    formData.countryCode === undefined ||
    formData.countryCode === "" ||
    formData.whatsappCountryCode === undefined ||
    formData.whatsappCountryCode === ""
  ) {
    setFormData((prevState) => ({
      ...prevState,
      countryCode: "+91",
      whatsappCountryCode: "+91",
    }));
  }

  const scrollTop = (key = "custom-profile") => {
    let element = document.getElementById(key);
    console.log(element, "element");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  const handleSearch = (e) => {
    let {
      target: { value },
    } = e;
    setSearch(value);
  };
  const handleChange = (e) => {
    let {
      target: { name, value },
    } = e;

    if (name === "countryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        mobileNumber: "",
        //whatsappNumber: "",
      }));

      if (formData.sameAsMobileNumber) {
        setFormData((prevState) => ({
          ...prevState,
          sameAsMobileNumber: e.target.value,
          whatsappNumber: "",
          whatsappCountryCode: value,
        }));
      }
    }

    if (name === "whatsappCountryCode") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        //whatsappNumber: formData.mobileNumber,
        whatsappNumber: "",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    if (formData.sameAsMobileNumber && name === "mobileNumber") {
      setFormData((prevState) => ({
        ...prevState,
        whatsappNumber: value,
      }));
    }
  };

  return (
    <div className="basic_detail_container">
      <div className="container-fluid container-lg">
        <div className="basic_detail_inner_container">
          <div className="header" id="custom-profile">
            <div>Basic Details</div>
            <hr></hr>
          </div>
          <div className="profile_pic_container">
            <div className="profile_pic">
              <div className="box">
                {isuploading ? (
                  <CommonPageLoader />
                ) : (
                  <>
                    {
                      !!formData.chefImage ? (
                        <img src={formData.chefImage} alt="Thumb" />
                      ) : (
                        <span className="d-flex">
                          <img src={userUpload} alt="image" />
                        </span>
                      )
                      // <>
                      //   {selectedImage && (
                      //     <img src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                      //   )}
                      // </>
                    }
                  </>
                )}
              </div>
              <div className="availability ">
                <Form.Label className="mb-3">Availability </Form.Label>
                <div className="d-flex align-items-center">
                  <NormalToggleSwitch
                    name="availability "
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        availabilityStatus: e.target.checked,
                      }))
                    }
                    checked={formData.availabilityStatus}
                  />
                  <div className="check-value">
                    {formData.availabilityStatus ? "YES" : "NO"}
                  </div>
                </div>
              </div>
            </div>

            <div className="upload_btn">
              <input
                ref={reference}
                onChange={(e) => {
                  e.target.files[0] &&
                    handleBeforeImageCropping(e, "chefImage");
                }}
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                // multiple={false}
              />
              {/* <Button
              onClick={() => reference.current.click()}
              variant="outline-dark"
            >
              Upload Image
            </Button> */}
              <NormalButton
                label=" Upload Image"
                disabled={isuploading}
                className="w-100 fw-700 outline-gray-btn"
                onClick={() => {
                  reference.current.click();
                  //setShowImageCrop(true);
                }}
              />
              <p className="fs-12 alert-for-upload-profile mb-0 mt-2">
                <sup className="text-danger fs-14">*</sup>
                Please upload image file that is 5MB or less
              </p>
              {validator.current.message(
                "Image",
                formData.chefImage,
                "required"
              )}
            </div>
          </div>

          <div className="body">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    First Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    placeholder="Enter First Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={formData.firstName}
                  />
                  {validator.current.message(
                    "First Name",
                    formData.firstName,
                    "required|min:3|max:20"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Last Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    autoComplete="off"
                    placeholder="Enter Last Name"
                    onChange={(e) => {
                      handleChange(e);

                      console.log(formData);
                    }}
                    value={formData.lastName}
                  />
                  {validator.current.message(
                    "Last Name",
                    formData.lastName,
                    "required|max:20"
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Business Name<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="businessName"
                    autoComplete="off"
                    placeholder="Enter Brand Name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={formData.businessName}
                  />
                  {validator.current.message(
                    "Business Name",
                    formData.businessName,
                    "required|min:3|max:50"
                  )}
                </Form.Group>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Mobile Number<sup>*</sup>
                  </Form.Label>
                  <div className="d-flex country-mobile">
                    <div className="country-code-dropdown">
                      <CountryCodeDropDown
                        onClick={(e) => {
                          handleChange({
                            target: {
                              name: "countryCode",
                              value: e,
                            },
                          });
                        }}
                        value={
                          formData.countryCode === undefined ||
                          formData.countryCode === ""
                            ? "+91"
                            : formData.countryCode
                        }
                        disabled={formData.loginType === false ? true : false}
                      />
                    </div>
                    <Form.Control
                      autoComplete="nope"
                      // disabled={(formData.loginType = false)}
                      disabled={formData.loginType === false ? true : false}
                      //disabled={false}
                      type="number"
                      name="mobileNumber"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={formData.mobileNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />

                    
                  </div>
                  {validator.current.message(
                      "Mobile Number",
                      formData.mobileNumber,
                      "required"
                    )}
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>
                    Email ID<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    type="email"
                    name="email"
                    placeholder="Enter Email ID"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={formData.email}
                  />
                  {validator.current.message(
                    "Email",
                    formData.email,
                    "required|email"
                  )}
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3">
                <Form.Group>
                  <Form.Label>Whatsapp Number</Form.Label>
                  <div className="d-flex country-mobile">
                    <div className="country-code-dropdown">
                      <CountryCodeDropDown
                        onClick={(e) => {
                          handleChange({
                            target: {
                              name: "whatsappCountryCode",
                              value: e,
                            },
                          });
                        }}
                        value={
                          formData?.whatsappCountryCode
                            ? formData?.whatsappCountryCode
                            : "+91"
                        }
                        disabled={formData.sameAsMobileNumber}
                      />
                    </div>

                    <Form.Control
                      autoComplete="nope"
                      disabled={formData.sameAsMobileNumber}
                      type="number"
                      name="whatsappNumber"
                      placeholder="Enter Mobile Number"
                      onChange={(e) => {
                        if (!regExp.test(e.currentTarget.value)) {
                          handleChange(e);
                        }
                      }}
                      value={formData.whatsappNumber}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {/* {validator.current.message(
                    "Whatsapp",
                    formData.whatsappNumber,
                    "required"
                  )} */}
                </Form.Group>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center">
                <Form.Group className="mb-0">
                  {/* <Form.Label>Email ID</Form.Label> */}
                  <NormalCustomCheckbox
                    name="sameAsMobileNumber"
                    checked={formData.sameAsMobileNumber}
                    value={formData.sameAsMobileNumber}
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      if (value) {
                        setFormData((prevState) => ({
                          ...prevState,
                          sameAsMobileNumber: e.target.value,
                          whatsappNumber: formData.mobileNumber,
                          whatsappCountryCode: formData?.countryCode,
                        }));
                      } else {
                        setFormData((prevState) => ({
                          ...prevState,
                          sameAsMobileNumber: e.target.checked,
                          whatsappNumber: "",
                          whatsappCountryCode: "",
                        }));
                      }
                    }}
                    label={"Same as mobile number"}
                    disable={!formData.mobileNumber}
                  />
                  {/* {validator.current.message(
                    "Whatsapp",
                    formData.whatsappNumber,
                    "required"
                  )} */}
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <Form.Group>
                  <Form.Label>
                    About me<sup>*</sup>
                  </Form.Label>
                  <Form.Control
                    maxLength="500"
                    type="text"
                    className="w-100"
                    autoComplete="off"
                    placeholder="About you in 500 characters or less"
                    onChange={(e) => {
                      let { AboutDetails } = { ...formData };
                      let {
                        target: { value },
                      } = e;
                      AboutDetails.About = value;
                      // setFormData({ ...formData, AboutDetails })
                      setFormData((prevState) => ({
                        ...prevState,
                        AboutDetails,
                      }));
                    }}
                    value={formData.AboutDetails.About}
                  />
                  {validator.current.message(
                    "About me",
                    formData.AboutDetails.About,
                    "required|min:10|max:500"
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="cusine-blocks">
              <Form.Label className="mb-0">
                Cuisine<sup>*</sup>
              </Form.Label>
              {/* Cuisine<br></br> */}
              <div className="cuisine_list">
                <span className="button-width">
                  <NormalButton
                    label="Add Cuisine"
                    className="fw-700 outline-gray-btn"
                    onClick={handleShow}
                    prefix={plus}
                  />
                  {validator.current.message(
                    "Cuisine",
                    selectedCuisineList,
                    "required"
                  )}
                </span>
                {selectedCuisineList?.map((item) => (
                  <div className="cusine_item">
                    {item}&ensp;
                    <b
                      className="rmv_cuisine_item"
                      onClick={() => {
                        setSelectedCuisineList(
                          selectedCuisineList.filter((items) => items !== item)
                        );
                        setrenderCusinieList(selectedCuisineList);
                      }}
                    >
                      &#10006;
                    </b>
                  </div>
                ))}
              </div>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop={true}
                keyboard={false}
                centered
              >
                <Modal.Header>
                  <Modal.Title>
                    <b>Select Cuisine</b>
                  </Modal.Title>
                  <span class="cursor-pointer" onClick={handleClose}>
                    <img src={closeBtn} alt="icon" />
                  </span>
                </Modal.Header>
                {/* <hr></hr> */}
                <Modal.Body>
                  <div className="model_body">
                    <div className="search-outer">
                      <div class="input-group  search-input-group">
                        <input
                          type="text"
                          id="searchValue"
                          name="searchValue"
                          autoComplete="off"
                          value={search}
                          onChange={(e) => {
                            handleSearch(e);
                          }}
                          className="form-control border-right-0 searchInput"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                        />
                        <div class="input-group-append ">
                          <span
                            class="input-group-text bg-white border-left-0 cursor-pointer"
                            onClick={() => {
                              setSearch("");
                            }}
                          >
                            <img
                              className="mailIcon"
                              src={closeGray}
                              alt="search"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="list-body">
                      <div className="row">
                        {cuisineList
                          .filter((item) =>
                            search === ""
                              ? item
                              : String(item)
                                  .toLowerCase()
                                  .match(search.toLowerCase())
                          )
                          .map((item) => {
                            return (
                              <div className="col-6 mb-2">
                                <NormalCustomCheckbox
                                  name={item}
                                  checked={selectedCuisineList.includes(item)}
                                  value={selectedCuisineList.includes(item)}
                                  onChange={(e) => {
                                    handleSelectedCuisine(e);
                                  }}
                                  label={item}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="pt-0">
                  <div className="d-flex justify-content-start w-100">
                    <NormalButton
                      label="Discard"
                      className="white-btn mr-3"
                      onClick={() => {
                        setSelectedCuisineList([]);
                        setShow(false);
                        setSearch("");
                      }}
                    />
                    <span className="m-2" />

                    <NormalButton
                      primary
                      label="Save"
                      className="fw-700"
                      disabled={isLoading}
                      onClick={() => {
                        mapCusine();
                        setSearch("");
                      }}
                    />
                  </div>
                  {/* <Button variant="primary" onClick={mapCusine}>
                    Next
                  </Button> */}
                </Modal.Footer>
              </Modal>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <Form.Group>
                <Form.Label>
                  Delivery Partners<sup>*</sup>
                </Form.Label>
                <div>
                  <span className="mx-2">
                    <NormalRadio
                      className=" border-0 rounded-0"
                      label={"Own delivery partners"}
                      name="OWN"
                      value={formData.deliveryPartners === "OWN"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          deliveryPartners: e.target.name,
                        }));
                      }}
                      checked={formData.deliveryPartners === "OWN"}
                      disabled={false}
                    />
                  </span>
                  <span className="mx-4">
                    <NormalRadio
                      disabled={true}
                      className=" border-0 rounded-0"
                      label={"Platform delivery partners (Coming soon) "}
                      name="PLATFORM"
                      value={formData.deliveryPartners === "PLATFORM"}
                      onChange={(e) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          deliveryPartners: e.target.name,
                        }));
                        setShowPlatformAlert(true);
                      }}
                      checked={formData.deliveryPartners === "PLATFORM"}
                    />
                  </span>
                </div>

                {validator.current.message(
                  "delivery partners",
                  formData.deliveryPartners,
                  "required"
                )}
              </Form.Group>
            </div>
          </div>
          <br></br>
          <div className="header" id="FoodLicense">
            <div>Food License</div>
            <hr></hr>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-3">
              <Form.Group>
                <Form.Label>
                  License Number
                  {/* {formData.countryCode === "+91" && <sup>*</sup>} */}
                </Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="Enter License Number"
                  onChange={(e) => {
                    let {
                      target: { value },
                    } = e;
                    let { LicenseDetails } = { ...formData };
                    LicenseDetails.licenseNumber = value;

                    setFormData((prevState) => ({
                      ...prevState,
                      LicenseDetails,
                    }));
                  }}
                  value={formData.LicenseDetails.licenseNumber}
                />
                {/* {validatorLicence.current.message(
                  "License Number",
                  formData.LicenseDetails.licenseNumber,
                  "required"
                )} */}
              </Form.Group>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-5">
              <div className="custom-file-upload error-file-license">
                <Form.Group>
                  <Form.Label>
                    License File
                    {/* {formData.countryCode === "+91" && <sup>*</sup>} */}
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      disabled={isDocumentuploading}
                      type="file"
                      ref={referenceFile}
                      placeholder="Enter..."
                      accept="application/pdf,image/*"
                      className="custom-edited-upload"
                      onChange={(e) => {
                        e.target.files[0] && handleFileUpload(e, "licenseFile");
                        // if (e.currentTarget.files[0]){

                        //   setRawFile(e.currentTarget.files[0])
                        // setSelectedImage(e.currentTarget.files[0])
                        // }
                      }}
                    />
                    <p className="fileNAme">
                      {isDocumentuploading ? (
                        "loading..."
                      ) : (
                        <>
                          {formData.LicenseDetails.licenseFileUrl
                            ? formData.LicenseDetails.licenseFileUrl
                                .split("/")
                                .pop()
                                .split("--")
                                .pop()
                            : ""}
                        </>
                      )}
                    </p>
                    <NormalButton
                      label="Upload"
                      className="fw-700 fs-14 file-btn"
                      disabled={isDocumentuploading}
                      onClick={() => referenceFile.current.click()}
                    />
                    <p className="fs-12 alert-for-upload mb-0">
                      {/* {formData.countryCode === "+91" && (
                        <sup className="text-danger fs-14">*</sup>
                      )} */}
                      Please upload file that is 5MB or less
                    </p>
                    {/* {validatorLicence.current.message(
                      "License File",
                      formData.LicenseDetails.licenseFileUrl,
                      "required"
                    )} */}
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-5 col-lg-3">
              <Form.Group>
                <Form.Label>
                  License Expiry Date
                  {/* {formData.countryCode === "+91" && <sup>*</sup>} */}
                </Form.Label>
                <NormalDate
                  placeholder="Enter Date"
                  name="licenseExpiryDate"
                  showYearDropdown={true}
                  value={
                    formData.LicenseDetails.licenseExpiryDate &&
                    new Date(formData.LicenseDetails.licenseExpiryDate)
                  }
                  isIcon={false}
                  minDate={new Date(moment().add(10, "days"))}
                  className="form-control mini-date"
                  onChange={(e) => {
                    // let date=moment(e.target.value).format("DD/MM/YYYY")
                    let { LicenseDetails } = { ...formData };
                    LicenseDetails.licenseExpiryDate = e.target.value;

                    setFormData((prevState) => ({
                      ...prevState,
                      LicenseDetails,
                    }));
                  }}
                />
                {/* <Form.Control
                  type="date"
                  placeholder="Enter License Expiry Date"
                  onChange={(e) => {
                    let { LicenseExpiryDetails } = { ...formData };
                    LicenseExpiryDetails.licenseExpiryDate = e.currentTarget.value;
                    setFormData({
                      ...formData,
                      LicenseExpiryDetails,
                    });
                  }}
                  value={formData.LicenseNumberDetails.licenseExpiryDate}
                /> */}
                {/* {validatorLicence.current.message(
                  "License Expiry",
                  formData.LicenseDetails.licenseExpiryDate,
                  "required"
                )} */}
              </Form.Group>
            </div>
          </div>

          <div className="header">
            <div>Social Media</div>
            <hr></hr>
          </div>

          <div className="body">
            <div className="item3">
              <span
                className={`text-nowrap test-instagram ${
                  !errorFacebook ? "test-facebook" : ""
                }`}
              >
                <img className="me-1" src={facebook} alt="facebook" />
                &ensp;Facebook - &ensp;
              </span>
              <div
                className={`col-12 col-xs-12 col-md-12 instagram-facebook col-lg-7 ${
                  !errorFacebook ? "test-facebook" : ""
                }`}
              >
                <Form.Group className="mb-0">
                  <Form.Control
                    className="mb-0"
                    type="text"
                    autoComplete="off"
                    placeholder="Enter complete URL of your Facebook Profile Page"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { facebook } = { ...formData };
                      facebook.facebookLink = value;
                      if (value === "") {
                        setErrorFacebook(true);
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        facebook,
                      }));
                    }}
                    value={formData?.facebook?.facebookLink}
                  />
                  <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                    {/* <sup className="text-danger fs-14">*</sup> */}
                    Enter URL in format www.facebook.com/thecheflane
                  </p>

                  {/* {validator.current.message(
                    'Facebook',
                    formData.socialMediaLinks.facebook,
                    'url',
                  )} */}
                  {!errorFacebook && (
                    <span class="error-message fs-12">
                      Please enter a valid Facebook URL
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="item3">
              <span className="text-nowrap test-instagram">
                <img className="" src={Instagram} alt="Instagram" />
                &ensp;Instagram - &ensp;
              </span>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 instagram-facebook ">
                <Form.Group className="mb-0">
                  <Form.Control
                    type="text"
                    className="mb-0"
                    autoComplete="off"
                    placeholder="Enter complete URL of your Instagram Profile Page"
                    onChange={(e) => {
                      let {
                        target: { value },
                      } = e;
                      let { instagram } = { ...formData };
                      instagram.instagramLink = value;
                      if (value === "") {
                        setErrorInstagram(true);
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        instagram,
                      }));
                    }}
                    value={formData?.instagram?.instagramLink}
                  />
                  {/* {validator.current.message(
                    'Instagram',
                    formData.socialMediaLinks.instagram,
                    'url',
                  )} */}
                  <p className="fs-12 position-absolute text-instagam alert-for-upload mb-0">
                    {/* <sup className="text-danger fs-14">*</sup> */}
                    Enter URL in format www.instagram.com/the.chef.lane
                  </p>
                  {!errorInstagram && (
                    <span class="error-message fs-12">
                      Please enter a valid Instagram URL
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <NormalButton
            label="Discard"
            className="white-btn mr-3"
            onClick={() => resetFormData()}
          />
          <span className="m-2" />

          <NormalButton
            primary
            label="Next"
            className="fw-700"
            disabled={isLoading || isDocumentuploading}
            onClick={() => submitBasicDetails()}
          />
        </div>
        <ImageCropComponent
          setShowImageCrop={setShowImageCrop}
          showImageCrop={showImageCrop}
          handleFileUpload={handleFileUpload}
          setCroppingImage={setCroppingImage}
          croppingImage={croppingImage}
        />
        <Modal
          show={showPlatformAlert}
          onHide={() => setShowPlatformAlert(false)}
          backdrop={true}
          keyboard={false}
          className="deactivate-modal"
          centered
        >
          <Modal.Header className="bg-white border-0">
            <Modal.Title></Modal.Title>
            <span
              class="cursor-pointer"
              onClick={() => setShowPlatformAlert(false)}
            >
              <img src={closeBtn} alt="icon" />
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-deActive mt-5 pb-5">
              <h4 className="mb-2">
                Choosing platform delivery partners, may <br />
                result in limited radius for delivery.
              </h4>
            </div>
            <Modal.Footer></Modal.Footer>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefUpdate,
      fileUpload,
      imageUpload,
      getCusineList,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(BasicDetail);
