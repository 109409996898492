import React, { Component } from 'react'
// Redux Connection
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Component import
import CustomerComponent from 'component/customerProfile'

export class CustomerProfile extends Component {
  render() {
    return <CustomerComponent />
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

const component = CustomerProfile

export const CustomerProfilePage = connect(null, mapDispatchToProps)(component)
