import React from "react";
import "./style.scss";
import missionone from "../../assets/images/missin-one.png";
import missiontwo from "../../assets/images/missin-two.png";
import missionthree from "../../assets/images/missin-three.png";

export const AboutUs = () => {
  return (
    <div className="mission_container_background ">
      <div className="container mission_container ">
        <h1 className="text-primary-color line-height-42 fs-31 fw-700 text-center mb-5 ">
          About Us
        </h1>
        <div className="d-flex mb-4 justify-content-center align-items-center flex-wrap px-5 px-sm-0">
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            It is a deeply personal journey that has brought us here.
          </p>
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            Our daughters are passionate about food, and 1 of them even wanted to start a little home baking business during the pandemic. But it was not as easy as it looked. She soon found that she needed a different set of skills to promote and market her fledgling business, and there was no platform for discovery. So just good food was not going to ensure that her cakes reached more tables.
          </p>
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            This was our Eureka moment! Our personal quest turned into a passion project, to bring different cuisines, zip codes and home chefs to a common platform, a one-stop-shop for healthy, hearty and homely food.          </p>

          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            We decided to create a space for home cooks, so that they can focus on the food and leave the rest to us.          </p>
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            The Chef Lane is dedicated to all the home chefs out there.
          </p>
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            The Chef Lane is FOR home chefs. And, it is BY home chefs.
          </p>
          <p className="col-12 text-left mb-0 fs-18 mb-3">
            {" "}
            - Sawan & Surbhi Gupta
          </p>



          {/* <div className="col-lg-11 col-12 mx-auto">
          <div className="row mb-5 ">
            <div className="col-md-12 col-lg-4  mb-4">
              <div className="bg-white d-flex flex-column align-items-center px-3 mission-block ">
                <img alt="chef" className="mx-auto h-100" src={missionone} />
                <p className="pt-4 px-md-3 mx-auto mb-md-3 fs-16 mb-4 text-center">
                  We help create the ideal business environment, so chefs can
                  focus on their food.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mb-4">
              <div className="bg-white d-flex flex-column align-items-center px-3  mission-block">
                <img alt="chef" className="mx-auto h-100" src={missiontwo} />
                <p className="pt-4 px-md-3 mx-auto  mb-md-3 mb-4 fs-16 text-center">
                  Building a profile is just the first step on this journey.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4">
              <div className="bg-white d-flex flex-column align-items-center px-2  mission-block ">
                <img alt="chef" className="mx-auto h-100" src={missionthree} />
                <p className="pt-1 px-md-3 mx-auto mb-md-3 mb-4 fs-16 text-center mission-third">
                  We intend to develop an ecosystem around home catering, so we
                  can boost chefs' visibility through effective toolkits on
                  social media projection, nutritional data and best business
                  practices.
                </p>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export const AboutUsComp = AboutUs;
