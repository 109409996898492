import React, { useState } from "react";
import "./style.scss";
import getSymbolFromCurrency from "currency-symbol-map";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import { NormalButton } from "component/common";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { handleAddToCart } from "action/cartAct";
import AddToButton from "./addToCart";
const MenuCard = ({
  list,
  handleAddToCart,
  isAddingCart,
  ChefDetail,
  data,
}) => {
  // const [pageViewMore, setpageViewMore] = useState(3);
  const [learnMore, setLearnMore] = useState(false);
  const handleLearnmore = () => {
    setLearnMore(!learnMore);
  };
  console.log(list);
  let { preference = [] } = list;
  let imagePreference = list.dishCategory === "Veg" ? vegIcon : nonvegIcon;
  // const handleViewMore = () => {
  //   setpageViewMore();
  // };
  // const handleViewLess = () => {
  //   setpageViewMore(3);
  // };
  return (
    <>
      {!list.dishIsAvailable && (
        <>
          <div className="position-absolute blur-certfication p-3">
            <p className="blur mb-0 text-white">
              This Dish is Currently Unavailable
            </p>
          </div>
          <div className="custom-boxShadow-menu  bg-white-FF p-3 d-flex justify-content-start content-media ">
            <div className="d-flex ">
              <div className="foodimage">
                <img src={list.dishImage} alt="" />
                <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                  {/* {list.status} */}
                </div>
              </div>
              {/* <img src={list.dishImage} alt="" srcset="" className="w-100"/> */}
              <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                {list.dishIsAvailable}
              </div>
            </div>
            <div className=" menucard_description ml-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <div className=" foodname fs-16 fw-700 text-primary-color ">
                    {list.name.length < 15 ? (
                      list.name
                    ) : (
                      <React.Fragment>
                        {list.name.substring(0, 14)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.name}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/* <div>
                        {list.preference === "NonVeg" ? (
                          <span className="ml-2 d-flex align-items-center">
                            <img src={list.nonvegIcon} alt="" srcset="" />
                          </span>
                        ) : (
                          ""
                        )}
                      </div> */}

                <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                  <div className="nonVageIcon mx-2">
                    {imagePreference !== "" && (
                      <img src={imagePreference} alt="" srcset="" />
                    )}
                  </div>
                  <span>
                    {ChefDetail.currency
                      ? getSymbolFromCurrency(ChefDetail.currency)
                      : "₹"}

                    {list.price}
                  </span>
                </div>
              </div>
              {list.about && (
                <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                  {list.about.length < 35 ? (
                    list.about
                  ) : learnMore ? (
                    <React.Fragment>
                      {list.about}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        ...Less
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {list.about && list.about.substring(0, 34)}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        Read more...
                      </span>
                    </React.Fragment>
                  )}
                  {/* {learnMore ? (
                          list.AboutDetails.About
                        ) : (
                          
                        )} */}
                </p>
              )}

              {list.preference ? (
                <div className="d-flex my-2 type-menu-container flex-wrap">
                  {list.preference.length <= 2 ? (
                    list.preference.map((type, index) => (
                      <span
                        key={index}
                        className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                      >
                        {type}
                      </span>
                    ))
                  ) : (
                    <React.Fragment>
                      {list.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {list.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
      {list.deactivateId && (
        <>
          <div className="position-absolute blur-certfication">
            <p className="blur mb-0 text-white">
              This Chef’s Certification has been expired.
            </p>
          </div>
          <div className="custom-boxShadow-menu  bg-white-FF p-3 d-flex justify-content-start content-media">
            <div className="d-flex ">
              <div className="foodimage">
                <img src={list.dishImage} alt="" />
                <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                  {/* {list.status} */}
                </div>
              </div>
              {/* <img src={list.dishImage} alt="" srcset="" className="w-100"/> */}
              <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                {list.dishIsAvailable}
              </div>
            </div>
            <div className=" menucard_description ml-5">
              <div className="d-flex justify-content-between">
                <div className="d-flex ">
                  <div className="foodname fs-16  fw-700 text-primary-color ">
                    {list.name.length < 15 ? (
                      list.name
                    ) : (
                      <React.Fragment>
                        {list.name.substring(0, 14)}
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{list.name}</Tooltip>}
                          containerPadding={20}
                        >
                          <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                            ...Read more
                          </span>
                        </OverlayTrigger>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/* <div>
                       {list.preference === "NonVeg" ? (
                         <span className="ml-2 d-flex align-items-center">
                           <img src={list.nonvegIcon} alt="" srcset="" />
                         </span>
                       ) : (
                         ""
                       )}
                     </div> */}

                <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                  <div className="nonVageIcon mx-2">
                    {imagePreference !== "" && (
                      <img src={imagePreference} alt="" srcset="" />
                    )}
                  </div>
                  <span>
                    {ChefDetail.currency
                      ? getSymbolFromCurrency(ChefDetail.currency)
                      : "₹"}

                    {list.price}
                  </span>
                </div>
              </div>
              {list.about && (
                <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                  {list.about.length < 35 ? (
                    list.about
                  ) : learnMore ? (
                    <React.Fragment>
                      {list.about}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        ...Less
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {list.about && list.about.substring(0, 34)}{" "}
                      <span
                        className="text-yellow-C1 cursor-pointer"
                        onClick={handleLearnmore}
                      >
                        Read more...
                      </span>
                    </React.Fragment>
                  )}
                  {/* {learnMore ? (
                         list.AboutDetails.About
                       ) : (
                         
                       )} */}
                </p>
              )}

              {list.preference ? (
                <div className="d-flex my-2 type-menu-container flex-wrap">
                  {list.preference.length <= 2 ? (
                    list.preference.map((type, index) => (
                      <span
                        key={index}
                        className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                      >
                        {type}
                      </span>
                    ))
                  ) : (
                    <React.Fragment>
                      {list.preference.map(
                        (type, index) =>
                          index < 2 && (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          )
                      )}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 200, hide: 300 }}
                        overlay={
                          <Tooltip>
                            {list.preference.slice(2).join(", ")}
                          </Tooltip>
                        }
                        containerPadding={20}
                      >
                        <span
                          className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                          style={{
                            color: "#b58204",
                          }}
                        >
                          ...Read more
                        </span>
                      </OverlayTrigger>
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
      {list.dishIsAvailable && !list.deactivateId && (
        <>
          <div
            className={`bg-white-FF p-3 d-flex flex-column custom-boxShadow-menu ${
              list.typeOfMenu === "Advance Order"
                ? "min-height-165"
                : "min-height-137"
            }`}
          >
            <div className="bg-white-FF d-flex justify-content-start content-media ">
              {list.dishImage ? (
                <>
                  <div className="d-flex  ">
                    <div className="foodimage">
                      <img src={list.dishImage} alt="" />
                      <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                        {/* {list.status} */}
                      </div>
                    </div>

                    {/* <img src={list.dishImage} alt="" srcset="" className="w-100"/> */}
                    <div className="order_status_Screen text-primary-color fs-16 line-height-18 fw-700">
                      {list.dishIsAvailable}
                    </div>
                  </div>
                  <div className=" menucard_description ml-5">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex ">
                        <div className=" foodname fs-16 fw-700 text-primary-color ">
                          {list.name.length < 15 ? (
                            list.name
                          ) : (
                            <React.Fragment>
                              {list.name.substring(0, 14)}
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 300 }}
                                overlay={<Tooltip>{list.name}</Tooltip>}
                                containerPadding={20}
                              >
                                <span className=" text-yellow-C1 cursor-pointer mt-2 fs-11 fw-500">
                                  ...Read more
                                </span>
                              </OverlayTrigger>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      {/* <div>
                       {list.preference === "NonVeg" ? (
                         <span className="ml-2 d-flex align-items-center">
                           <img src={list.nonvegIcon} alt="" srcset="" />
                         </span>
                       ) : (
                         ""
                       )}
                     </div> */}

                      <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                        <div className="nonVageIcon mx-2">
                          {imagePreference !== "" && (
                            <img src={imagePreference} alt="" srcset="" />
                          )}
                        </div>
                        <span>
                          {ChefDetail.currency
                            ? getSymbolFromCurrency(ChefDetail.currency)
                            : "₹"}

                          {list.price}
                        </span>
                      </div>
                    </div>
                    {list.about && (
                      <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                        {list.about.length < 35 ? (
                          list.about
                        ) : learnMore ? (
                          <React.Fragment>
                            {list.about}{" "}
                            <span
                              className="text-yellow-C1 cursor-pointer"
                              onClick={handleLearnmore}
                            >
                              ...Less
                            </span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {list.about && list.about.substring(0, 34)}{" "}
                            <span
                              className="text-yellow-C1 cursor-pointer"
                              onClick={handleLearnmore}
                            >
                              Read more...
                            </span>
                          </React.Fragment>
                        )}
                        {/* {learnMore ? (
                         list.AboutDetails.About
                       ) : (
                         
                       )} */}
                      </p>
                    )}

                    {/* {list.typeOfMenu !== 'Advance Order' && ( */}
                    <div className="d-flex justify-content-between align-items-end">
                      {list.preference ? (
                        <div className="d-flex my-2 type-menu-container flex-wrap">
                          {list.preference.length <= 2 ? (
                            list.preference.map((type, index) => (
                              <span
                                key={index}
                                className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                              >
                                {type}
                              </span>
                            ))
                          ) : (
                            <React.Fragment>
                              {list.preference.map(
                                (type, index) =>
                                  index < 2 && (
                                    <span
                                      key={index}
                                      className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                                    >
                                      {type}
                                    </span>
                                  )
                              )}
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 200, hide: 300 }}
                                overlay={
                                  <Tooltip>
                                    {list.preference.slice(2).join(", ")}
                                  </Tooltip>
                                }
                                containerPadding={20}
                              >
                                <span
                                  className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                                  style={{
                                    color: "#b58204",
                                  }}
                                >
                                  ...Read more
                                </span>
                              </OverlayTrigger>
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}

                      {/* <AddToButton id={list._id} ChefDetail={ChefDetail} /> */}

                      {/* <NormalButton
                          primary
                          label="Add"
                          className="fw-700 fs-16 add-to-cart"
                          disabled={isAddingCart}
                          onClick={() => {
                            handleAddToCart(list._id)
                          }}
                        /> */}
                    </div>
                    {/* )} */}
                  </div>
                </>
              ) : (
                <div className=" menucard_description ms-0 w-100">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex ">
                      <div className=" foodname fs-16 fw-700 text-primary-color ">
                        {list.name.length < 15 ? (
                          list.name
                        ) : (
                          <React.Fragment>
                            {list.name.substring(0, 14)}
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 300 }}
                              overlay={<Tooltip>{list.name}</Tooltip>}
                              containerPadding={20}
                            >
                              <span className="cursor-pointer">...</span>
                            </OverlayTrigger>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    {/* <div>
                       {list.preference === "NonVeg" ? (
                         <span className="ml-2 d-flex align-items-center">
                           <img src={list.nonvegIcon} alt="" srcset="" />
                         </span>
                       ) : (
                         ""
                       )}
                     </div> */}

                    <div className=" order_status text-primary-color fs-16 line-height-18 fw-700 d-flex">
                      <div className="nonVageIcon mx-2">
                        {imagePreference !== "" && (
                          <img src={imagePreference} alt="" srcset="" />
                        )}
                      </div>
                      <span>
                        {ChefDetail.currency
                          ? getSymbolFromCurrency(ChefDetail.currency)
                          : "₹"}
                        {list.price}
                      </span>
                    </div>
                  </div>
                  {list.about && (
                    <p className="about-min-height fs-14 text-grey-7c line-height-16  fw-400">
                      {list.about.length < 35 ? (
                        list.about
                      ) : learnMore ? (
                        <React.Fragment>
                          {list.about}{" "}
                          <span
                            className="text-yellow-C1 cursor-pointer"
                            onClick={handleLearnmore}
                          >
                            ...Less
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {list.about && list.about.substring(0, 34)}{" "}
                          <span
                            className="text-yellow-C1 cursor-pointer"
                            onClick={handleLearnmore}
                          >
                            Read more...
                          </span>
                        </React.Fragment>
                      )}
                      {/* {learnMore ? (
                         list.AboutDetails.About
                       ) : (
                         
                       )} */}
                    </p>
                  )}
                  {/* {list.typeOfMenu !== 'Advance Order' && ( */}
                  <div className="d-flex justify-content-between align-items-end">
                    {/* {list.preference ? (
                      <div className="d-flex type-menu-container   mt-2">
                        {list.preference &&
                          list.preference
                            .slice(0, pageViewMore)
                            .map((type, index) => (
                              <span
                                key={index}
                                className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2 "
                              >
                                {type}
                              </span>
                            ))}
                      </div>
                    ) : null} */}
                    {list.preference ? (
                      <div className="d-flex my-2 type-menu-container flex-wrap">
                        {list.preference.length <= 2 ? (
                          list.preference.map((type, index) => (
                            <span
                              key={index}
                              className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                            >
                              {type}
                            </span>
                          ))
                        ) : (
                          <React.Fragment>
                            {list.preference.map(
                              (type, index) =>
                                index < 2 && (
                                  <span
                                    key={index}
                                    className="type_menu fs-14 mr-2 line-height-16 text-yellow-C1 bg-yellow-C2"
                                  >
                                    {type}
                                  </span>
                                )
                            )}
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 200, hide: 300 }}
                              overlay={
                                <Tooltip>
                                  {list.preference.slice(2).join(", ")}
                                </Tooltip>
                              }
                              containerPadding={20}
                            >
                              <span
                                className="text-yellow-C1 cursor-pointer mt-1 fs-14 fw-500"
                                style={{
                                  color: "#b58204",
                                }}
                              >
                                ...Read more
                              </span>
                            </OverlayTrigger>
                          </React.Fragment>
                        )}
                      </div>
                    ) : null}
                    {/* {list.preference.length && list.preference.length > 3 && (
                      <>
                        {pageViewMore && pageViewMore === 3 ? (
                          <div className="d-flex justify-content-center align-items-center order-served-viewmore">
                            <NormalButton
                              label="View Mores"
                              className="clear text-primary-color border-1 line-height-18 fs-14 bg-white fw-700 py-2 px-4"
                              onClick={handleViewMore}
                            />
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-items-center  order-served-viewmore">
                            <NormalButton
                              label="View less"
                              className="clear text-primary-color border-1 line-height-18 fs-14 bg-white fw-700 py-2 px-4"
                              onClick={handleViewLess}
                            />
                          </div>
                        )}
                      </>
                    )} */}
                    {/* <AddToButton id={list._id} ChefDetail={ChefDetail} /> */}
                    {/* 
                      <NormalButton
                        primary
                        label="Add"
                        className="fw-700 fs-16 add-to-cart"
                        disabled={isAddingCart}
                        onClick={() => {
                          handleAddToCart(list._id)
                        }}
                      /> */}
                  </div>
                  {/* // )} */}
                </div>
              )}
            </div>
            {list.typeOfMenu === "Advance Order" && (
              <div className="d-flex justify-content-between align-items-end">
                <p className="mt-1 fw-700 fs-16 text-primary-color mb-0 text-start line-height-16 ">
                  {list.AdvanceOrderType}
                </p>
                {/* <AddToButton id={list._id} ChefDetail={ChefDetail} /> */}

                {/* <NormalButton
                  primary
                  label="Add"
                  className="fw-700 fs-16 add-to-cart"
                  disabled={isAddingCart}
                  onClick={() => {
                    handleAddToCart(list._id)
                  }}
                /> */}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MenuCard;
