import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import earn from 'assets/images/banner_postLogin.png'
import { ListChef } from '../common/Listchef'
import { Accordion } from '../common/accordion'
import { Table } from '../../component/common/Table'
import { CardsChef } from '../common/CardsChef'
import './style.scss'

const HomeClass = ({ userLocation }) => {
  // const userLocation = {}
  console.log(userLocation, 'user location')
  return (
    <div className="Home bg-gold-ff">
      <div className="container py-lg-5 py-4">
        <div className="row banner">
          <div className="col-md-7 col-12 mt-lg-5 mt-md-4 order-md-1 order-2 mt-2 pt-5">
            <div className="home-banner">
              <p className="mb-lg-4 mb-md-2 mt-md-0 mt-3 pb-3">
                Make your passion your business
              </p>
              <p className="mt-lg-3 mt-md-2 mb-lg-4 mb-md-2 pb-3">
                Become an entrepreneur from the comfort of your own home
              </p>
              <p className="mt-lg-3 mt-md-2 mb-lg-5 mb-md-5">
                Earn when you want to
              </p>
            </div>
            {/* <p className="fs-18 line-height-20 fw-700 text-black-25 mb-4 mb-lg-3">
              Ready to be your own boss and cook whenever you want?
            </p> */}
          </div>
          <div className="col-12 col-md-5 mt-md-0 mt-5 d-flex justify-content-center order-md-2 order-1">
            <img className="mw-100" src={earn} alt="Logo" />
          </div>
        </div>
      </div>
      <div className="bg-yellow-ff">
        <CardsChef />
      </div>
      <div className="bg-gray-ff">
        <ListChef title="So Let’s Get Started" />
      </div>
      <Table country={userLocation.country} />
      <Accordion />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userLocation: state.commonStore.userLocation,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

let component = HomeClass

export const HomeComp = connect(mapStateToProps, mapDispatchToProps)(component)
