import React from 'react'
import './style.scss'
import TalkIconimg from '../../../assets/svg/icon-track.svg'
import {
  ProfileIcon,
  MarkerIcon,
  MenuIcon,
  OrderIcon,
  RatingIcon,
  TalkIcon,
  LogoutIcon,
} from '../icons/Icons'
import { convertToSlug } from 'service/helperFunctions'
import { FRONT_END_PORT } from 'utils/constants'
import { logout } from 'service/utilities'

function SideBar({ active, setActive, data }) {
  let userDetails = data
  console.log(userDetails)
  return (
    <div id="sideBar-chef">
      <div className="outline">
        <ul>
          {/* <li
            className={`${active === 'chefOrder' ? 'active' : ''}`}
            onClick={() => setActive('chefOrder')}
          >
            <i>
              <OrderIcon color={active === 'orders' ? 'white' : '#08316d'} />
            </i>
            <span>My Orders</span>
          </li> */}
          <li
            className={`${active === 'basicDetail' ? 'active' : ''}`}
            onClick={() => setActive('basicDetail')}
          >
            <i>
              <ProfileIcon
                color={active === 'basicDetail' ? 'white' : '#08316d'}
              />
            </i>
            <span>My Profile</span>
          </li>
          <li
            className={`${active === 'customerOrder' ? 'active' : ''}`}
            onClick={() => setActive('customerOrder')}
          >
            <i>
              <OrderIcon color={active === 'orders' ? 'white' : '#08316d'} />
            </i>
            <span>Orders</span>
          </li>
          <li>
            <i>
              {/* <img src={TalkIconimg}/> */}
              <TalkIcon color={active === 'talk' ? 'white' : '#08316d'} />
            </i>
            <span>
              {!!userDetails && (
                <a
                  href={`mailto:support@thecheflane.com?subject=You have a message from Customer - ${userDetails?.firstName} ${userDetails?.lastName}`}
                >
                  Email Admin
                </a>
              )}
            </span>
          </li>
          {/* <li onClick={() => logout()}>
            <i>
              <LogoutIcon color={active === 'review' ? 'white' : '#08316d'} />
            </i>
            <span>Logout</span>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default SideBar
