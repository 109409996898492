import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import Form from "react-bootstrap/Form";
import { Button } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { NormalButton, Marker } from "component/common";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import GoogleMapReact from "google-map-react";
import { Toast } from "service/toast";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { chefUpdate } from "action/ChefDetailsAct";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";
import * as L from "leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import markerIcon from "assets/svg/marker.svg";
import currentLocation from "assets/svg/currentLocation.svg";

const provider = new OpenStreetMapProvider();

function AdressDetail({
  chefUpdate,
  formData,
  setFormData,
  handleFormChange,
  setFormDataAddress,
}) {
  const [updateValidator, setUpdateValidator] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [locationGoogle, setLocationGoogle] = useState({});
  const [defaultZoom, setDefaultZoom] = useState(18);
  const [getLocationLoader, setGetLocationLoader] = useState(false);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="error-message fs-12">{message}</span>
      ),
      autoForceUpdate: this,
    })
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!formData.AddressDetails?.location?.lat) getLocation();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  var optionsPosition = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    setGetLocationLoader(true);
    setAddress("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        optionsPosition
      );
      // setDefaultZoom(22);
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
        time: 2000,
      });
    }
  };
  const showPosition = (position) => {
    console.log(position, "newww currrrrt");
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: lat,
      long: lon,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    // this.timer = setTimeout(() => {
    navigatorGeoLocation(lat, lon).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      console.log(address);
      handleLocationGoogle(address);
      setGetLocationLoader(false);
    });
    // }, 100)
    // this.displayLocation(lat, lon)
  };
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  };
  const submitAddressDetails = async () => {
    if (validator.current.allValid()) {
      setIsLoading(true);
      console.log(formData, "basicDetails");
      await chefUpdate(formData)
        .then(() => {
          setIsLoading(false);
          handleFormChange("menuDetails");
        })
        .catch((e) => setIsLoading(false));
    } else {
      validator.current.showMessages();
      setUpdateValidator(!updateValidator);
      Toast({
        type: "error",
        message: "Please fill up all the required fields",
        time: 3000,
      });
      scrollTop();
    }
  };
  const handleLatLng = (data) => {
    console.log(data, "data");
    let { AddressDetails } = formData;
    AddressDetails.location = {
      lat: data.lat,
      long: data.lng,
    };
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
  };
  const handleMApClick = ({ lat, lng }) => {
    // this.timer = setTimeout(() => {
    let userLocation = navigatorGeoLocation(lat, lng).then((response) => {
      let data = response.results;
      let address = extractAddressFields(data[0].address_components);
      console.log(address);
      handleLocationGoogle(address);
    });
    console.log("navvvv", userLocation);
    // }, 100)
  };
  const handleLocationGoogle = (e) => {
    console.log(e);
    let { country, city, pincode, region, area, state, street } = e;
    let { AddressDetails } = formData;
    AddressDetails.country = region;
    AddressDetails.city = city;
    AddressDetails.area = area;
    AddressDetails.pincode = pincode;
    AddressDetails.state = state;
    AddressDetails.address1 = street;
    // setFormData({ ...formData, AddressDetails })
    setFormData((prevState) => ({
      ...prevState,
      AddressDetails,
    }));
    scrollTop();
  };
  const scrollTop = () => {
    let element = document.getElementById("sub-content");
    console.log(element, "element");
    element.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "nearest",
    });
  };
  let {
    AddressDetails: { location },
  } = formData;
  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      enableHighAccuracy: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      // minZoom: 11,
      // maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: false,
      draggable: true,
      draggableCursor: "pointer",
    };
  };
  const onMarkerDragEnd = (coord) => {
    let newRegion = {
      latitude: parseFloat(coord.latitude),
      longitude: parseFloat(coord.longitude),
    };
    handleMApClick(coord.latitude, coord.longitude);
  };
  return (
    <div className="address_detail_container">
      <div className="container">
        <div className="address_detail_inner_container">
          {/* <div className="header">
            <div>Address Details</div>
            <hr></hr>
          </div> */}
          <div className="body mt-0 mb-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <div className="header">
                      Map{" "}
                      <span className="fs-16 fw-500">
                        (Please select location on the map)
                      </span>
                    </div>
                    <hr />
                  </div>
                  {validator.current.message(
                    "Location",
                    formData.AddressDetails.location,
                    "required"
                  )}
                  <div className="col-xs-12 col-sm-12">
                    <div id="map-outline" className="position-relative">
                      <div id="map">
                        <GoogleMapReact
                          center={
                            location.lat
                              ? {
                                  lat: Number(location.lat),
                                  lng: Number(location.long),
                                }
                              : {
                                  lat: 13.0827,
                                  lng: 80.2707,
                                }
                          }
                          defaultZoom={18}
                          zoom={defaultZoom}
                          onClick={(e) => {
                            let { lat, lng } = e;
                            let { AddressDetails } = formData;
                            AddressDetails.location = {
                              lat: lat,
                              long: lng,
                            };
                            setFormData((prevState) => ({
                              ...prevState,
                              AddressDetails,
                            }));
                            handleMApClick(e);
                          }}
                          options={getMapOptions}
                          // yesIWantToUseGoogleMapApiInternals={true}
                        >
                          {location && (
                            <Marker
                              lat={location.lat}
                              lng={location.long}
                              name="My Marker"
                              color=""
                              isInfo={true}
                              infoWindowOpen={infoWindowOpen}
                              infoWindowContent={
                                <div className="info-outline-small d-flex justify-content-center">
                                  <div className="">
                                    <span className="text-red fs-12">
                                      You are here!
                                    </span>
                                  </div>
                                  <span className="rectang"></span>
                                </div>
                              }
                              children={
                                <img
                                  src={markerIcon}
                                  // onClick={() => {
                                  //   setInfoWindowOpen(!infoWindowOpen)
                                  // }}
                                  alt="icon"
                                />
                              }
                              // draggable={true}
                              // onDragEnd={(e) =>
                              //   onMarkerDragEnd(e.nativeEvent.coordinate)
                              // }
                            />
                          )}
                        </GoogleMapReact>
                        <LocationSearchInput
                          isMap={"map"}
                          address={address}
                          handleAddress={(e) => handleLocationGoogle(e)}
                          placeholder=""
                          handleSelect={(address) => setAddress(address)}
                          handleLatLng={handleLatLng}
                          handleFind={() => {}}
                          handleCurrentLocation={() => getLocation()}
                          getLocationLoader={getLocationLoader}
                        />
                        <div
                          id="custom-current-loaction"
                          className="verify d-flex align-items-center newEdit cursor-pointer"
                          onClick={() => getLocation()}
                          title="Current location"
                        >
                          <img
                            src={currentLocation}
                            alt="icons"
                            className={
                              getLocationLoader ? "opacity-5" : "opacity-1"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12">
                    <div className="header">Address Details</div>
                    <hr />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Country"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.country = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.country}
                      />
                      {validator.current.message(
                        "Country",
                        formData.AddressDetails.country,
                        "required|min:3|alpha_space"
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter State"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.state = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.state}
                      />
                      {/* {validator.current.message(
                        'State',
                        formData.AddressDetails.state,
                        'required|alpha_space',
                      )} */}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>
                        Address line 1<sup>*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Address"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.address1 = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.address1}
                      />
                      {validator.current.message(
                        "Address",
                        formData.AddressDetails.address1,
                        "required"
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>Address line 2</Form.Label>
                      <Form.Control
                        // disabled={true}
                        type="text"
                        placeholder="Enter Optional Address"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.address2 = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.address2}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Form.Group>
                      <Form.Label>
                        Area
                        {/* <sup>*</sup> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area Name"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.area = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.area}
                      />
                      {/* {validator.current.message(
                        "Area",
                        formData.AddressDetails.area,
                        "required"
                      )} */}
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter City"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.city = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.city}
                      />
                      {validator.current.message(
                        "City",
                        formData.AddressDetails.city,
                        "required"
                      )}
                    </Form.Group>
                  </div>

                  <div className="col-xs-12 col-sm-6">
                    <Form.Group>
                      <Form.Label>Pin Code</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Enter Pin Code"
                        onChange={(e) => {
                          let {
                            target: { value },
                          } = e;
                          let { AddressDetails } = { ...formData };
                          AddressDetails.pincode = value;
                          setFormData((prevState) => ({
                            ...prevState,
                            AddressDetails,
                          }));
                        }}
                        value={formData.AddressDetails.pincode}
                      />
                      {/* {validator.current.message(
                        'Pincode',
                        formData.AddressDetails.pincode,
                        'required',
                      )} */}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <NormalButton
            label="Back"
            className="white-btn mr-3"
            onClick={() => handleFormChange("basicDetails")}
          />
          <span className="m-2" />

          <NormalButton
            primary
            label="Save"
            className=""
            disabled={isLoading}
            onClick={() => submitAddressDetails()}
          />
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefUpdate,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(AdressDetail);
