import React from "react";
import nonvegIcon from "assets/images/nonveg.png";
import vegIcon from "assets/images/veg.png";
import eggIcon from "assets/images/egg.png";
export const PageIcons = () => (
  <div className="container-fluid bg-yellow-BF">
    <div className="row">
      <div className="col-12 d-flex justify-content-end">
        <span className="d-flex align-items-center mx-3">
          <img src={vegIcon} alt="icon" />
          <p className="mb-0 mx-2"> VEG</p>
        </span>
        <span className="d-flex align-items-center mx-3">
          <img src={nonvegIcon} alt="icon" />
          <p className="mb-0 mx-2"> NON-VEG</p>
        </span>
        {/* <span className="d-flex align-items-center mx-3">
                <img src={eggIcon} alt="icon" />
                <p className="mb-0 mx-2"> EGG</p>
            </span> */}
      </div>
    </div>
  </div>
);

// export default PageIcons;
