import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cheflist from 'assets/images/cheflist.png'
import vegIcon from 'assets/images/veg.png'
import { NormalButton } from "component/common/";

const OrderServedAvailable = () => {
  return (
    <>
         <div className="row">
                <div className="col-xs-12 col-sm-12 col-lg-8 order-image-column">
                    <div className="d-flex outline flex-column">
                      <div className="">
                        <div className="d-flex ">
                          <div className="">
                            <div className="foodimage">
                              <img src={cheflist} alt="" />
                            </div>
                          </div>
                          <div className="menucard_description ml-3">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex ">
                                <div className="foodname fs-18 line-height-20 fw-700 text-black-25 d-flex">
                                    Double cheese Burger and Fries
                                    <div className="nonVageIcons ms-3">
                                        <span className="mx-0">
                                        <img src={vegIcon} alt="icons" />
                                        </span>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mt-4">
                                <div className="d-flex flex-wrap w-100 me-4">
                                    <h4 className="fs-16 w-100 order-rate pb-2 text-black-25 fw-700"> ₹700 <span className="fs-8 ms-2 text-green-C1 bg-green-C2">paid</span></h4>
                                    <div className="d-flex">
                                        <p className="fs-14 fw-700 text-grey-7c me-4">Cheese burger X 2 | Potato wedges X 1 | Salad X 2</p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-4 ps-4">
                      <div className="foodname fs-14 line-height-20 fw-700  text-grey-7c d-flex">
                       Ordered on: 24th Oct 2021, at 5:00 PM
                      </div>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4 order-served-viewmore">
                           <NormalButton
                              label="View More"
                              className="clear text-primary-color border-1 line-height-18 fs-16 bg-white fw-700 py-2 px-4"
                            />
                 </div>
            </div>
    </>
      
  )
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
    },
    dispatch,
  )
}
export default connect(null, mapDispatchToProps)(OrderServedAvailable)
