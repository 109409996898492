import React, { useState, useEffect } from "react";
import { login } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { validationRules } from "./validate";
import { NormalButton } from "component/common/NormalButton";
import SignUpOptions from "./SignUpOptions";
import { useLocation } from "react-router-dom";
import OrSeperator from "component/common/OrSeperator";
import { logout } from "service/utilities";
import { Toast } from "service/toast";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "service/api";
const LoginClass = (props) => {
  const [logindetails, setLoginDetails] = useState({
    emailIdOrMobileNumber: "",
    password: "",
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [signInGoogle, setGoogleSignIn] = useState(false);
  const [error, setErrors] = useState({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  //handle Change
  const handleChange = ({ target: { name, value } }) => {
    const tempErrors = { ...error };
    tempErrors && tempErrors[name] && (tempErrors[name] = undefined);
    setLoginDetails((prevState) => ({ ...prevState, [name]: value }));
    setErrors({ ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());
    if (fieldInvalidList !== undefined) {
      setErrors({ ...fieldInvalidList });
    }
    return !fieldInvalidList;
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.key === "enter") {
      handleSubmit(e);
    }
  };
  const facebookResponse = (response) => {
    console.log(response, "responseresponse");
    //debugger;
    if (response?.userID) {
      const tokenBlob = new Blob(
        [
          JSON.stringify(
            {
              accessToken: response.accessToken,
              userId: response.userID,
              userTypeRole: query.get("userTypeRole"),
              service: "LISTING",
            },
            null,
            2
          ),
        ],
        { type: "application/json" }
      );
      console.log(tokenBlob, "tokenBlob");
      const options = {
        method: "POST",
        body: tokenBlob,
        mode: "cors",
        cache: "default",
      };
      console.log(options, "options");
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}auth/facebook/login`,
        options
      ).then((r) => {
        const token = r.headers.get("x-auth-token");
        r.json().then(({ data, msg, message }) => {
          //debugger;
          if (message === "Access Token must be required") {
            Toast({ type: "error", message: message, time: 3000 });
          } else if (msg === "Login Successful") {
            Toast({ type: "success", message: msg && msg, time: 3000 });
          } else {
            Toast({
              type: "success",
              message: msg ? msg : message,
              time: 3000,
            });
          }
          localStorage.setItem("userId", data?._id);
          localStorage.setItem("name", data?.firstName);
          data && localStorage.setItem("userDetailsWeb", JSON.stringify(data));
          localStorage.setItem("userTypeRole", query.get("userTypeRole"));
          localStorage.setItem("authToken", response?.tokenId);
          if (data?.userTypeRole === "customer") {
            history.replace("/");
          } else if (data?.userTypeRole === "chef") {
            if (data?.status === "APPROVED") {
              history.replace("/index/activeProfile");
            } else {
              history.replace("/index/profile");
            }
          }
        });
      });
    }
  };

  const googleResponse = (response) => {
    // let userInfo = {
    //   name: response.profileObj.name,
    //   emailId: response.profileObj.email,
    // };
    // localStorage.setItem("userTypeRole", query.get("userTypeRole"));
    // localStorage.setItem("name", "LISTING");
    // setGoogleSignIn({ userInfo });
    if (response?.profileObj) {
      const tokenBlob = new Blob(
        [
          JSON.stringify(
            {
              token: response.tokenId,
              userTypeRole: query.get("userTypeRole"),
              service: "LISTING",
            },
            null,
            2
          ),
        ],
        { type: "application/json" }
      );
      console.log(tokenBlob, "tokenBlob");
      const options = {
        method: "POST",
        body: tokenBlob,
        mode: "cors",
        cache: "default",
      };
      console.log(options, "options");
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}auth/google/login`,
        options
      ).then((r) => {
        const token = r.headers.get("x-auth-token");
        r.json().then((data, message) => {
          //debugger;
          console.log("datadata", data);
          // if (data.message === "Account Deactivated") {
          //   alert("Account Deactivated");
          //   Toast({
          //     type: "error",
          //     message: data.message,
          //     time: 3000,
          //   });
          // }
          if (
            data.message === "Access Token must be required" ||
            data.message === "Account Deactivated"
          ) {
            Toast({ type: "error", message: data.message, time: 3000 });
          } else if (data.msg === "Login Successful") {
            Toast({
              type: "success",
              message: data.msg && data.msg,
              time: 3000,
            });
          } else {
            Toast({
              type: "success",
              message: data.msg ? data.msg : data?.message,
              time: 3000,
            });
          }
          // Toast({
          //   type: "success",
          //   message: data.msg ? data?.msg : data?.message,
          //   time: 3000,
          // });
          localStorage.setItem("userId", data?.data?._id);
          localStorage.setItem("name", data?.data?.firstName);
          data?.data &&
            localStorage.setItem("userDetailsWeb", JSON.stringify(data?.data));
          localStorage.setItem("userTypeRole", query.get("userTypeRole"));
          localStorage.setItem("authToken", response?.tokenId);

          if (data?.data?.userTypeRole === "customer") {
            history.replace("/");
          } else if (data?.data?.userTypeRole === "chef") {
            if (data?.data?.status === "APPROVED") {
              history.replace("/index/activeProfile");
            } else {
              history.replace("/index/profile");
            }
          }
          //window.location.reload();
        });
      });
    }
  };
  const onFailure = (error) => {
    alert(error);
  };
  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      emailIdOrMobileNumber: logindetails.emailIdOrMobileNumber,
      password: logindetails.password,
      userTypeRole: query.get("userTypeRole"),
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    props
      .loginApiCall(body)
      .then(({ data, checkLicenseExpired }) => {
        console.log(checkLicenseExpired);
        setisButtonClicked(false);
        localStorage.setItem("userId", data._id);
        localStorage.setItem("name", data.name);
        localStorage.setItem("userTypeRole", query.get("userTypeRole"));

        if (data.userTypeRole === "customer") {
          history.replace("/");
        } else if (data.userTypeRole === "chef") {
          if (data.status === "APPROVED") {
            history.replace("/index/activeProfile");
          } else {
            history.replace("/index/profile");
          }
        }
      })
      .catch(() => {
        setisButtonClicked(false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!!token) {
      logout();
    }
  }, []);

  function onChangeCaptcha(value) {
    if (value === null) {
      setreCaptchaFailed(true);
    } else {
      setreCaptchaFailed(false);
    }
  }
  const [reCaptchaFailed, setreCaptchaFailed] = useState(true);
  return (
    <div className="row">
      <div className="col-md-8 col-12 mx-auto ">
        <h3 className="fw-700 fs-24 line-height-28 text-black-18 text-md-start text-center mb-4">
          Login
        </h3>
        <div className="d-flex justify-content-center align-items-center mb-4 px-2 google-facebook">
          <div className="col-12 col-sm-6 google-signin me-2">
            <GoogleLogin
              clientId="377800051763-mrqr8beaufh15kikrp5tnavvnj6mmvc1.apps.googleusercontent.com"
              //clientId="922273041299-pe1dhgn4av613lva02i4iv7v0hf410fn.apps.googleusercontent.com"
              buttonText="Login with Google"
              onSuccess={googleResponse}
              onFailure={googleResponse}
              cookiePolicy={"single_host_origin"}
              prompt="select_account"
            />
          </div>
          <div className="col-12 col-sm-6 facebook-signin">
            <FacebookLogin
              // appId="556836402559037"
              appId="335006581985920"
              autoLoad={false}
              fields="name,email,picture"
              callback={facebookResponse}
              icon="fa-facebook"
            />
          </div>
        </div>
        <div className="fs-18 text-black-25 mb-5 fw-700 line-border px-2">
          <span>or</span>
        </div>
      </div>
      <div className="col-md-8 col-12 mx-auto">
        <div className="mt-3 pt-1">
          <label>Email ID</label>
          <div className="input-group fs-16">
            <input
              className="form-control"
              name="emailIdOrMobileNumber"
              placeholder="Enter email..."
              value={logindetails.emailIdOrMobileNumber}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
          </div>
          {error.emailIdOrMobileNumber && (
            <ErrorComponent message={error.emailIdOrMobileNumber[0]} />
          )}
        </div>
        <div className="mt-4 pt-1">
          <div className="d-flex justify-content-between">
            <label>Password</label>
            <p
              className="mb-0 text-primary-color fs-14 fw-600 cursor-pointer"
              onClick={() =>
                history.push(
                  `/auth/resetpassword?userTypeRole=${query.get(
                    "userTypeRole"
                  )}`
                )
              }
            >
              Forgot?
            </p>
          </div>

          <div className="input-group password-field position-relative">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Enter password..."
              value={logindetails.password}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
            <div className="input-group-addon right">
              <i
                onClick={() => setPasswordVisible(!passwordVisible)}
                className={`cursor-pointer text-gold icon-${
                  passwordVisible ? "eye" : "eye-hide"
                } fs-18`}
              />
            </div>
          </div>
          {error.password && <ErrorComponent message={error.password[0]} />}
          <div className="mt-3 pt-1 d-flex justify-content-center">
            <ReCAPTCHA
              sitekey="6Lf7Po8gAAAAAG7XwUxeNjPaBX0wNlZQ_ume6px_"
              onChange={onChangeCaptcha}
            />
          </div>

          <div className="w-100 py-1">
            <NormalButton
              primary
              label="Continue"
              className="my-md-5 my-4 w-100 fw-700"
              onClick={handleSubmit}
              disabled={isButtonClicked || reCaptchaFailed}
              //disabled={isButtonClicked}
            />
          </div>
        </div>
      </div>

      <div className="col-md-10 col-12 text-center mb-3 mx-auto">
        <OrSeperator title="Don’t have an account? Signup" />
      </div>
      <div className="col-md-8 col-12 mx-auto">
        <SignUpOptions disabled={isButtonClicked} />
      </div>
      <div className="col-12 text-center">
        <h6 className="conditions-apply fs-16 mb-0">
          By continuing, you agree to the{" "}
          <a
            target="_blank"
            className="text-primary-color cursor-pointer terms"
            href="/index/t&c"
          >
            Terms & Conditions
          </a>{" "}
          of use and{" "}
          <a
            target="_blank"
            className="text-primary-color cursor-pointer terms"
            href="/index/pp"
          >
            Privacy Policy
          </a>
          .
        </h6>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginApiCall: login,
    },
    dispatch
  );
};

let component = LoginClass;

export const LoginComp = connect(null, mapDispatchToProps)(component);
