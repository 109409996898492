import React from "react";
import "./style.scss";
import TalkIconimg from "../../../assets/svg/icon-track.svg";
import {
  ProfileIcon,
  MarkerIcon,
  MenuIcon,
  OrderIcon,
  RatingIcon,
  TalkIcon,
} from "../icons/Icons";
import { convertToSlug } from "service/helperFunctions";
import { FRONT_END_PORT } from "utils/constants";

function SideBar({ active, setActive, data }) {
  let userDetails = data;
  console.log(userDetails);
  let chefID = userDetails._id;
  return (
    <div id="sideBar-chef">
      <div className="outline">
        <ul>
          <li
            className={`${active === "basicDetail" ? "active" : ""}`}
            onClick={() => setActive("basicDetail")}
          >
            <i>
              <ProfileIcon
                color={active === "basicDetail" ? "white" : "#08316d"}
              />
            </i>
            <span>Profile</span>
          </li>
          <li
            className={`${active === "address" ? "active" : ""}`}
            onClick={() => setActive("address")}
          >
            <i>
              <MarkerIcon color={active === "address" ? "white" : "#08316d"} />
            </i>
            <span>Address</span>
          </li>
          <li
            className={`${active === "menu" ? "active" : ""}`}
            onClick={() => setActive("menu")}
          >
            <i>
              <MenuIcon color={active === "menu" ? "white" : "#08316d"} />
            </i>
            <span>Menu</span>
          </li>

          {/* <li className={`${active === 'chefOrder' ? 'active' : ''}`}
            onClick={() => setActive('chefOrder')}>
                        <i>
                            <OrderIcon color={active === 'orders'? 'white':'#08316d'} />
                        </i>
                        <span>
                        Orders
                        </span>
          </li> 
          <li  className={`${active === 'rateReview' ? 'active' : ''}`}
            onClick={() => setActive('rateReview')}>
                        <i>
                            <RatingIcon color={active === 'rateReview'? 'white':'#08316d'} />
                        </i>
                        <span>
                        Ratings and Review
                        </span>
          </li>  */}
          {/* <li>
                        <i>
                            <OrderIcon color={active === 'orders'? 'white':'#08316d'} />
                        </i>
                        <span>
                        Orders
                        </span>
                    </li>
                    <li>
                        <i>
                            <RatingIcon color={active === 'review'? 'white':'#08316d'} />
                        </i>
                        <span>
                        Ratings and Review
                        </span>
                    </li> */}
          <li>
            <i>
              {/* <img src={TalkIconimg}/> */}
              <TalkIcon color={active === "talk" ? "white" : "#08316d"} />
            </i>
            <span>
              {!!userDetails.businessName && (
                <a
                  href={`mailto:support@thecheflane.com?subject=You have a message from chef - ${
                    userDetails.businessName
                  }&body=Chefs webpage: ${FRONT_END_PORT}${convertToSlug(
                    userDetails.businessNameSlug
                  )}%0D%0A %0d%0aPlease do not delete above content %0D%0A %0d%0aHi, %0D%0A`}
                >
                  Email Admin
                </a>
              )}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
