import React, { Component } from "react";
import "./navbar.scss";
import logo from "assets/svg/logo.svg";
import arrowright from "assets/svg/arrowright.svg";
import { history } from "service/helpers";
import { NormalButton, NormalToggleSwitch } from "component/common";
import { Modal } from "reactstrap";
import ErrorComponent from "component/common/ErrorComponent";
import { EventEmitter } from "service/helpers/EventEmitter";
import validate from "service/validation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { io } from "socket.io-client";
import { logout } from "service/utilities";
import LocationSearchInput from "component/common/locationSearchAutocomplete";
import { Toast } from "service/toast";
import { navigatorGeoLocation } from "component/common/navigatorGeoLocation";
import { extractAddressFields } from "service/helperFunctions";
import { userLocationType } from "service/actionType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  chefChangePasswordPostLogin,
  userChangePasswordPostLogin,
  getNotificationCount,
  getNotificationList,
  userSwitch,
} from "action/AuthAct";
const google = window.google;

export class NavbarClass extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }
  state = {
    userName: null,
    token: null,
    active: false,
    isPopupOpen: false,
    passwordDetails: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    currentPasswordVisible: false,
    newPasswordVisible: false,
    reEnterpasswordVisible: false,
    errors: {},
    userDetails: {},
    isButtonClicked: false,
    notifyCount: 0,
    locations: {},
    address: "",
    showSearch: false,
    userLocation: "",
    currentUser: "",
    currentToggle: true,
    showShare: true,
  };
  componentDidMount = () => {
    //debugger
    if (window.matchMedia("(min-width: 992px)").matches) {
      this.setState({ showShare: true });
    } else {
      this.setState({ showShare: false });
    }
    let authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.setState({
        token: authToken,
      });
      if (localStorage.getItem("userDetailsWeb")) {
        let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
        if (userDetails) {
          console.log("userDetails.firstName", userDetails.firstName);
          this.setState({
            userDetails: { ...userDetails },
            userName: userDetails.firstName,
            currentUser: userDetails.userTypeRole,
          });
        }
        if (userDetails?._id) {
          EventEmitter.init(userDetails._id);
          this.getNotificationCountApi();
          EventEmitter.addEventListener(
            "navbar-menu",
            this.getNotificationCountApi
          );
        }
      }
    }
    this.timer = setTimeout(() => {
      this.setState({ showSearch: true });
    }, 1500);
    let { location } = history;
    let isAuthRoute = location.pathname.includes("/auth") ? true : false;
    if (!isAuthRoute) {
      if (this.state.userLocation === "") {
        this.getLocation();
      }
    }
    document.addEventListener("click", this.handleClickOutside, false);
    return () => {
      document.removeEventListener("click", this.handleClickOutside, false);
    };
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
    EventEmitter.removeEvent("navbar-menu");
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
  }

  handleClickOutside = (event) => {
    let { showShare } = this.state;
    //debugger
    if (window.matchMedia("(max-width: 992px)").matches) {
      if (
        this.wrapperRef.current &&
        !this.wrapperRef.current.contains(event.target)
      ) {
        this.setState({ showShare: false });
      } else {
        this.setState({ showShare: !showShare });
      }
    } else {
      this.setState({ showShare: true });
    }
  };
  getNotificationCountApi = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    let {
      notificationList = {},
      getNotificationCount,
      getNotificationList,
    } = this.props;
    getNotificationCount(userDetails._id).then((data) => {
      this.setState({ notifyCount: data.notificationCount });
      let { pageMeta = {} } = notificationList || {};
      if (pageMeta && pageMeta.page == 1) {
        getNotificationList(userDetails._id, { page: 1 });
      }
    });
  };

  handleSwitchUsers = () => {
    let { currentUser } = this.state;
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    console.log(userDetails, "bbbb");
    !userDetails && logout();
    let body = {
      userTypeRole: currentUser === "chef" ? "customer" : "chef",
      chefId:
        currentUser === "chef" ? userDetails?._id : userDetails?.masterChefId,
    };
    if (
      currentUser === "chef" ||
      (currentUser === "customer" && !!userDetails?.masterChefId)
    ) {
      this.props
        .userSwitch(body)
        .then((data) => {
          console.log(data);
          if (data !== null) {
            if (data?.userTypeRole === "customer") {
              history.push("/");
              // window.location.reload()
            } else if (data?.deactivateId === true) {
              logout();
            } else {
              history.push("/index/profile");
              // window.location.reload()
            }
            this.setState({
              currentUser: data?.userTypeRole,
              currentToggle: true,
            });
          } else {
            this.setState({
              currentToggle: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      localStorage.clear();
      history.push("/index/home");
      window.location.reload(true);
    }
  };

  handlelogout = () => {
    // localStorage.clear();
    // history.push("/");
    // window.location.reload();
    logout();
  };

  //handle Dropdown
  handleDropdown = () => {
    if (!this.state.active) {
      document.addEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    } else {
      document.removeEventListener(
        "click",
        this.handleOutsideClickForProfile,
        false
      );
    }
    this.setState((prevState) => ({
      active: !prevState.active,
    }));
  };

  //handle Outside Click For Profile
  handleOutsideClickForProfile = (e) => {
    if (this.node != null) {
      if (this.node.contains(e.target)) {
        return;
      }
    }
    this.handleDropdown();
  };

  handlePopup = () => {
    this.setState({
      isPopupOpen: !this.state.isPopupOpen,
    });
  };

  //handle Cancel
  handleCancel = () => {
    this.setState({
      isButtonClicked: false,
      passwordDetails: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isPopupOpen: false,
      errors: {},
    });
  };

  //handle Change
  handleChange = ({ target: { name, value } }) => {
    let { passwordDetails, errors } = this.state;

    const tempErrors = { ...errors };

    tempErrors[name] = undefined;
    passwordDetails[name] = value;
    this.setState({
      passwordDetails: {
        ...passwordDetails,
      },
      errors: {
        ...tempErrors,
      },
    });
  };

  //validation Rules
  validationRules = () => {
    let passwordValidation = {
      // format: {
      //     pattern:
      //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_#%*?&])[A-Za-z\d@_#$!%*?&]*$/,
      //     flags: "i",
      //     message:
      //         "^Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
      // },
      length: {
        minimum: 8,
        tooShort: "^Must be atleast 8 characters",
        maximum: 12,
        tooLong: "^Must contain less than 12 character",
      },
    };
    return {
      // oldPassword: {
      //   presence: {
      //     allowEmpty: false,
      //     message: "^Current Password is required",
      //   },
      // },
      newPassword: {
        presence: {
          allowEmpty: false,
          message: "^New Password is required",
        },
        ...passwordValidation,
      },
      confirmPassword: {
        presence: {
          allowEmpty: false,
          message: "^Confirm password is required",
        },
        equality: {
          attribute: "newPassword",
          message: "^Both password must match",
          comparator: function (v1, v2) {
            return JSON.stringify(v1) === JSON.stringify(v2);
          },
        },
      },
    };
  };

  //validate Fields
  validateFields = (data) => {
    const fieldInvalidList = validate(data, this.validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      this.setState({
        errors: {
          ...errors,
          ...fieldInvalidList,
        },
      });
    }

    return !fieldInvalidList;
  };

  //handle Submit
  handleSubmit = () => {
    let { passwordDetails, userDetails } = this.state;
    let body = {
      ...passwordDetails,
    };
    if (userDetails.userTypeRole === "chef") {
      body.chefId = userDetails._id;
    } else {
      body.userId = userDetails._id;
    }
    if (!this.validateFields(body)) return;
    this.setState({ isButtonClicked: true });
    delete body.confirmPassword;
    let apiCall = "";
    userDetails.userTypeRole === "chef"
      ? (apiCall = this.props.chefChangePasswordPostLogin(body))
      : (apiCall = this.props.userChangePasswordPostLogin(body));
    apiCall
      .then(() => {
        this.handleCancel();
      })
      .catch(() => {
        this.setState({ isButtonClicked: false });
      });
  };

  handleLatLng = (data) => {
    let newData = [];
    console.log(data, "data");
    Object.keys(data).forEach((item) => newData.push(data[item]));
    // setFileds({ ...formFields, latLong: newData })
  };
  handleFind = (e) => {
    let locations = e;
    console.log("locations", locations);
    this.setState({ address: "" }, () => {
      console.log(this.state.address);
      if (Object.keys(locations).length) {
        this.props.updatePageCount(1);
        history.push(
          `/index/userDetail?area=${locations.area}&city=${locations.city}&state=${locations.state}&country=${locations.region}`
        );
      } else {
        Toast({
          type: "error",
          message: "Please select from drop-down",
          time: 2000,
        });
      }
    });
  };
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.showError
      );
    } else {
      Toast({
        type: "error",
        message: "Geolocation is not supported by this browser.",
        time: 2000,
      });
    }
  };
  showPosition = (position) => {
    let { userLocation } = this.state;
    let lat = position.coords.latitude;
    let lon = position.coords.longitude;
    this.timer = setTimeout(() => {
      navigatorGeoLocation(lat, lon).then((response) => {
        let data = response.results;
        let address = extractAddressFields(data[0].address_components);
        console.log(address, "adddddddd");
        if (!!address) {
          this.setState({ userLocation: address.city });
          this.props.updateUserLocation(address);
          // useDispatch({
          //   type: userLocationType.userLocation,
          //   payload: address,
          // })
        }
      });
      console.log("navvvv", userLocation);
    }, 100);
    // this.displayLocation(lat, lon)
  };
  showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred");
        break;
      default:
        console.log("error on Map");
        break;
    }
  }

  render() {
    let {
      userName,
      notifyCount = 0,
      token,
      active,
      isButtonClicked,
      errors,
      isPopupOpen,
      passwordDetails,
      currentPasswordVisible,
      newPasswordVisible,
      reEnterpasswordVisible,
      address,
      locations,
      showSearch,
      userLocation,
      currentUser,
      currentToggle,
      showShare,
    } = this.state;

    let { oldPassword, newPassword, confirmPassword } = passwordDetails;
    let userDetails = JSON.parse(localStorage.getItem("userDetailsWeb"));
    let reDirect = "";
    console.log(userDetails);
    if (!!userDetails && userDetails.userTypeRole === "chef") {
      reDirect =
        userDetails.status === "APPROVED"
          ? "/index/activeProfile"
          : "/index/profile";
    } else {
      reDirect = "/";
    }
    let { location } = history;
    console.log(location.pathname);
    let isAuthRoute =
      location.pathname.includes("/auth") ||
      location.pathname.includes("/chefpdf") ||
      location.pathname === "/index/faq" ||
      location.pathname === "/index/mission" ||
      location.pathname === "/index/aboutUs" ||
      location.pathname === "/index/pp" ||
      location.pathname === "/index/t&c" ||
      location.pathname === "/index/l&r" ||
      location.pathname === "/index/home" ||
      location.pathname === "/index/activeProfile" ||
      location.pathname === "/index/profile" ||
      location.pathname === "/index/notifications" ||
      location.pathname === "/index/contactus";
    let isTogglePAth =
      location.pathname.includes("/auth") ||
      location.pathname.includes("/chefpdf") ||
      location.pathname === "/index/faq" ||
      location.pathname === "/index/mission" ||
      location.pathname === "/index/aboutUs" ||
      location.pathname === "/index/pp" ||
      location.pathname === "/index/t&c" ||
      location.pathname === "/index/l&r" ||
      location.pathname === "/index/home" ||
      location.pathname === "/index/profile" ||
      location.pathname === "/index/notifications" ||
      location.pathname === "/index/customerProfile" ||
      location.pathname === "/index/contactus";

    console.log(location.pathname, isAuthRoute, "gfgf");
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg  bg-primary-color fixed-top">
          <div className="container-fluid px-lg-5">
            <div className="h-100">
              <img
                src={logo}
                alt="homechefLogo"
                onClick={() =>
                  token ? history.push(`${reDirect}`) : history.push("/")
                }
                className="w-100 cursor-pointer"
              />
            </div>
            {token &&
            localStorage.getItem("userTypeRole") &&
            localStorage.getItem("userTypeRole") !== "chef" ? (
              <div className="d-flex align-items-center d-md-none d-none d-block">
                <i className="icon-location fs-16 px-2" />
                <span className="text-grey fs-18 fw-400">Chennai</span>
              </div>
            ) : null}
            {/* {token ? (
              <>
              {location.pathname !== '/index/home' &&
              !location.pathname.includes('/auth') && (
                <div className="d-flex align-items-center">
                  <NormalToggleSwitch
                    name="availability "
                    onChange={(e) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        currentToggle: !currentToggle,
                      }))
                      this.handleSwitchUsers()
                    }}
                    checked={currentToggle}
                  />
                  <div className="text-white mx-2">
                    {currentUser === 'chef'
                      ? 'Customer Mode'
                      : 'Chef Mode'}
                  </div>
                </div>
              )}
              </>
            ) : (
            ""
            )} */}
            <div className="d-flex justify-content-center align-items-center">
              <div
                className={`ms-md-5 ps-md-5 d-flex w-100 align-items-center mobile-locaton ${
                  showSearch && location.pathname !== "/" ? "shown" : "hidden"
                }`}
              >
                {!!userLocation && !isAuthRoute && (
                  <>
                    <div className="d-flex align-items-center d-md-block d-none location-map me-md-4">
                      <span className="text-white fs-18 fw-400">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 200, hide: 300 }}
                          overlay={<Tooltip>{userLocation}</Tooltip>}
                          containerPadding={20}
                        >
                          <i className="icon-location fs-18 px-2" />
                        </OverlayTrigger>
                      </span>
                    </div>
                  </>
                )}
                {!isAuthRoute && showSearch && location.pathname !== "/" ? (
                  <div className="position-relative w-100">
                    <LocationSearchInput
                      isMap={"header"}
                      address={address}
                      handleAddress={(e) => this.handleFind(e)}
                      placeholder=""
                      handleSelect={(address) => this.setState({ address })}
                      handleLatLng={this.handleLatLng}
                      // handleFind={handleFind}
                    />

                    {/* <div className="nav-search">
                        <span className="search-icon">
                          <i className="icon-search fs-20" />
                        </span>

                        <input
                          placeholder={'Search'}
                          className={'searchBox bg-white text-black-25 fs-14'}
                          type="text"
                          name="name"
                        />
                      </div> */}
                  </div>
                ) : null}
              </div>
              {!token && showSearch ? (
                <>
                  {location.pathname !== "/index/home" &&
                    location.pathname !== "/index/activeProfile" &&
                    location.pathname !== "/index/Profile" && (
                      <div
                        className={`text-yellow-C1 click-word fs-14 fw-500 d-block   d-lg-none  ${
                          isAuthRoute ? "clicktext" : ""
                        }`}
                      >
                        Click here <img src={arrowright} alt="" />
                      </div>
                    )}
                </>
              ) : (
                // <>
                //   <div className="text-yellow-C1 fs-14 fw-500 d-block   d-lg-none  ">
                //     Click here to start <img src={arrowright} alt="" />
                //   </div>
                // </>
                ""
              )}
              {token &&
                !location.pathname.includes("/auth") &&
                !location.pathname.includes("/chefpdf") &&
                location.pathname !== "/index/home" &&
                location.pathname !== "/index/profile" &&
                currentUser === "chef" && (
                  <div
                    className="position-relative cursor-pointer d-lg-none d-block mx-3 "
                    onClick={() => history.push("/index/notifications")}
                  >
                    {notifyCount && notifyCount !== 0 ? (
                      <span className="bell-notification">
                        &nbsp;{notifyCount}
                      </span>
                    ) : null}
                    <i className="icon-notifications text-white fs-16" />
                  </div>
                )}
              {location.pathname !== "/index/home" && (
                <button
                  className="border-0 hab-menu shadow-none d-flex d-lg-none "
                  type="button"
                  // data-bs-toggle="collapse"
                  // data-bs-target="#navbarSupportedContent"
                  // aria-controls="navbarSupportedContent"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation"
                  onClick={() => {
                    // this.setState({ showShare: true });
                  }}
                  ref={this.wrapperRef}
                >
                  <i className="icon-menu fs-24" />
                </button>
              )}
            </div>

            <div
              className={`collapse navbar-collapse ${
                !this.state.showshare ? "show" : ""
              } ${token ? "white_box_shadow" : "box-block"}`}
              id="navbarSupportedContent"
            >
              <div className="ms-md-5 ps-md-5 d-flex w-100 align-items-center mobile-mark-location">
                {!!userLocation && !isAuthRoute && (
                  <div className="d-flex align-items-center d-md-block d-none me-md-4">
                    <i className="icon-location fs-18 px-2" />
                    <span className="text-white fs-18 fw-400">
                      {userLocation}
                    </span>
                  </div>
                )}
                {!isAuthRoute && showSearch && location.pathname !== "/" ? (
                  <div className="position-relative w-100 location-mark">
                    <LocationSearchInput
                      isMap={"header"}
                      address={address}
                      handleAddress={(e) => this.handleFind(e)}
                      placeholder=""
                      handleSelect={(address) => this.setState({ address })}
                      handleLatLng={this.handleLatLng}
                      // handleFind={handleFind}
                    />

                    {/* <div className="nav-search">
                      <span className="search-icon">
                        <i className="icon-search fs-20" />
                      </span>

                      <input
                        placeholder={'Search'}
                        className={'searchBox bg-white text-black-25 fs-14'}
                        type="text"
                        name="name"
                      />
                    </div> */}
                  </div>
                ) : null}
              </div>

              {token ? (
                <>
                  {showShare && (
                    <></>
                    // <div className="d-flex justify-content-end w-100 align-items-center  px-5 mobile-responsive">
                    //   {!isTogglePAth &&
                    //     location.pathname !== "/index/home" &&
                    //     ((!!userDetails?.masterChefId &&
                    //       !userDetails?.isDeletedInchef) ||
                    //       userDetails?.userTypeRole === "chef") && (
                    //       <div className="d-flex align-items-center availability-block mx-2">
                    //         <NormalButton
                    //           label={
                    //             currentUser === "chef"
                    //               ? "Switch to Customer mode"
                    //               : "Switch to Chef mode"
                    //           }
                    //           className="ms-3 px-3 yellow-btn"
                    //           onClick={() => {
                    //             this.setState((prevState) => ({
                    //               ...prevState,
                    //               currentToggle: !currentToggle,
                    //             }));
                    //             this.handleSwitchUsers();
                    //           }}
                    //         />
                    //         {/* <NormalToggleSwitch
                    //         name="availability "
                    //         onChange={(e) => {
                    //           this.setState((prevState) => ({
                    //             ...prevState,
                    //             currentToggle: !currentToggle,
                    //           }))
                    //           this.handleSwitchUsers()
                    //         }}
                    //         checked={!currentToggle}
                    //       /> */}
                    //         {/* <div className="text-white mx-2">
                    //         {currentUser === 'chef'
                    //           ? 'Switch to Customer'
                    //           : 'Switch to Chef'}
                    //       </div> */}
                    //       </div>
                    //     )}
                    //   {!location.pathname.includes("/auth") &&
                    //     !location.pathname.includes("/chefpdf") &&
                    //     location.pathname !== "/index/home" &&
                    //     location.pathname !== "/index/profile" &&
                    //     currentUser === "chef" && (
                    //       <div
                    //         id="navbar-menu"
                    //         className="position-relative cursor-pointer d-md-block d-none mx-3 "
                    //         onClick={() => history.push("/index/notifications")}
                    //       >
                    //         {notifyCount && notifyCount !== 0 ? (
                    //           <span className="bell-notification">
                    //             &nbsp;{notifyCount}
                    //           </span>
                    //         ) : null}
                    //         <i className="icon-notifications text-white fs-16" />
                    //       </div>
                    //     )}
                    //   <div className="align-items-center  cursor-pointer  d-lg-none d-block pr-3 pl-1 profile-dropdown ">
                    //     <ul className="mb-0">
                    //       <li className="d-flex align-items-center mb-3">
                    //         <p className="text-black fs-16 user-title mb-0 ">
                    //           {userName}
                    //         </p>
                    //       </li>
                    //       {/* <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                    //           Profile
                    //         </li>
                    //         <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                    //           Menu
                    //         </li>
                    //         <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                    //           Talk to admin
                    //         </li>
                    //         <li
                    //           className="d-flex align-items-center mb-3"
                    //           onClick={() => this.handlePopup('isPopupOpen')}
                    //         >
                    //           Change Password
                    //         </li> */}
                    //       {/* {!!token && currentUser !== 'chef' && (
                    //       <li
                    //         className="d-flex align-items-center mb-3"
                    //         onClick={() =>
                    //           history.push('/index/customerProfile')
                    //         }
                    //       >
                    //         <i className="icon-settings fs-20 me-2" />
                    //         My Profile
                    //       </li>
                    //     )} */}
                    //       <li
                    //         className="d-flex align-items-center mb-3"
                    //         onClick={() => this.handlePopup("isPopupOpen")}
                    //       >
                    //         <i className="icon-settings fs-20 me-2" />
                    //         Change Password
                    //       </li>
                    //       <li
                    //         className="d-flex align-items-center"
                    //         onClick={() => this.handlelogout()}
                    //       >
                    //         <i className="icon-logout fs-20 me-2" />
                    //         Log Out
                    //       </li>
                    //     </ul>
                    //   </div>
                    //   {/* {currentUser !== 'chef' && (
                    //   <i
                    //     className="icon-shopping-bag fs-26 px-2 cursor-pointer"
                    //     onClick={() => history.push('/index/cart')}
                    //   />
                    // )} */}
                    // </div>
                  )}
                  <div className="d-flex justify-content-end w-100 align-items-center  px-5 mobile-responsive mobile-display-none">
                    {!isTogglePAth &&
                      location.pathname !== "/index/home" &&
                      ((!!userDetails?.masterChefId &&
                        !userDetails?.isDeletedInchef) ||
                        userDetails?.userTypeRole === "chef") && (
                        <div className="d-flex align-items-center availability-block mx-2">
                          <NormalButton
                            label={
                              currentUser === "chef"
                                ? "Switch to Customer mode"
                                : "Switch to Chef mode"
                            }
                            className="ms-3 px-3 yellow-btn"
                            onClick={() => {
                              this.setState((prevState) => ({
                                ...prevState,
                                currentToggle: !currentToggle,
                              }));
                              this.handleSwitchUsers();
                            }}
                          />
                          {/* <NormalToggleSwitch
                            name="availability "
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                ...prevState,
                                currentToggle: !currentToggle,
                              }))
                              this.handleSwitchUsers()
                            }}
                            checked={!currentToggle}
                          /> */}
                          {/* <div className="text-white mx-2">
                            {currentUser === 'chef'
                              ? 'Switch to Customer'
                              : 'Switch to Chef'}
                          </div> */}
                        </div>
                      )}
                    {!location.pathname.includes("/auth") &&
                      !location.pathname.includes("/chefpdf") &&
                      location.pathname !== "/index/home" &&
                      location.pathname !== "/index/profile" &&
                      currentUser === "chef" && (
                        <div
                          id="navbar-menu"
                          className="position-relative cursor-pointer d-md-block d-none mx-3 "
                          onClick={() => history.push("/index/notifications")}
                        >
                          {notifyCount && notifyCount !== 0 ? (
                            <span className="bell-notification">
                              &nbsp;{notifyCount}
                            </span>
                          ) : null}
                          <i className="icon-notifications text-white fs-16" />
                        </div>
                      )}
                    <div className="align-items-center  cursor-pointer  d-lg-none d-block pr-3 pl-1 profile-dropdown ">
                      <ul className="mb-0">
                        <li className="d-flex align-items-center mb-3">
                          <p className="text-black fs-16 user-title mb-0 ">
                            {userName}
                          </p>
                        </li>
                        {/* <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                              Profile  
                            </li>
                            <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                              Menu
                            </li>
                            <li className="d-flex align-items-center mb-3" onClick={() => history.push('/index/notifications')}>
                              Talk to admin
                            </li>
                            <li
                              className="d-flex align-items-center mb-3"
                              onClick={() => this.handlePopup('isPopupOpen')}
                            >
                              Change Password
                            </li> */}
                        {/* {!!token && currentUser !== 'chef' && (
                          <li
                            className="d-flex align-items-center mb-3"
                            onClick={() =>
                              history.push('/index/customerProfile')
                            }
                          >
                            <i className="icon-settings fs-20 me-2" />
                            My Profile
                          </li>
                        )} */}
                        <li
                          className="d-flex align-items-center mb-3"
                          onClick={() => this.handlePopup("isPopupOpen")}
                        >
                          <i className="icon-settings fs-20 me-2" />
                          Change Password
                        </li>
                        <li
                          className="d-flex align-items-center"
                          onClick={() => this.handlelogout()}
                        >
                          <i className="icon-logout fs-20 me-2" />
                          Log Out
                        </li>
                      </ul>
                    </div>
                    <div
                      className="align-items-center cursor-pointer  pr-3 pl-1 profile-dropdown desktop-profile"
                      onClick={this.handleDropdown}
                    >
                      <p className="mx-2 mb-0 text-white user-profile">
                        {userName ? userName.substring(0, 1) : "?"}
                      </p>
                      <p className="text-white fs-16 mb-0 ">{userName}</p>
                      <i className="icon-arrow-down fs-24 px-2" />
                      <ul className={`dropdown ${active ? "active" : ""}`}>
                        {/* {!!token && currentUser !== 'chef' && (
                        <li
                          className="d-flex align-items-center"
                          onClick={() =>
                            history.push('/index/customerProfile')
                          }
                        >
                          <i className="icon-user fs-20 me-2" />
                          My Profile
                        </li>
                      )} */}
                        <li
                          className="d-flex align-items-center"
                          onClick={() => this.handlePopup("isPopupOpen")}
                        >
                          <i className="icon-settings fs-20 me-2" />
                          Change Password
                        </li>
                        <li
                          className="d-flex align-items-center"
                          onClick={() => this.handlelogout()}
                        >
                          <i className="icon-logout fs-20 me-2" />
                          Log Out
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                showShare && (
                  <div className="d-flex justify-content-end w-100 align-items-center">
                    {location.pathname !== "/index/home" && (
                      <>
                        <div className=" mobile-block-section">
                          <div className="d-flex align-items-center mobile-cheflocation">
                            <NormalButton
                              whiteBtn
                              label="I am a Chef"
                              className="px-3 chef-profile-mobile"
                              onClick={() =>
                                history.push("/auth/login?userTypeRole=chef")
                              }
                            />
                            <NormalButton
                              whiteBtn
                              label="I am a Customer "
                              className="ms-3 px-3"
                              onClick={() =>
                                history.push(
                                  "/auth/login?userTypeRole=customer"
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <Modal
            isOpen={isPopupOpen}
            className={"change-password modal-dialog-centered"}
            size="md"
          >
            <div className="d-flex justify-content-between align-items-center mb-4 popup-header">
              <h5 className="fs-22 fw-700 mb-0">Change password</h5>
              <i
                onClick={() => this.handleCancel()}
                className="icon-close cursor-pointer"
              />
            </div>
            <div className="px-md-5 mt-3">
              <p className="text-black-25 pb-2">Update your new password</p>

              <div className="normal-input">
                <input
                  className={"form-control w-100"}
                  name={"oldPassword"}
                  type={currentPasswordVisible ? "text" : "password"}
                  value={oldPassword}
                  placeholder={"Enter Old Password"}
                  onChange={this.handleChange}
                />
                {/* {errors.oldPassword && (
                  <ErrorComponent message={errors.oldPassword[0]} />
                )} */}
              </div>
              <div className="normal-input">
                <input
                  className={"form-control w-100"}
                  name={"newPassword"}
                  type={newPasswordVisible ? "text" : "password"}
                  value={newPassword}
                  placeholder={"Enter New Password"}
                  onChange={this.handleChange}
                />
                {errors.newPassword && (
                  <ErrorComponent message={errors.newPassword[0]} />
                )}
              </div>
              <div className="normal-input position-relative">
                <input
                  className={"form-control w-100"}
                  name={"confirmPassword"}
                  type={reEnterpasswordVisible ? "text" : "password"}
                  value={confirmPassword}
                  placeholder={"Confirm Password"}
                  onChange={this.handleChange}
                />
                {newPassword !== "" && newPassword === confirmPassword && (
                  <div className="input-group-addon right">
                    <i className={`cursor-pointer icon-tick-transparent`} />
                  </div>
                )}
                {errors.confirmPassword && (
                  <ErrorComponent message={errors.confirmPassword[0]} />
                )}
              </div>
            </div>
            <div className="mx-auto mt-5 mb-3">
              <NormalButton
                label={"Submit"}
                primary
                onClick={() => this.handleSubmit()}
                disabled={isButtonClicked}
              />
            </div>
          </Modal>
        </nav>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  notificationList: state.commonStore.notificationListData,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      chefChangePasswordPostLogin,
      userChangePasswordPostLogin,
      getNotificationCount,
      getNotificationList,
      userSwitch,
      updateUserLocation: (data) =>
        dispatch({
          type: userLocationType.userLocation,
          payload: data,
        }),
      updatePageCount: () =>
        dispatch({
          type: userLocationType.currentPageChef,
          payload: 1,
        }),
    },
    dispatch
  );
};
export const Navbar = connect(mapStateToProps, mapDispatchToProps)(NavbarClass);
