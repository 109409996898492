// import React, { useState } from 'react'
// import { Toast } from 'service/toast'
// import { extractAddressFields } from 'service/helperFunctions'

// const google = window.google

// export const navigatorGeoLocation = (lat, log) => {
//   var address

//   //   const displayLocation = (latitude, longitude) => {
//   var geocoder
//   geocoder = new window.google.maps.Geocoder()
//   var latlng = new window.google.maps.LatLng(lat, log)

//   return geocoder.geocode({ latLng: latlng }, function (results, status) {
//     var add
//     if (status == window.google.maps.GeocoderStatus.OK) {
//       if (results[0]) {
//         var add = results[0].formatted_address
//         var value = add.split(',')

//         let count = value.length
//         let country = value[count - 1]
//         let state = value[count - 2]
//         let city = value[count - 3]
//         console.log('city name is: ' + city)
//         // address = extractAddressFields(results[0])
//         address = city
//         add = city
//         console.log('city: ' + address)

//         // x.innerHTML = "city name is: " + city;
//       } else {
//         console.log('address not found')
//         // x.innerHTML = "address not found";
//       }
//     } else {
//       console.log('Geocoder failed due to: ' + status)
//     }
//   })
//   //   }
// }


import React from 'react';
import { Toast } from 'service/toast';
import { extractAddressFields } from 'service/helperFunctions';

const google = window.google;

export const navigatorGeoLocation = (lat, log) => {
  return new Promise((resolve, reject) => {
    if (!google || !google.maps) {
      return reject('Google Maps JavaScript API not loaded');
    }

    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, log);

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          const addressComponents = results[0].formatted_address.split(',');
          const count = addressComponents.length;
          const city = addressComponents[count - 3].trim();

          console.log('City name is: ' + city);

          resolve(city); // Return city name
        } else {
          console.log('Address not found');
          reject('Address not found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
        reject('Geocoder failed due to: ' + status);
      }
    });
  });
};

